
// outsource dependencies

// local dependencies
import { PERMISSIONS } from './types';

// configuration
export const initial = {
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
    uploadedFile: '',
    data: {},
};

export default function ( state = initial, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case PERMISSIONS.CLEAR:
            state = initial;
            break;
        case PERMISSIONS.DATA:
            state = {...state, data: options.data};
            break;
        case PERMISSIONS.META:
            state = {...state, ...options, data: state.data};
            break;
    }

    return state;
}
