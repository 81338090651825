
// outsource dependencies
import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Select, { Async } from 'react-select';
import { FormControl, MenuItem, FormHelperText, TextField } from '@material-ui/core';

// local dependencies
import is from '../services/is.service';

// configuration
MdSelect.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
};

/**
 * Prepared MdSelect
 *
 * @param { Object } props
 * @public
 */
export function MdSelect ({ input, meta, label, optionsLabel, helpText = '', skipTouch = false, valueKey = 'id', labelKey = 'name', options, simpleValue, onChange, ...attr }) {
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = meta.error;
    }
    simpleValue = is.function(simpleValue) ? simpleValue : v=>v;
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '4px'}} component="label" htmlFor={input.name}>{label}</FormHelperText>)}
        <Select
            hideSelectedOptions={true}
            id={input.name}
            name={input.name}
            options={options}
            isDisabled={attr.disabled}
            value={simpleValue(input.value, options)}
            getOptionValue={option => get(option, valueKey, option)}
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{ Control, Option }}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
            onChange={e => {
                input.onChange(e);
                is.function(onChange)&&onChange(e);
            }}/>
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
}

MdAsyncSelect.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    loadOptions: PropTypes.func.isRequired,
};

/**
 * Prepared MdAsyncSelect
 *
 * @param { Object } props
 * @public
 */
export function MdAsyncSelect ({ input, meta, label, optionsLabel, helpText = '', skipTouch = false, valueKey = 'id', labelKey = 'name', onChange, ...attr }) {
    // let ref;
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = meta.error;
    }
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '4px'}} component="label" htmlFor={input.name}>{label}</FormHelperText>)}
        <Async
            // ref={r=>ref = r}
            hideSelectedOptions={true}
            cacheOptions={false}
            defaultOptions
            id={input.name}
            name={input.name}
            value={input.value}
            isDisabled={attr.disabled}
            getOptionValue={option => get(option, valueKey, option)}
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{ Control, Option }}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
            onChange={e => {
                input.onChange(e);
                is.function(onChange)&&onChange(e);
                // console.log('Async.onChange', ref);
                // ref.state.defaultOptions = [];
            }}/>
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
}

/**
 * Simple MdSelect
 *
 * @param { Object } props
 * @public
 */
export function SimpleSelect ({name, value, label, optionsLabel, valueKey = 'id', labelKey = 'name', options, simpleValue, onChange, message = '', helpText='', ...attr }) {
    simpleValue = is.function(simpleValue) ? simpleValue : v=>v;
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '2px'}} component="label" htmlFor={name}>{label}</FormHelperText>)}
        <Select
            hideSelectedOptions={true}
            id={name}
            name={name}
            options={options}
            isDisabled={attr.disabled}
            value={simpleValue(value, options)}
            getOptionValue={option => get(option, valueKey, option)}
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{Control, Option}}
            onChange={e => is.function(onChange)&&onChange(e)}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
                />
        {!helpText ? '': (<FormHelperText component="label" htmlFor={name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={name}>{message}</FormHelperText>)}
    </FormControl>);
}

SimpleSelect.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
};

/**
 * Simple MdAsyncSelect
 *
 * @param { Object } props
 * @public
 */
export function SimpleAsyncSelect ({ name, value, label, optionsLabel, helpText = '', valueKey = 'id', labelKey = 'name', simpleValue, onChange, message='', ...attr }) {
    simpleValue = is.function(simpleValue) ? simpleValue : v=>v;
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '2px'}} component="label" htmlFor={name}>{label}</FormHelperText>)}
        <Async
            hideSelectedOptions={true}
            cacheOptions={false}
            defaultOptions
            id={name}
            name={name}
            value={simpleValue(value)}
            isDisabled={attr.disabled}
            getOptionValue={option => get(option, valueKey, option)}
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{ Control, Option }}
            onChange={(e) => is.function(onChange) && onChange.bind(this)(e)}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
                />
        {!helpText ? '': (<FormHelperText component="label" htmlFor={name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={name}>{message}</FormHelperText>)}
    </FormControl>);
}

SimpleAsyncSelect.propTypes = {
    value: PropTypes.any,
    loadOptions: PropTypes.func.isRequired,
};

/**
 * custom control component for react-select
 *
 * @param {Object} props
 * @private
 */
function Control ({ children, innerProps, innerRef, selectProps: { name, label, error, disabled } }) {
    return (
        <TextField
            dir="auto"
            autoComplete="off"
            fullWidth
            name={name}
            label={label}
            error={error}
            disabled={disabled}
            InputLabelProps={{shrink: true}}
            InputProps={{ inputComponent, inputProps: {innerRef, children, ...innerProps} }}
                />
    );
}
/**
 * custom control component for react-select
 *
 * @param {Object} props
 * @private
 */
function inputComponent ({ innerRef, inputRef, ...attr}) {
    return (<div ref={innerRef} {...attr} style={{display: 'flex', padding: 0, margin: '-1px'}} />);
}

/**
 * custom option component to provide ability format options item without braking filter logic
 *
 * @param {Object} props
 * @private
 */
function Option ({ innerRef, innerProps, isFocused, options, children, label, value, selectProps: { optionsLabel } }) {
    // console.log('Option', label, value );
    return (<MenuItem
        {...innerProps}
        buttonRef={innerRef}
        selected={isFocused}
            >
        { is.function(optionsLabel) ? optionsLabel({label, value}, options) : children }
    </MenuItem>);
}

