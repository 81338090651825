
// local dependencies
import { createTypes } from '../../actions/types';

export const LIST = (prefix => {
    return {
        PREFIX: new RegExp(prefix, 'i'),
        // simple action
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
        // complex actions
        INITIALIZE: `${prefix}INITIALIZE`,
        UPDATE_LIST: `${prefix}UPDATE_LIST`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        DELETE_ITEM: `${prefix}DELETE_ITEM`,
        CANCEL_ADVANCED_SEARCH: `${prefix}CANCEL_ADVANCED_SEARCH`,
    };
})('@organizations/list/');

export const EDIT = (prefix => {
    return {
        PREFIX: new RegExp(prefix, 'i'),
        // simple action
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex actions
        UPDATE: `${prefix}UPDATE`,
        INITIALIZE: `${prefix}INITIALIZE`,
        SETUP_STATE: `${prefix}SETUP_STATE`,
        CHANGE_TYPE: `${prefix}CHANGE_TYPE`,
        SYNCHRONIZE_WITH_ZOOM_INFO: `${prefix}SYNCHRONIZE_WITH_ZOOM_INFO`,
        SETUP_COUNTRY: createTypes(`${prefix}SETUP_COUNTRY`),
    };
})('@organizations/edit/');
