
// outsource dependencies
import { fork } from 'redux-saga/effects';

// local dependencies
import agreements from './agreements/sagas';
import assetClassesSagas from './asset-classes/sagas';
import auditLogs from './audit-logs/sagas';
import cacheMetricsData from './cache-metrics-data/sagas';
import changePassword from './change-password/saga';
import dataClassesSagas from './data-classes/sagas';
import hints from './hints/sagas';
import importNews from './import-news/saga';
import importPaceCourses from './import-pace-courses/saga';
import importWebinars from './import-webinars/saga';
import importWhatsNew from './import-whats-new/saga';
import industries from './industries/sagas';
import languages from './languages/sagas';
import loadDemoModal from './load-demo-modal/saga';
import organizationsSagas from './organizations/sagas';
import permissions from './permissions/saga';
import profile from './profile/saga';
import qlikAnalyticsSagas from './qlik-analytics/sagas';
import quantificationModels from './quantification-models/sagas';
import regulationsSagas from './regulations/sagas';
import roles from './roles/saga';
import technologyCategories from './technology-categories/sagas';
import usersSagas from './users/sagas';

/**
 * connect all public sagas
 *
 *
 * @public
 */
export default function* () {
    yield fork(agreements);
    yield fork(assetClassesSagas);
    yield fork(auditLogs);
    yield fork(cacheMetricsData);
    yield fork(changePassword);
    yield fork(dataClassesSagas);
    yield fork(hints);
    yield fork(importNews);
    yield fork(importPaceCourses);
    yield fork(importWebinars);
    yield fork(importWhatsNew);
    yield fork(industries);
    yield fork(languages);
    yield fork(loadDemoModal);
    yield fork(organizationsSagas);
    yield fork(permissions);
    yield fork(profile);
    yield fork(qlikAnalyticsSagas);
    yield fork(quantificationModels);
    yield fork(regulationsSagas);
    yield fork(roles);
    yield fork(technologyCategories);
    yield fork(usersSagas);
}
