
// outsource dependencies
// import is from '../services/is.service';

// local dependencies
import BaseModel from './base.model';
import { instanceAPI } from '../services/api.service';

/**
 *
 *
 * @constructor RegulationModel
 * @type {RegulationModel}
 * @public
 */
class RegulationModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {

    }

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @override BaseModel
     * @private
     */
    static get baseRoute () {
        return 'regulations';
    }

    /**
     * get self organization
     *
     * @override BaseModel
     * @public
     */
    static getSelf () {
        return new Promise((resolve, reject) => {
            instanceAPI({ method: 'get', url: '/regulations/self' })
                .then(user => resolve( RegulationModel.create(user)) )
                .catch(reject);
        });
    }

}

export default RegulationModel;
