
// outsource dependencies
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

// local dependencies
import { PublicLogo } from '../../images';
import { PUBLIC } from '../../actions/types';
import Preloader from '../../components/preloader';
import CenteredBox from '../../components/centered-box';
import { PUBLIC_WELCOME, SIGN_IN } from '../../constants/routes';

// configuration

/**
 * Change Password using token from email notification
 *
 * @constructor EmailConfirmation
 * @public
 */
class EmailConfirmation extends Component {
    render () {
        let { expectAnswer, invalidToken } = this.props;
        return (<div id="publicPage">
            <CenteredBox>
                <div className="row">
                    <div className="col-xs-8 col-xs-offset-2 text-center">
                        <Link to={PUBLIC_WELCOME.LINK()}>
                            <PublicLogo />
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <h1 className="col-xs-12 text-center">
                        <strong> Email Confirmation </strong>
                    </h1>
                </div>
                <div className="row offset-bottom-6">
                    <Preloader type="BOX" expectAnswer={expectAnswer}>
                        { invalidToken ? (
                            <h3 className="col-xs-12 text-justify">
                                Whoa there! The request token for this page is invalid.
                                It may have already been used, or expired because it is too old.
                                Please go back to the and try again.
                            </h3>
                        ) : (
                            <h3 className="col-xs-12 text-justify">
                                The email address was successfully verified. Welcome aboard !
                            </h3>
                        )}
                    </Preloader>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-xs-offset-6 text-right text-uppercase">
                        <Link to={SIGN_IN.LINK()} className="link"> Sign In </Link>
                    </div>
                </div>
            </CenteredBox>
        </div>);
    }

    componentWillMount () { this.props.validateToken(this.props.match.params); }
}

export default connect(
    state => ({ ...state.emailConfirmation }),
    dispatch => ({ validateToken: ({token}) => dispatch({type: PUBLIC.EMAIL_CONFIRMATION.REQUEST, token }) })
)(EmailConfirmation);
