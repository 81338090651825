
// outsource dependencies
import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { takeEvery, put, call, take } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../types';
import { changeField } from './index';
import { history } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import { OrganizationModel } from '../../../models';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';


function * initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let result = yield call(getData, id);
        let availableLanguages = yield call(getLanguages);
        // NOTE: initialize lists of states and cities for current country
        let countryId = get(result, 'country.id', null);
        if ( countryId ) {
            let stateId = get(result, 'state.id', null);
            yield put({type: EDIT.SETUP_COUNTRY.REQUEST, countryId});
            yield take(EDIT.SETUP_COUNTRY.FINISH);
            yield put({type: EDIT.SETUP_STATE, countryId, stateId});
        }
        yield put({type: EDIT.DATA, ...result});
        yield put({type: EDIT.META, availableLanguages});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, ...params});
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false, initialized: true});
}

function * updateDataSaga ({type, id, ...data}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let result = yield call(updateData, id, data);
        yield put({type: EDIT.DATA, ...result});
        if ( id === NEW_ID ) {
            // NOTE update url take id from results
            yield call(history.push, ROUTES.ORGANIZATIONS.LINK_EDIT(result));
        }
        yield call(toastr.success, 'Organization', 'Data was successfully updated.');
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function * synchronizeWithZoomInfoDataSaga ({type, id, ...data}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let result = yield call(OrganizationModel.synchronizeWithZoomInfo, id, data);
        // console.log(result);
        yield put({type: EDIT.DATA, ...result});
        yield call(history.push, ROUTES.ORGANIZATIONS.LINK_EDIT(result));
        yield call(toastr.success, 'Organization', 'Data was successfully syncronized with ZoomInfo.');
        yield put({type: EDIT.INITIALIZE, ...result});
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function* changeOrganizationTypeSaga ({orgType}) {
    // NOTE clear previous values
    yield put(changeField('rootParent', null));
    yield put(changeField('isCloudVendor', false));
    yield put(changeField('isTechnologyVendor', false));
    yield put(changeField('isSystemVendor', false));
    yield put(changeField('isServiceVendor', false));
    yield put(changeField('owner', null));
    yield put(changeField('industry', null));
    yield put(changeField('parent', null));
    yield put(changeField('averageRevenue', null));
    yield put(changeField('qualThreshold', null));
    yield put(changeField('marketCapitalizationNumber', null));
    yield put(changeField('isPublicCompany', false));
    yield put(changeField('useMultiFactorAuth', false));
    yield put(changeField('supportedLanguages', []));
}

function* setupCountrySaga ({countryId}) {
    yield put({type: EDIT.META, expectAnswer: true, states: [], cities: []});
    try {
        // NOTE clear old field values
        yield put(changeField('state', null));
        yield put(changeField('city', null));
        // NOTE get list of states for chosen country
        let states = yield call(getStates, countryId);
        // NOTE get list of cities for chosen country
        // cause not all countries have states but can have cities
        let cities = yield call(getCities, countryId);
        yield put({type: EDIT.META, expectAnswer: false, states, cities});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
    yield put({type: EDIT.SETUP_COUNTRY.FINISH});
}

function* setupStateSaga ({countryId, stateId}) {
    yield put({type: EDIT.META, expectAnswer: true, cities: []});
    try {
        // NOTE clear old field values
        yield put(changeField('city', null));
        // NOTE get list of cities for chosen country and state
        let cities = yield call(getCities, countryId, stateId);
        yield put({type: EDIT.META, expectAnswer: false, cities});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * connect all public sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.SYNCHRONIZE_WITH_ZOOM_INFO, synchronizeWithZoomInfoDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.SETUP_STATE, setupStateSaga);
    yield takeEvery(EDIT.CHANGE_TYPE, changeOrganizationTypeSaga);
    yield takeEvery(EDIT.SETUP_COUNTRY.REQUEST, setupCountrySaga);
}

/**
 *
 * @public
 */
function getData ( id ) {
    return id === NEW_ID ? OrganizationModel.create({id: NEW_ID}) : OrganizationModel.getById(id);
}
/**
 *
 * @param {String|Number} id
 * @param {Object} data
 * @public
 */
function updateData ( id, data = {} ) {
    // TODO apply allowed props to update
    // let { email, firstName, lastName, mobilePhone, roleNames, organization } = data;
    // NOTE "spread" break the model instance
    return OrganizationModel.partiallyUpdate(id, data);
}

/**
 * get list of states for current country
 * @param {Number} countryId
 * @private
 */
function getStates( countryId ) {
    return new Promise (( resolve, reject ) => {
        instanceAPI({ method: 'post', url: `/states/filter`, data: { page: 0, size: 6, filter: { countryId } } })
            .then(({items}) => resolve(items)).catch(reject)
    });
}

/**
 * get list of cities for current country and state
 * @param {Number} countryId
 * @param {Number} stateId
 * @private
 */
function getCities( countryId, stateId ) {
    return new Promise (( resolve, reject ) => {
        instanceAPI({ method: 'post', url: `/cities/filter`, data: { page: 0, size: 6, filter: { countryId, stateId } } })
            .then(({items}) => resolve(items)).catch(reject)
    });
}

/**
 * get all available supported languages
 * @private
 */
function getLanguages() {
    return instanceAPI({ method: 'post', url: `/supported-languages/filter`, data: { size: 10000 } })
        .then(({items}) => items);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}

