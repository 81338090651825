
// outsource dependencies
import React from 'react';
import filter from 'lodash/filter';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Row, Col, Collapse, ListGroup, ListGroupItem, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// local dependencies
import {Logo, AdditionalPublicLogo} from '../images';
import { history } from '../store';
import UserMenu from './user-menu';
import Theme from '../components/theme';
import * as ROUTES from '../constants/routes';
import { MENU_ITEM_TYPE } from '../constants/spec';
import ConfirmDialog from '../components/confirmation-dialog';
import LoadDemoModal from './load-demo-modal'
import CropModal from "../components/image-crop.modal";

/**
 * Implement private layout wrapper
 *
 * @param {Object} props
 * @public
 */
export default connect(state => ({expanded: state.privateLayout.expanded }), null)(
    ({ children, expanded, menu = [] }) => (
    <Theme name="ROOT">
        <div id="privateLayout" className="container-fluid">
            <CropModal/>
            <ConfirmDialog/>
            <LoadDemoModal/>
            <Row>
                <div className="private-header">
                    <Col xs={5}>
                        <Link to={ROUTES.PRIVATE_WELCOME_SCREEN.LINK()}>
                            <Logo full height="45" tabIndex={-1} />
                        </Link>
                    </Col>
                    <Col xs={2} className="text-center">
                        <Link to={ROUTES.PRIVATE_WELCOME_SCREEN.LINK()} className="header-logo">
                            <AdditionalPublicLogo height="45" tabIndex={-1}/>
                        </Link>
                    </Col>
                    <Col xs={5}> <UserMenu /> </Col>
                </div>
            </Row>
            <Row>
                <div className={'private-aside '+ (expanded ? 'expanded' : 'collapsed' )}>
                    <div id="menu">
                        <div className="hide-scroll-bar">
                            <ListGroup componentClass="ul">
                                { menu.map((item, index) => (<AsideMenuItem {...item} expanded={expanded} key={index}/>)) }
                            </ListGroup>
                        </div>
                    </div>
                    <div id="content"> <div className="hide-scroll-bar"> { children } </div> </div>
                </div>
            </Row>
        </div>
    </Theme>
));

/**
 * switcher to make nested menu
 *
 * @param {Object} props
 * @public
 */
function AsideMenuItem ( props ) {
    let { expanded, type, icon, name, link, isActive, ...attr } = props;
    let options = {
        delayHide: 0.2 * 1000,
        placement: expanded ? 'top' : 'right',
        delayShow: (expanded ? 8 : 0.2) * 1000,
        overlay: (<Tooltip id={'tooltip-'}> {name} </Tooltip>)
    };
    switch ( type ) {
        default: return (<ListGroupItem> default </ListGroupItem>);
        case MENU_ITEM_TYPE.MENU: return (<CollapsibleMenu {...props} tooltipOptions={options} />);
        case MENU_ITEM_TYPE.ACTION: return (<OverlayTrigger {...options}>
            <ListGroupItem {...attr}>
                { icon ? (<i className={icon} aria-hidden="true"> </i>) : '' }
                &nbsp;{ expanded ? name : '' }
            </ListGroupItem>
        </OverlayTrigger>);
        case MENU_ITEM_TYPE.LINK: return (<OverlayTrigger {...options}>
            <LinkContainer exact={false} to={link} isActive={isActive}>
                <ListGroupItem {...attr}>
                    { icon ? (<i className={icon} aria-hidden="true"> </i>) : '' }
                    &nbsp;{ expanded ? name : '' }
                </ListGroupItem>
            </LinkContainer>
        </OverlayTrigger>);
    }
}

class CollapsibleMenu extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            open: false,
            active: false,
        };
        this.throttledAutoToggle = throttle(() => this.autoToggle(), 600);
    }
    componentWillMount () { this.throttledAutoToggle() }
    componentWillUpdate () { this.throttledAutoToggle(); }
    autoToggle () {
        let { list = [] } = this.props;
        let active = false;
        let links = filter(list, {type: MENU_ITEM_TYPE.LINK});
        for ( let {link, isActive} of links ) {
            let reg = new RegExp(link, 'i');
            if (
                reg.test(history.location.pathname)
                || (isActive&&isActive(null, history.location))
            ) {
                active = true;
                break;
            }
        }
        // NOTE prevent triggering render without needs
        if ( active !== this.state.active ) {
            this.setState({active, open: active});
        }
    }

    render () {
        let { list = [], name, icon, expanded, type, tooltipOptions,  ...attr } = this.props;
        let { open, active } = this.state;
        return (<ListGroup componentClass="ul" style={{margin: 0}}>
            <OverlayTrigger {...tooltipOptions}>
                <ListGroupItem className={'sub-menu-toggle '+(active ? 'active' : '')} {...attr} onClick={() => !attr.disabled&&this.setState({ open: !open })}>
                    <Badge> <i className={'fa fa-caret-down fa-lg transition '+( open ? '' : 'fa-rotate-90')} aria-hidden="true"> </i> </Badge>
                    { expanded ? name : '' }
                </ListGroupItem>
            </OverlayTrigger>
            <Collapse in={open}>
                <div className="collapse-box">
                    { list.map((item, index) => (<AsideMenuItem {...item} expanded={expanded} key={index}/>)) }
                </div>
            </Collapse>
        </ListGroup>);
    }
}
