
// outsource dependencies
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

// local dependencies
import { LIST } from '../actions';
import { history } from '../../../store';
import { PERMISSION, DOWNLOAD_TYPES } from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import withDownloadLink from '../../../components/download-link';
import { HINTS} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import { AddBtn, EditBtn, Link, PrimaryBtn } from '../../../components/md-button';
import {get} from "lodash";
import {HINTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, uploadFile, uploadedFile } = this.props;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6}>
                            <h2 className="text-uppercase">
                                Hints
                                <Preloader expectAnswer={expectAnswer} type="ICON" />
                            </h2>
                            <Breadcrumbs breadCrumbsMap={ HINTS_MAP }  />
                        </Col>
                        <Col xs={12} sm={6} className="text-right top-indent-4">
                            <DownloadBtn className="text-nowrap  offset-bottom-1" />
                            <PrimaryBtn
                                component="label"
                                htmlFor="fileUpload"
                                tooltip="Upload CSV"
                                className="text-nowrap offset-left-2 offset-bottom-1"
                            >
                                <i className="fa fa-upload" style={{fontSize: 20}} aria-hidden="true"> </i>
                                &nbsp;CSV
                                <input
                                    type="file"
                                    accept=".csv"
                                    id="fileUpload"
                                    value={uploadedFile}
                                    style={{display: 'none'}}
                                    onChange={e => uploadFile(e.target.files[0])}
                                />
                            </PrimaryBtn>
                            <Link className="text-nowrap offset-left-2 offset-bottom-1" Btn={AddBtn} to={HINTS.LINK_EDIT()} permission={PERMISSION.HINT.CREATE} />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected />
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>
                    )}
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.hints.list.expectAnswer, list: state.hints.list.data}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        uploadFile: file => dispatch({type: LIST.UPLOAD_FILE, file}),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.hints.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.hints.list.filter,
        disabled: state.hints.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

const ConnectedTable = connect(
    state => ({...state.hints.list}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead>
                <TableRow>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                        >
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'hintType'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('hintType')}
                        >
                            Hint type
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'code'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('code')}
                        >
                            Code
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">Hint Text (en)</TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow key={i}>
                    <TableCell className="text-nowrap">{item.name}</TableCell>
                    <TableCell className="text-nowrap">{item.hintType}</TableCell>
                    <TableCell className="text-nowrap">{item.code}</TableCell>
                    <TableCell>
                        <div><b>{get(item, 'translations.eng.title')}</b></div>
                        {/*<div>{get(item, 'translations.eng.body')}</div>*/}
                        <div dangerouslySetInnerHTML={{__html: get(item, 'translations.eng.body')}} />
                    </TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <div>
                            <Link
                                Btn={EditBtn}
                                permission={PERMISSION.HINT.UPDATE}
                                to={HINTS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            />
                            {/*<DelBtn permission={PERMISSION.HINT.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2" />*/}
                        </div>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>));

const ConnectedError = connect(
    state => ({message: state.hints.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const DownloadBtn = withDownloadLink({downloadType: DOWNLOAD_TYPES.ADMIN_HINTS.CSV_LIST})(props => (
    <PrimaryBtn {...props} tooltip="Download data">
        <i className="fa fa-download" style={{fontSize: 20}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
));
