// outsource dependencies
import {connect} from 'react-redux';
import React, {Component, Fragment} from 'react';
import {Paper} from '@material-ui/core';
import {reduxForm, Field, getFormValues} from 'redux-form';
import {Grid, Row, Col} from 'react-bootstrap';


// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import Editor from '../../../components/html-editor';
import ErrorMessage from '../../../components/alert-error';
import {NEW_ID } from '../../../constants/spec';
import {SubmitBtn, ResetBtn, CancelBtn, PrimaryBtn} from '../../../components/md-button';
import {MdSelect} from "../../../components/md-select";
import {HINTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import { selectImageSrc } from "../../../images"


let activeStyle = {
    backgroundColor: '#fff',
};

let defaultStyle = {
    backgroundColor: '#fff',
    boxShadow: 'none'
};

class Edit extends Component {
    componentDidMount() {
        this.props.initialize(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, match} = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        {isNew
                                            ? 'Create hint'
                                            : 'Edit hint'
                                        }
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"/>
                                </h2>
                                <Breadcrumbs breadCrumbsMap={HINTS_MAP}/>
                                <ConnectedError/>
                                <ConnectedForm isNew={isNew}/>
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.hints.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.hints.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.hints.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        initialValues: state.hints.edit.data,
        disabled: state.hints.edit.expectAnswer,
        translations: state.hints.edit.translations,
        translationMode: state.hints.edit.translationMode,
        formValues: getFormValues('editHint')(state),
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeMode: langMode => dispatch({type: EDIT.CHANGE_MODE, langMode}),
    })
)(reduxForm({
    form: 'editHint',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }if (!values.hintType) {
            errors.hintType = 'Hint type is required';
        }if (!values.code) {
            errors.code = 'Code is required';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, changeMode, update, reset, isNew, translations, translationMode, formValues={}, cancel}) => (
    <form autoComplete="off" name="editHint" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={6}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder='Name'
                    label={(<strong className="required-asterisk"> Name </strong>)}
                />
            </Col>
            <Col xs={6}>
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="hintType"
                    disabled={disabled}
                    component={MdSelect}
                    placeholder="Hint type"
                    options={['Simple', 'Rich']}
                    optionsLabel={
                        ({value})=> {
                            if (value === 'Simple'){return 'Simple'}
                            if (value === 'Rich'){return 'Rich'}
                        }
                    }
                    label={(<strong className="required-asterisk"> Hint type </strong>)}
                    simpleValue={(value) => {
                        let lbl = '';
                        if (value === 'Simple'){lbl = 'Simple'}
                        if (value === 'Rich'){lbl = 'Rich'}

                        return {value, label: lbl} }}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={6}>
                <Field
                    name="code"
                    component={MdInput}
                    disabled={!isNew || disabled}
                    placeholder='Code'
                    label={(<strong className="required-asterisk"> Code </strong>)}
                />
            </Col>
            <Col xs={6}>
                <Field
                    multiline={true}
                    name="externalId"
                    component={MdInput}
                    disabled={disabled}
                    placeholder='External ID'
                    label={(<strong> External ID </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4"> <Col xs={12}>
            <h3 className="text-center text-uppercase">Translations</h3>
        </Col> </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} className="text-center">
                {translations.map( (lang, i) => {
                    if (!lang.isPublic) return null;
                    let isActive = lang.code === translationMode;
                    return (
                        <PrimaryBtn tooltip={lang.name} style={isActive ? activeStyle : defaultStyle} key={lang.id} onClick={() => changeMode(lang.code)} disabled={disabled} className='offset-right-2'>
                            <img style={{boxShadow: '0px 0px 7px 0px #00000057'}} alt={lang.name} src={selectImageSrc(lang.code)} height={30}/>
                        </PrimaryBtn>
                    )})}
            </Col>
        </Row>
        <Row><HintDescription type={formValues.hintType || {}} /></Row>
        {formValues.hintType === 'Rich' && (<Row className="offset-bottom-8">
            <Col xs={12} className="text-center">
                <Field
                    name={`link`}
                    component={MdInput}
                    disabled={disabled}
                    placeholder='Link'
                    label={(<strong> Link </strong>)}
                />
            </Col>
        </Row>)}
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"/>
                <ResetBtn onClick={() => { reset() }} disabled={pristine || disabled} className="offset-right-2"/>
                <CancelBtn onClick={cancel}/>
            </Col>
        </Row>
    </form>
)));

const HintDescription = connect(
    state => ({
        translations: state.hints.edit.translations,
        disabled: state.hints.edit.expectAnswer,
        translationMode: state.hints.edit.translationMode,
    }),
    null
)(({translationMode, disabled, type}) => (<Fragment>
    {type === 'Rich' && (<Col xs={12} className="offset-bottom-4">
        <Field
            name={`translations.${translationMode}.title`}
            component={MdInput}
            disabled={disabled}
            placeholder={`Title (${translationMode})`}
            label={(<strong> Title ({translationMode}) </strong>)}
        />
    </Col>)}
    <Col xs={12} className="offset-bottom-4">
        <Field
            multiline={true}
            name={`translations.${translationMode}.body`}
            component={type === 'Rich' ? Editor : MdInput}
            disabled={disabled}
            placeholder={`Description (${translationMode})`}
            label={(<strong> Description ({translationMode}) </strong>)}
        />
    </Col>
</Fragment>));