
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const LIST = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        DELETE_ITEM: `${prefix}DELETE_ITEM`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
    }
})('@regulations/list/');

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        CANCEL: `${prefix}CANCEL`,
        INITIALIZE: `${prefix}INITIALIZE`,
        GET_STATES: `${prefix}GET_STATES`,
        ADD_AGE_ID: `${prefix}ADD_AGE_ID`,
        REMOVE_AGE_ID: `${prefix}REMOVE_AGE_ID`,
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
    }
})('@regulations/edit/');
