// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@material-ui/core';
import {Field, reduxForm} from 'redux-form';
import {Col, Grid, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import {NEW_ID} from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {INDUSTRIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {MdAsyncSelect} from "../../../components/md-select";
import {instanceAPI} from "../../../services/api.service";

class Edit extends Component {
    componentDidMount() { this.props.initialize( this.props.match.params.id ); }
    componentWillUnmount() { this.props.clear(); }


    render() {
        let { expectAnswer, match } = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        {isNew ? 'Create industry' : 'Edit industry'}
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                </h2>
                                <Breadcrumbs breadCrumbsMap={ INDUSTRIES_MAP }  />
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.industries.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.industries.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.industries.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        initialValues: state.industries.edit.data,
        disabled: state.industries.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData})
    })
)(reduxForm({
    form: 'editIndustry',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel})=>(
    <form autoComplete="off" name="editIndustry" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder='Name'
                    label={(<strong className="required-asterisk"> Name </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12} md={12} lg={12}>
                <Field
                    name="parent"
                    disabled={disabled}
                    placeholder="Parent Industry"
                    component={MdAsyncSelect}
                    label={(<strong> Parent Industry </strong>)}
                    loadOptions={(name, done) => {
                        instanceAPI({
                            method: 'post',
                            url: 'industries/filter',
                            data: {page: 0, size: 12, filter: {name}, sort: {field: 'name', order: 'ASC'}},
                        }).then(({items})=>done(items)).catch(done.bind(null, []));
                    }}/>
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col md={6}>
                <Field
                    type="number"
                    name="naicsCode"
                    component={MdInput}
                    disabled={disabled}
                    placeholder="NAICS Code"
                    label={(<strong> NAICS Code </strong>)}
                        />
            </Col>
            <Col md={6}>
                <Field
                    type="number"
                    name="naicsCodeUpper"
                    component={MdInput}
                    disabled={disabled}
                    placeholder="NAICS Code Upper"
                    label={(<strong> NAICS Code Upper </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" />
                <CancelBtn onClick={cancel} />
            </Col>
        </Row>
    </form>
)));
