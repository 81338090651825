// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import {Close} from '@material-ui/icons';
import {Paper, IconButton, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Card, CardHeader, CardContent, CardActions, Tooltip,} from '@material-ui/core';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import Preloader from '../../../components/preloader';
import store from '../../../store';
import {CACHE_METRICS_DATA} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter, {SimpleSearchField} from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {WarningBtn, Link, PrimaryBtn, DetailsBtn} from '../../../components/md-button';
import {SimpleAsyncSelect} from '../../../components/md-select';
import {CACHE_METRICS_DATA_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {OrganizationModel} from "../../../models";
import {instanceAPI} from "../../../services/api.service";

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, list} = this.props;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <h2 className="text-uppercase">
                                Cache metrics data
                                <Preloader expectAnswer={expectAnswer} type="ICON"/>
                            </h2>
                            <Breadcrumbs breadCrumbsMap={CACHE_METRICS_DATA_MAP}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <WarningBtn tooltip="Rebuild all metrics cache" style={{color: 'white'}}
                                        onClick={() => store.dispatch({type: LIST.REBUILD_ALL_METRICS_CACHE})}
                                        className="offset-left-2"> Rebuild all metrics cache </WarningBtn>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <FiltersPanelConnected/>
                        </Col>
                    </Row>
                    <ConnectedError/>
                    {list.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>
                    )}
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.cacheMetricsData.list.expectAnswer, list: state.cacheMetricsData.list.data}),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.cacheMetricsData.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.cacheMetricsData.list.filter,
        disabled: state.cacheMetricsData.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        placeholder="Search by metric name ..."
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
    />
));

/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = connect(
    state => ({
        disabled: state.cacheMetricsData.list.expectAnswer,
        showAdvanced: state.cacheMetricsData.list.showAdvanced,
    }),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => {
            dispatch({type: LIST.META, showAdvanced: true});
            dispatch({type: LIST.UPDATE_DATA, filter: ''});
        },
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({showAdvanced, update, openAdvanced, closeAdvanced, disabled}) => (<div>
    {showAdvanced ? (
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title="Advanced search"
                        action={
                            <Tooltip title="Cancel">
                                <IconButton aria-label="Cancel advanced search" onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }/>
                    <CardContent> <SearchForm/> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn disabled={disabled} onClick={update} tooltip="Apply"> Apply </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn disabled={disabled} onClick={closeAdvanced} tooltip="Cancel"> Cancel </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected/>
            </Col>
            <Col xs={12} sm={6}>
                <PrimaryBtn onClick={openAdvanced} tooltip="Advanced search"> Advanced search </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>));

/**
 * search form
 *
 * @public
 */
const SearchForm = connect(
    state => ({...state.cacheMetricsData.list}),
    dispatch => ({changeFilterValue: data => dispatch({type: LIST.META, ...data})})
)(({expectAnswer, filter, metricName, organization, system, lastName, email, role, changeFilterValue, name, isDeleted}) => (<div>
    <Row>
        <Col xs={4} className="offset-bottom-2">
            <SimpleSearchField
                value={filter}
                disabled={expectAnswer}
                placeholder="Search by metric name ..."
                clear={() => changeFilterValue({filter: ''})}
                onChange={e => changeFilterValue({filter: e.target.value})}
            />
        </Col>
        <Col xs={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={organization}
                disabled={expectAnswer}
                placeholder="Organization"
                label={(<strong> Organization </strong>)}
                onChange={organization => changeFilterValue({organization})}
                loadOptions={(name, done) => {
                    OrganizationModel.getListByName(name, 6).then(done).catch(() => done([]));
                }}/>
        </Col>
        <Col xs={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={system}
                disabled={expectAnswer}
                placeholder="System"
                label={(<strong> System </strong>)}
                onChange={system => changeFilterValue({system})}
                loadOptions={(name, done) => {
                    instanceAPI({
                        method: 'post',
                        url: 'systems/filter',
                        data: {page: 0, size: 6, filter: {name}},
                    }).then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        {/*<Col xs={4} className="offset-bottom-4"> <OrganizationFormConnected/> </Col>*/}
    </Row>
</div>));


const ConnectedTable = connect(
    state => ({...state.cacheMetricsData.list}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({data, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead>
                <TableRow>
                    {/*<TableCell className="th">Organization</TableCell>*/}
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'organization'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('organization')}
                        >
                            Organization
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'riskModelName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('riskModelName')}
                        >
                            Risk Model
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'systemName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('systemName')}
                        >
                            System
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'metricName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('metricName')}
                        >
                            Metric name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">Metric domain </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'metricType'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('metricType')}
                        >
                            Metric type
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">Metric value </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'metricLevel'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('metricLevel')}
                        >
                            Metric level
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow key={i}>
                    <TableCell className="text-nowrap">{item.organizationName}</TableCell>
                    <TableCell className="text-nowrap">{item.riskModelName}</TableCell>
                    <TableCell className="text-nowrap">{item.systemName}</TableCell>
                    <TableCell className="text-nowrap">{item.metricName}</TableCell>
                    <TableCell className="text-nowrap">{item.metricDomainName}</TableCell>
                    <TableCell className="text-nowrap">{item.metricType}</TableCell>
                    <TableCell className="text-nowrap">{item.metricValue}</TableCell>
                    <TableCell className="text-nowrap">{item.metricLevel}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <div>
                            <Link
                                Btn={DetailsBtn}
                                tooltip="Details"
                                to={CACHE_METRICS_DATA.LINK_EDIT({
                                    id: item.id,
                                    query: {back: history.location.pathname}
                                })}
                            />
                        </div>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>));

const ConnectedError = connect(
    state => ({message: state.cacheMetricsData.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
