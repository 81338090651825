
// outsource dependencies
import { fork } from 'redux-saga/effects';

// local dependencies
import loading from "./import/saga";
import list from "./list/saga";

/**
 * connect all public sagas
 *
 *
 * @public
 */
export default function* () {
    yield fork(loading);
    yield fork(list);
}