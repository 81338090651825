
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, TableSortLabel } from '@material-ui/core';

// local dependencies
import { LIST } from '../types';
import { history } from '../../../store';
import Page from '../../../components/page';
import Alert from '../../../components/alert-error';
import { AGREEMENTS } from '../../../constants/routes';
import SearchFilter  from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import { Humanize, Truncate } from '../../../components/filter';
import Preloader, { TYPE } from '../../../components/preloader';
import { Link, EditBtn, DelBtn, AddBtn } from '../../../components/md-button';
import {AGREEMENTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

// configure
export const allowedSort = ['name', 'content', 'organization'];

/**
 * Table
 *
 * @private
 */
const ConnectedTable = connect(
    state => ({
        list: state.agreements.list.list,
        page: state.agreements.list.page,
        size: state.agreements.list.size,
        totalPages: state.agreements.list.totalPages,
        disabled: state.agreements.list.expectAnswer,
    }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        changePage: page => dispatch({type: LIST.UPDATE_LIST, page}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
        changeSize: size => dispatch({type: LIST.UPDATE_LIST, size, page: 0}),
        deleteItem: id => toastr.confirm(<ConfirmDeleting />, { onOk: () => dispatch({type: LIST.DELETE_ITEM, id}) }),
    })
)(({ list, disabled, page, size, totalPages, changePage, changeSize, deleteItem }) => (<Paper>
    <div style={{overflowX: 'auto'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <SortableCell name="name" />
                    <SortableCell name="content" />
                    {/*<SortableCell name="organizations" />*/}
                    {/* NOTE temporary solution for broken sorting */}
                    <TableCell className="th">
                        <Humanize tag="strong" text="organizations"/>
                    </TableCell>
                    <TableCell align="right" />
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (<TableRow key={index}>
                    <TableCell component="th" scope="row"> {item.name} </TableCell>
                    <TableCell component="th" scope="row" style={{whiteSpace: 'pre-wrap'}}>
                        <Truncate text={item.content || ''} options={{length: 512, end: '...', breakOnWord: true}} />
                    </TableCell>
                    <TableCell component="th" scope="row"> {(item.organizations||[]).map(i=>i.name).join(', ')} </TableCell>
                    <TableCell component="th" scope="row" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            disabled={disabled}
                            to={AGREEMENTS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                                />
                        &nbsp;&nbsp;&nbsp;
                        <DelBtn disabled={disabled} onClick={() => deleteItem(item.id)} className="offset-left-2" />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={disabled}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</Paper>));

/**
 * prepared confirmation for organization deleting
 *
 * @public
 */
const ConfirmDeleting = () => (<div>
    <h3> Confirm deleting </h3>
    <p> Are you sure you want to delete the current organization? </p>
</div>);

/**
 * Wrapper for sorting TH
 *
 * @private
 */
const SortableCell = connect(
    state => ({
        disabled: state.agreements.list.expectAnswer,
        sortF: state.agreements.list.sortF,
        sortD: state.agreements.list.sortD,
    }),
    dispatch => ({ changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}) })
)(({disabled, sortF, sortD, name, changeSort, ...attr})=>(
    <TableCell className="th" {...attr}>
        <Tooltip
            enterDelay={300}
            placement="top-start"
            title={(<span>Sort by "<Humanize tag="strong" text={name} />"</span>)}
                >
            <span>
                <TableSortLabel
                    disabled={disabled}
                    active={sortF===name}
                    direction={sortD?'asc':'desc'}
                    onClick={()=>changeSort(name)}
                        >
                    <Humanize tag="strong" text={name} />
                </TableSortLabel>
            </span>
        </Tooltip>
    </TableCell>
));

/**
 *
 * @private
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.agreements.list.filter,
        disabled: state.agreements.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_LIST, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

/**
 * Table wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.agreements.list }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
    })
)(({ list, init, initialized, expectAnswer, errorMessage, clearError }) => (
    <Page init={init} initialized={initialized}>
        <Row>
            <Col xs={8}> <h2 className="text-uppercase offset-bottom-4">
                Agreements List &nbsp; <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
            </h2>
                <Breadcrumbs breadCrumbsMap={ AGREEMENTS_MAP }  />
            </Col>
            <Col xs={4} className="text-right top-indent-4">
                <Link Btn={AddBtn} to={AGREEMENTS.LINK_EDIT()} disabled={expectAnswer} tooltip="Create organization" />
            </Col>
        </Row>
        <Row> <Col xs={12} md={8} mdOffset={2}> <Alert active message={errorMessage} onChange={clearError} /> </Col> </Row>
        <Row> <Col xs={12}>
            <div style={{width: '300px', marginBottom: '10px'}}> <SearchFilterConnected /> </div>
        </Col> </Row>
        <Row> <Col xs={12}>
            {list.length ? (<ConnectedTable />) : (<h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>)}
        </Col> </Row>
    </Page>
));
