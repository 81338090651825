
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';

// local dependencies
import { Spinner } from '../images';

// configuration
export const TYPE = {
    MIN_HEIGHT: 'MIN_HEIGHT',
    SPINNER: 'SPINNER',
    ICON: 'ICON',
    BOX: 'BOX',
};

/**
 * Preloader hide content and show spinner if we not ready to show.
 *
 * @param {Object} props
 * @public
 */
function Preloader ({ type, expectAnswer, children, height, ...attr }) {
    // NOTE do nothing
    if ( !expectAnswer ) return ( children||'' );
    switch ( type ) {
        case TYPE.SPINNER: return (<Spinner style={{width: '20px'}} {...attr} />);
        case TYPE.ICON: return (<IconPreloader {...attr} />);
        case TYPE.BOX: return (<BoxPreloader {...attr} />);
        case TYPE.MIN_HEIGHT: return (<BoxPreloader style={{minHeight: `${(height*0.6)||90}px`, marginTop: `${(height*0.4)||60}px`}} {...attr} />);
        // NOTE preloader used like default preloader in public index html
        default: return (<div className="def-preloader">
            <div id="SPW">
                <div id="SP_1" className="sp"> </div> <div id="SP_2" className="sp"> </div> <div id="SP_3" className="sp"> </div> <div id="SP_4" className="sp"> </div>
                <div id="SP_5" className="sp"> </div> <div id="SP_6" className="sp"> </div> <div id="SP_7" className="sp"> </div> <div id="SP_8" className="sp"> </div>
            </div>
        </div>);
    }
}

Preloader.propTypes = {
    height: PropTypes.number,
    expectAnswer: PropTypes.bool,
    type: PropTypes.oneOf( Object.keys(TYPE).map(key=>TYPE[key]) ),
};

export default Preloader;

/**
 * Based on font awesome spinner icon preloader
 *
 * @param {Object} props
 * @private
 */
function IconPreloader ( props ) {
    return (<strong>
        <i className="fa fa-spinner fa-spin fa-fw" aria-hidden="true"> </i>
        <span className="sr-only"> Loading... </span>
    </strong>);
}

/**
 * Prepared for boxes default preloader
 *
 * @param {Object} props
 * @private
 */
function BoxPreloader ( props ) {
    return (<div className="box-preloader">
        <div id="SPW" {...props}>
            <div id="SP_1" className="sp"> </div> <div id="SP_2" className="sp"> </div> <div id="SP_3" className="sp"> </div> <div id="SP_4" className="sp"> </div>
            <div id="SP_5" className="sp"> </div> <div id="SP_6" className="sp"> </div> <div id="SP_7" className="sp"> </div> <div id="SP_8" className="sp"> </div>
        </div>
    </div>);
}
