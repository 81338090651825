
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../types';
import { history } from '../../../store';
import { LanguageModel } from '../../../models';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';

function * initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let result = yield call(getData, id);
        yield put({type: EDIT.DATA, ...result});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, ...params});
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false, initialized: true});
}

function * updateDataSaga ( {type, id, ...data} ) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let result = yield call(updateData, id, data);
        yield put({type: EDIT.DATA, ...result});
        if ( id === NEW_ID ) {
            // NOTE update url take id from results
            yield call(history.push, ROUTES.LANGUAGES.LINK_EDIT(result));
        }
        yield call(toastr.success, 'Language', 'Data was successfully updated.');
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function* uploadFileSaga ({file, format}) {
    yield put({type: EDIT.META, expectAnswer: true});
    try {
        let data = yield select(state => state.languages.edit.data);
        yield call(uploadFile, file, format, data.code);
        yield call(toastr.success, 'Dictionary', 'File was successfully upload.');
        // NOTE clear input value
        yield put({type: EDIT.META, expectAnswer: false, uploadedFile: ''});
    } catch ({message}) {
        yield call(toastr.error, 'Error', message);
        // NOTE clear input value
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message, uploadedFile: ''});
    }
}

/**
 * connect all public sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPLOAD_FILE, uploadFileSaga);
}

/**
 *
 * @public
 */
function getData ( id ) {
    return id === NEW_ID ? LanguageModel.create({id: NEW_ID}) : LanguageModel.getById(id);
}
/**
 *
 * @param {String|Number} id
 * @param {Object} data
 * @public
 */
function updateData ( id, data = {} ) {
    // NOTE "spread" break the model instance
    return LanguageModel.partiallyUpdate(id, data);
}

/**
 * upload dictionary file
 * @param {File} file
 * @param {String} format - file format
 * @param {String} code - current language code
 * @private
 */
function uploadFile ( file, format, code ) {
    const data = new FormData();
    data.append('file', file);
    return instanceAPI({
        data,
        method: 'post',
        headers: {'language-code': code},
        url: format === 'csv' ? '/admin/language-constants/csv/import' : '/admin/language-constants/json/import',

    });
}


/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
