// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Col, Grid, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip} from '@material-ui/core';

// local dependencies
import {LIST} from './types';
import Page from '../../components/page';
import Alert from '../../components/alert-error';
import {Humanize} from '../../components/filter';
import SearchFilter from '../../components/search-filter';
import MdTablePagination from '../../components/pagination';
import Preloader, {TYPE} from '../../components/preloader';
import {ROLES_MAP} from "../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumb";
import {DelBtn} from "../../components/md-button";

// configure
export const allowedSort = ['name', 'description'];

/**
 * Table
 *
 * @private
 */
const ConnectedTable = connect(
    state => ({
        list: state.roles.list,
        page: state.roles.page,
        size: state.roles.size,
        totalPages: state.roles.totalPages,
        disabled: state.roles.expectAnswer,
    }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        changePage: page => dispatch({type: LIST.UPDATE_LIST, page}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
        changeSize: size => dispatch({type: LIST.UPDATE_LIST, size, page: 0}),
        deleteItem: id => dispatch({type: LIST.DELETE, id})
    })
)(({ list, disabled, page, size, totalPages, changePage, changeSize, deleteItem }) => (<Paper>
    <div style={{overflowX: 'auto'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <SortableCell name="name" />
                    <SortableCell name="description" style={{whiteSpace: 'nowrap'}} />
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (<TableRow key={index}>
                    <TableCell component="th" scope="row"> {item.name} </TableCell>
                    <TableCell component="th" scope="row"> {item.description} </TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <div>
                            {!item.isLocked ? (
                                <DelBtn onClick={() => deleteItem(item.id)} className="offset-left-2"/>
                            ) : ""}
                        </div>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={disabled}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</Paper>));

/**
 * Wrapper for sorting TH
 *
 * @private
 */
const SortableCell = connect(
    state => ({
        disabled: state.roles.expectAnswer,
        sortF: state.roles.sortF,
        sortD: state.roles.sortD,
    }),
    dispatch => ({ changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}) })
)(({disabled, sortF, sortD, name, changeSort, ...attr})=>(
    <TableCell className="th" {...attr}>
        <Tooltip
            enterDelay={300}
            placement="top-start"
            title={(<span>Sort by "<Humanize tag="strong" text={name} />"</span>)}
                >
            <span>
                <TableSortLabel
                    disabled={disabled}
                    active={sortF===name}
                    direction={sortD?'asc':'desc'}
                    onClick={()=>changeSort(name)}
                        >
                    <Humanize tag="strong" text={name} />
                </TableSortLabel>
            </span>
        </Tooltip>
    </TableCell>
));

/**
 *
 * @private
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.roles.filter,
        disabled: state.roles.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_LIST, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

/**
 * Table wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.roles }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
    })
)(({ list, init, initialized, expectAnswer, errorMessage, clearError }) => (
    <Page init={init} initialized={initialized}> <Grid fluid>
        <Row>
            <Col xs={12}>
                <h2 className="text-uppercase offset-bottom-4">
                    Roles &nbsp; <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                </h2>
                <Breadcrumbs breadCrumbsMap={ ROLES_MAP }  />
            </Col>
        </Row>
        <Row> <Col xs={12} md={8} mdOffset={2}> <Alert active message={errorMessage} onChange={clearError} /> </Col> </Row>
        <Row> <Col xs={12}>
            <div style={{width: '300px', marginBottom: '10px'}}>
                <SearchFilterConnected />
            </div>
        </Col> </Row>
        <Row> <Col xs={12}>
            {list.length ? (<ConnectedTable />) : (<h3 className="text-uppercase text-center text-highlighted"> There is no roles </h3>)}
        </Col> </Row>
    </Grid> </Page>
));
