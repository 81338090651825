// outsource dependencies
import {find} from 'lodash';
import {toastr} from 'react-redux-toastr'
import {call, put, take, takeEvery} from 'redux-saga/effects';

// local dependencies
import {PERMISSIONS} from './types';
import {instanceAPI} from '../../services/api.service';

export const UNCATEGORIZED_PERMISSIONS = 'Uncategorized permissions';

function* initializeSaga () {
    yield put({type: PERMISSIONS.CLEAR});
    yield put({type: PERMISSIONS.UPDATE_MAP});
    yield take(PERMISSIONS.DATA);
    yield put({type: PERMISSIONS.META, initialized: true});
}

function* uploadFileSaga ({file, forceSynchronize}) {
    yield put({type: PERMISSIONS.META, expectAnswer: true});
    try {
        yield call(uploadFile, file, forceSynchronize);
        yield call(toastr.success, 'Permissions', `File was successfully upload`);
        yield put({type: PERMISSIONS.UPDATE_MAP});
    } catch ({message}) {
        yield call(toastr.error, 'Error', message);
        yield put({type: PERMISSIONS.META, errorMessage: message});
    }
    yield put({type: PERMISSIONS.META, expectAnswer: false});
}

function* updateMapSaga () {
    yield put({type: PERMISSIONS.META, expectAnswer: true});
    try {
        let data = yield call(buildMap);
        yield put({type: PERMISSIONS.DATA, data});
    } catch ({message}) {
        yield put({type: PERMISSIONS.META, errorMessage: message});
        yield put({type: PERMISSIONS.DATA});
    }
    yield put({type: PERMISSIONS.META, expectAnswer: false});

}

function * changePermissionSaga ({type, ...options}) {
    yield put({type: PERMISSIONS.META, expectAnswer: true, errorMessage: null});
    try {
        yield call(updateRolePermission, options);
        yield put({type: PERMISSIONS.UPDATE_MAP});
        yield take(PERMISSIONS.DATA);
        yield put({type: PERMISSIONS.META, expectAnswer: false});
        yield call(toastr.success, 'Change permission', 'Role permissions was changed successfully.');
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: PERMISSIONS.META, errorMessage: message, expectAnswer: false});
    }
}

/**
 * connect all public sagas
 *
 * @public
 */
export default function * () {
    yield takeEvery(PERMISSIONS.UPDATE_MAP, updateMapSaga);
    yield takeEvery(PERMISSIONS.INITIALIZE, initializeSaga);
    yield takeEvery(PERMISSIONS.UPLOAD_FILE, uploadFileSaga);
    yield takeEvery(PERMISSIONS.CHANGE_PERMISSION, changePermissionSaga);
}

/**
 * upload permission csv file
 * @param {File} file
 * @private
 */
function uploadFile ( file , forceSynchronize) {
    console.log(forceSynchronize);
    const data = new FormData();
    data.append('file', file);
    let url = `/admin/permissions/csv/import` + (forceSynchronize === true ? '?forceSynchronize=true' : '');
    return instanceAPI({method: 'post', url: url, data});
}

/**
 * build role map for all roles and all permissions based on known permissions for client side
 *
 * @private
 */
function buildMap() {
    return new Promise((resolve, reject ) => {
        Promise.all([
            instanceAPI({method: 'post', url: `/roles/filter`, data: {size: 10000, sort: {field: 'name', order: 'ASC'}} }),
            instanceAPI({method: 'post', url: `/admin/permissions/permission-roles`})
        ]).then(([roles, permissions]) => {
            roles = roles.items || [];

            /*
            let rowMap = [], categorized = [];
            for (let NAME in PERMISSION) {
                let GROUP = PERMISSION[NAME];
                // NOTE add delimiter
                rowMap.push({delimiter: true, name: filters.humanize(NAME) + ' permissions'});
                // NOTE add cols with permission actions for each role
                for (let SUB_NAME in GROUP) {
                    let row = {cols: [], name: filters.humanize(SUB_NAME)};
                    let permission = find(permissions, {name: GROUP[SUB_NAME]}) || {name: SUB_NAME + ' ' + NAME};
                    // NOTE define permissions which are known on client side
                    permission.id&&categorized.push(permission);
                    for (let role of roles) {
                        let enabled = Boolean(find(permission.roles, {id: role.id}));
                        row.cols.push({role, enabled, permission, editable: permission.id});
                    }
                    rowMap.push(row);
                }
            }
            // NOTE add permissions which are not defined on client side
            let uncategorized = difference(permissions, categorized);
            if ( uncategorized.length ) {
                rowMap.push({delimiter: true, name: 'Uncategorized permissions'});
                for (let permission of uncategorized) {
                    let row = {cols: [], name: filters.humanize(permission.name)};
                    for (let role of roles) {
                        let enabled = Boolean(find(permission.roles, {id: role.id}));
                        row.cols.push({role, enabled, permission, editable: true});
                    }
                    rowMap.push(row);
                }
            }
            */

            let permissionGroupsMap = {};
            let permissionRows = [];
            let uncategorizedPermissions = [];
            // UNCATEGORIZED_PERMISSIONS
            if (permissions && permissions.length) {
                for (let permission of permissions) {
                    if (permission['permissionGroup']) {
                        if (!permissionGroupsMap[permission['permissionGroup']]) {
                            permissionGroupsMap[permission['permissionGroup']] = {items: []};
                        }
                        permissionGroupsMap[permission['permissionGroup']].items.push(permission);
                    } else {
                        uncategorizedPermissions.push(permission);
                    }
                }

                if (uncategorizedPermissions && uncategorizedPermissions.length) {
                    permissionGroupsMap[UNCATEGORIZED_PERMISSIONS] = {items: []};
                    for (let permission of uncategorizedPermissions) {
                        permissionGroupsMap[UNCATEGORIZED_PERMISSIONS].items.push(permission);
                    }
                }
            }
            for (let permissionGroupName in permissionGroupsMap) {
                let permissionGroup = permissionGroupsMap[permissionGroupName];

                permissionRows.push({delimiter: true, name: permissionGroupName});
                for (let permission of permissionGroup.items) {
                    let row = {cols: [], name: (permission.title ? permission.title : permission.name), code: permission.name, url: permission.url};
                    for (let role of roles) {
                        let enabled = Boolean(find(permission.roles, {id: role.id}));
                        row.cols.push({role, enabled, permission, editable: true});
                    }
                    permissionRows.push(row);
                }
            }

            // console.log(permissionRows);
            // resolve({roles, permissions, rowMap});
            resolve({roles, permissions, rowMap: permissionRows});
        }).catch(reject);
    });
}

/**
 * update role permission
 * @param {Object} data
 * @private
 */
function updateRolePermission ({role = {}, permission = {}}) {
    // NOTE add permission if a role doesn't have it before
    let allowed = !Boolean(find(permission.roles, {id: role.id}));
    return instanceAPI({method: 'post', url: '/admin/permissions/update-items', data: [{role, permission, allowed}]});
}
