/**
 * Helper function to build string of roles
 *
 * @param roles
 * @returns {string}
 */
export function buildRolesString(roles) {
    let result = '';
    if (roles && roles.length) {
        for (let i = 0; i < roles.length; i++) {
            result += (i === 0 ? '' : ', ') + roles[i].name;
        }
    }
    return result;
}
