// outsource dependencies
import React from 'react';
import {Paper} from '@material-ui/core';

// local dependencies
import {config} from '../../constants';
import CenteredBox from '../../components/centered-box';
import {Col, Row} from "react-bootstrap";

export default function () {
    return (
        <CenteredBox>
            <Paper className="indent-10 text-center">
                <Row>
                    <Col xs={12}>
                        <h1 lassName="text-center text-highlighted">Settings</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h3 className="text-left">
                            UI Build: <span className="text-highlighted">{config.version}</span>, release date: <span className="text-highlighted">{config.releaseDate}</span>
                        </h3>
                    </Col>
                </Row>
            </Paper>
        </CenteredBox>
    )
}