// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {reduxForm, FieldArray} from 'redux-form';
import {withStyles, CardContent, CardActions, Dialog, DialogTitle, DialogContent,} from '@material-ui/core';

// local dependencies
import {LOAD_DEMO_MODAL} from './actions';
import Preloader from '../../components/preloader';
import {InfoBtn} from '../../components/md-button';
import {FormControl, FormGroup, FormControlLabel, Checkbox, Divider} from '@material-ui/core';
import {find, findIndex} from "lodash";


export default withStyles({paper: {minWidth: '500px'}})(connect(
    state => ({
        showNew: state.loadDemoModal.meta.showNew,
        organization: state.loadDemoModal.organization,
        showModal: state.loadDemoModal.meta.showModal,
        initialized: state.loadDemoModal.meta.initialized,
        expectAnswer: state.loadDemoModal.meta.expectAnswer,
    }),
    dispatch => ({
        init: id => dispatch({type: LOAD_DEMO_MODAL.INITIALIZE, id}),
        closeModal: () => dispatch({type: LOAD_DEMO_MODAL.CLEAR}),
    }),
)(({showModal, initialized, closeModal, expectAnswer, classes, organization}) => (
    <Dialog
        open={showModal}
        onClose={closeModal}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        classes={{paper: classes.paper}}
        aria-labelledby="selectModalTitle"
    >
        <DialogTitle id="selectModalTitle" disableTypography={true} style={{padding: `10px 24px 0`}}>
            <h3 className="text-uppercase text-center">
                <span className="align-middle"> Load Demo Data </span>&nbsp;
                <Preloader expectAnswer={expectAnswer} type="ICON"/>
            </h3>
            <h4 className="text-center">
                <span className="align-middle"> ( {organization.name} <span className="h6 text-lowercase">org.</span> ) </span>
            </h4>
        </DialogTitle>
        <DialogContent style={{paddingBottom: 0}}>
            <Preloader expectAnswer={!initialized} type="MIN_HEIGHT" height={200}>
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <ConnectedDemoList/>
                    </Col>
                </Row>
            </Preloader>
        </DialogContent>
    </Dialog>
)));


const CheckboxGroup = connect(
    state => ({
        availableDemosList: state.loadDemoModal.availableDemosList,
        isAllSelected: state.loadDemoModal.meta.isAllSelected,
        disabled: state.loadDemoModal.meta.expectAnswer,
    }),
    dispatch => ({
        toggleSelectAll: (isAllSelected) => dispatch({type: LOAD_DEMO_MODAL.META, isAllSelected: isAllSelected})
    })
)(({fields, availableDemosList, toggleSelectAll, isAllSelected, disabled}) => {
    const allValues = fields.getAll();
    const toggle = (event, option = {}) => {
        const key = findIndex(allValues, {id: option.id});
        if (event.target.checked) {
            fields.push(option);
            if(fields.length === availableDemosList.length-1) toggleSelectAll(true);
            if (event.target.defaultValue === 'isAllSelected') {
                fields.removeAll();
                availableDemosList.map(item => fields.push(item));
                toggleSelectAll(!isAllSelected);
            }
        }
        else {
            toggleSelectAll(false);
            if (event.target.defaultValue === 'isAllSelected') {
                toggleSelectAll(!isAllSelected);
                fields.removeAll();
            }
            key !== -1 && fields.remove(key);
        }
    };

    return (<FormControl component="fieldset">
        <FormGroup>
            <FormControlLabel
                disabled={disabled}
                label={(<strong> Select all </strong>)}
                checked={isAllSelected}
                control={
                    <Checkbox style={{padding: '3px 12px'}} value="isAllSelected"
                              onChange={event => toggle(event, {id: 30})}/>}
            />
            <Divider style={{backgroundColor: 'grey', marginBottom: 3, opacity: 0.5}}/>
            {availableDemosList.map((option = {}, key) => (
                <FormControlLabel
                    disabled={disabled}
                    key={key}
                    label={option.label}
                    checked={Boolean(find(allValues, {id: option.id}))}
                    control={
                        <Checkbox style={{padding: '3px 12px'}} value={option.name}
                                       onChange={event => toggle(event, option)}/>}
                />
            ))}
        </FormGroup>
    </FormControl>);
});

/**
 * item component connected to form
 *
 * @param {Object} props
 */
const ConnectedDemoList = connect(
    state => ({
        initialValues: state.loadDemoModal.list,
        disabled: state.loadDemoModal.meta.expectAnswer,
    }),
    dispatch => ({
        closeModal: () => dispatch({type: LOAD_DEMO_MODAL.CLEAR}),
        update: formData => dispatch({type: LOAD_DEMO_MODAL.UPDATE_MODEL, ...formData}),
    })
)(reduxForm({
    validate,
    form: 'selectDemos',
    enableReinitialize: true
})(({update, handleSubmit, disabled, invalid, closeModal, reset}) => (
        <form autoComplete="off" name="selectDemos" onSubmit={handleSubmit(update)}>
            <CardContent style={{padding: 10}}>
                <Row><Col xs={12}>
                    <FieldArray
                        disabled={disabled}
                        name="demos"
                        component={CheckboxGroup}
                    />
                </Col></Row>
            </CardContent>
            <CardActions style={{justifyContent: 'flex-end'}}>
                <InfoBtn tooltip="Apply" type="submit" variant="text" disabled={invalid || disabled}> Apply </InfoBtn>
                <InfoBtn tooltip="Reset" variant="text" onClick={reset} disabled={disabled}> Reset </InfoBtn>
                <InfoBtn tooltip="Close" variant="text" onClick={closeModal}> Close </InfoBtn>
            </CardActions>
        </form>
    )
));

/**
 * common validation for all Risk model forms
 *
 * @param {Object} values
 * @constructor
 */
function validate(values) {
    let errors = {};
    // NAME
    if (!values.name) {
        errors.name = 'GLOBALS$NAME_REQUIRED';
    }
    return errors;
}
