// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core';
import {Col, Row} from 'react-bootstrap';

// local dependencies
import {LIST} from '../actions';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import ErrorMessage from '../../../components/alert-error';
import {config} from '../../../constants';
import {Truncate} from "../../../components/filter";
import moment from "moment";
import Preloader from "../../../components/preloader";


class List extends Component {

    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }

    render() {
       let { list } = this.props;
        return (
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={9}>
                        <h2 className="text-uppercase">
                            Webinars
                        </h2>
                    </Col>
                </Row>
                <Row className="offset-bottom-4">
                    <Col xs={12} sm={6} lg={3}>
                        <SearchFilterConnected/>
                    </Col>
                </Row>

                <ConnectedError/>
                {list.length ? (
                    <Paper> <ConnectedTable/> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> There are no webinars </h3>
                )}
            </ConnectedInitializer>

        );
    }
}

const ConnectedTable = connect(
    state => ({...state.importWebinars.list}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({data, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead>
                <TableRow>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('name')}
                        >
                            Title
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('description')}
                        >
                            Description
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">URL</TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'date'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('date')}
                        >
                            Date
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'expiryDate'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('expiryDate')}
                        >
                            Expiry Date
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow key={i}>
                    <TableCell className="text-nowrap">
                        <Truncate tag="h5" text={item.title || ''}
                                  options={{length: 50, end: '...', breakOnWord: true}}/>
                    </TableCell>
                    <TableCell className="text-nowrap">
                        <Truncate tag="h5" text={item.description || ''}
                                  options={{length: 50, end: '...', breakOnWord: true}}/>
                    </TableCell>
                    <TableCell className="text-nowrap">
                        <Truncate tag="h5" text={item.url || ''} options={{length: 50, end: '...', breakOnWord: true}}/>
                    </TableCell>
                    <TableCell className="text-nowrap">
                        {item.date && moment(item.date).format(config.clientTimeFormat)}
                    </TableCell>
                    <TableCell className="text-nowrap">
                        {item.expiryDate && moment(item.expiryDate).format(config.clientTimeFormat)}
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>));

const ConnectedInitializer = connect(
    state => ({initialize: state.importWebinars.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

export default connect(
    state => ({
        expectAnswer: state.importWebinars.list.expectAnswer,
        list: state.importWebinars.list.data,
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE})
    })
)(List)


/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.importWebinars.list.filter,
        disabled: state.importWebinars.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
    />
));

const ConnectedError = connect(
    state => ({message: state.importWebinars.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
