// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@material-ui/core';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Col, Grid, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import {ANALYTIC_TYPES, ANALYTIC_TYPES_MAP, ENTITY_TYPE, NEW_ID} from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {QLIK_ANALYTICS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {OrganizationModel} from "../../../models";
import {MdAsyncSelect, MdSelect} from "../../../components/md-select";
import SelectEntities from "../../../components/select-entities";
import MdSwitch from "../../../components/md-switch";
import InputImage from "../../../components/input-image";

const Examples = {
    WEB_INTEGRATION_ID: 'Tkfct_v1d3I5DGIf8JED5sb3UDTX9eX3',
    API_KEY_ID: '0b4d38e0-c26c-417a-9393-24679103b0dd',
    APPLICATION_ID: 'ac403e95-244b-4759-8768-2f9e1feaebc4',
    OBJECT_ID: 'aBrBPNq',

};

class Edit extends Component {
    componentDidMount() {
        this.props.initialize(this.props.match.params.id, this.props.match.params.cloneId);
    }

    componentWillUnmount() {
        this.props.clear();
    }


    render() {
        let {expectAnswer, match} = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        {isNew
                                            ? 'Create qlik analytic'
                                            : 'Edit qlik analytic'
                                        }
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                </h2>
                                <Breadcrumbs breadCrumbsMap={QLIK_ANALYTICS_MAP}/>
                                <ConnectedError/>
                                <ConnectedForm isNew={isNew}/>
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.qlikAnalytics.edit.expectAnswer}),
    dispatch => ({
        initialize: (id, cloneId) => dispatch({type: EDIT.INITIALIZE, id, cloneId}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.qlikAnalytics.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.qlikAnalytics.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        initialValues: state.qlikAnalytics.edit.data,
        analyticsParameters: state.qlikAnalytics.edit.analyticsParameters,
        disabled: state.qlikAnalytics.edit.expectAnswer,
        formValues: getFormValues('editQlikAnalytic')(state),
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData})
    })
)(reduxForm({
    form: 'editQlikAnalytic',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }
        return errors;
    }
})(({handleSubmit, invalid, analyticsParameters, pristine, disabled, update,  reset, isNew, cancel, formValues}) => {
    let dynamicParametersMap = {};
    if (formValues && formValues.externalAnalyticsType && analyticsParameters[formValues.externalAnalyticsType]) {
        dynamicParametersMap = analyticsParameters[formValues.externalAnalyticsType];
    }
    let dynamicParameters = Object.keys(dynamicParametersMap);

    return (
        <form autoComplete="off" name="editQlikAnalytic" onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-6">
                <Col sm={6} className="user-profile-logo">
                    <Row className="offset-top-8">
                        <Col>
                            <Field
                                required
                                name="logoDocument"
                                placeholder="Report logo"
                                url={formValues && formValues.logoDocument ? formValues.logoDocument.url : ""}
                                organization={formValues && formValues.organization ? formValues.organization : null}
                                component={InputImage}
                                className="form-control"
                                maxWeight={2}
                                crop={{aspect: 4 / 3}}
                                height={300}
                                processValue={(uploadResult) => {
                                    return uploadResult.logoDocument;
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <Field
                                name="name"
                                component={MdInput}
                                disabled={disabled}
                                placeholder='Name'
                                label={(<strong className="required-asterisk"> Name </strong>)}
                            />
                        </Col>
                        <Col xs={12}>
                            <Field
                                valueKey="value"
                                labelKey="label"
                                component={MdSelect}
                                name="externalAnalyticsType"
                                disabled={disabled}
                                label={(<strong> External analytics type </strong>)}
                                options={ANALYTIC_TYPES}
                                optionsLabel={({value}) => ANALYTIC_TYPES_MAP[value ? value : 'QLIK']}
                                simpleValue={value => {return {value, label: ANALYTIC_TYPES_MAP[value ? value : 'QLIK']}}}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-6">
                        <Col xs={12}>
                            <Field
                                disabled={disabled}
                                name="organization"
                                component={MdAsyncSelect}
                                label={(<strong> Organization </strong>)}
                                loadOptions={(name, done) => {
                                    OrganizationModel.getListByName(name, 6, [], 'Organization').then(done).catch(() => done([]));
                                }}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                            <Field
                                name="isPublic"
                                fullWidth={false}
                                disabled={disabled}
                                component={MdSwitch}
                                label={(<strong> Is Public? </strong>)}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            {formValues && !formValues.isPublic ? (
                                <SelectEntities
                                    isMulti
                                    name="roles"
                                    disabled={disabled}
                                    type={ENTITY_TYPE.USER_ROLES}
                                    label={(<strong> Roles </strong>)}
                                />
                            ) : ''}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        multiline={true}
                        name="description"
                        component={MdInput}
                        disabled={disabled}
                        placeholder="Description"
                        label={(<strong> Description </strong>)}
                    />
                </Col>
            </Row>

            <h4 className="text-center"><strong>External analytics parameters:</strong></h4>
            <Row className="offset-bottom-4">
                {dynamicParameters.map((name, i) => (<Col xs={12} lg={6} className="offset-bottom-2" key={i}>
                    {<Field
                        multiline={true}
                        name={name}
                        component={MdInput}
                        disabled={disabled}
                        label={(
                            <strong> {name.charAt(0).toUpperCase() + name.slice(1).toLowerCase().replace(/_/g, ' ')} </strong>)}
                        helpText={Examples[name] && (<code> Example: {Examples[name]}</code>)}
                    />}
                </Col>))}
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"/>
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"/>
                    <CancelBtn onClick={cancel}/>
                </Col>
            </Row>
        </form>
    )
}));
