
// outsource dependencies

// local dependencies

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        INITIALIZE: `${prefix}INITIALIZE`,
    }
})('@profile/');
