
// outsource dependencies

// local dependencies
import CONFIRM_DIALOG from './actions';

export default function ( state = { show: false, message: null }, action ) {
    // eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case CONFIRM_DIALOG.REQUEST:
            state = { ...state, ...options, show: true };
            break;
        case CONFIRM_DIALOG.SUCCESS:
        case CONFIRM_DIALOG.ERROR:
            state = { show: false, message: null};
            break;
    }
    return state;
}
