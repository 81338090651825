// outsource dependencies
import {delay} from 'redux-saga';
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {LOAD_DEMO_MODAL} from './actions';
import {instanceAPI} from '../../services/api.service';
import {toastr} from "react-redux-toastr";

// configure

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(LOAD_DEMO_MODAL.INITIALIZE, initializeSaga);
    yield takeEvery(LOAD_DEMO_MODAL.UPDATE_MODEL, updateDemoModelSaga);
}

function* initializeSaga({type, ...options}) {
    yield put({type: LOAD_DEMO_MODAL.META, errorMessage: null});
    // NOTE open modal
    yield put({type: LOAD_DEMO_MODAL.META, initialized: false, showModal: true});
    // NOTE save the ID and Name of the organization for later use
    yield put({type: LOAD_DEMO_MODAL.ORGANIZATION, organization: options});
    yield delay(800);
    yield put({type: LOAD_DEMO_MODAL.META, initialized: true});
}

const demoData = {
    organizationId: null,
    riskModelId: null,
    loadDemoBusinessUnits: false,
    loadDemoSubsidiaries: false,
    loadDemoSystems: false,
    loadDemoUsers: false,
    loadDemoVendors: false,
    loadDemoGDPRArticles: false,
    loadDemoQualitativeQuestionsCore: false,
    loadDemoCyberSecurityMaturity: false,
    loadDemoQualitativeQuestionsGDPR: false,
    loadDemoQualitativeQuestionsVendorInternal: false,
    loadDemoSecurityRequirements: false,
    loadDemoFramework_PCI_DSS: false,
    loadDemoFramework_NIST_SP800: false,
    loadDemoFramework_NIST_CSF: false,
    loadDemoFramework_ISO_IEC_27001_2005: false,
    loadDemoFramework_ISO_IEC_27001_2013: false,

    loadDemoTechnologies: false,
    loadDemoQuantMetrics: false,
    loadDemoScoringQuestionsAmplified: false,
    loadDemoScoringQuestionsConfidentiality: false,
    loadDemoScoringQuestionsFFIECInherent: false,
    loadDemoScoringQuestionsFFIECOrgMaturity: false,
    loadDemoScoringQuestionsImpactSystem: false,
    loadDemoScoringQuestionsImpactVendor: false,
    loadDemoScoringQuestionsImpactGDPR: false,
    loadDemoScoringQuestionsIntegrity: false,
    loadDemoScoringQuestionsLikelihoodSystem: false,
    loadDemoScoringQuestionsLikelihoodVendor: false,
    loadDemoScoringQuestionsLikelihoodGDPR: false,
    loadDemoScoringQuestionsMaturity: false,
};

function* updateDemoModelSaga({type, ...options}) {
    let data = {...demoData};
    const { demos } = options;
    let { id, name } = yield select(state => state.loadDemoModal.organization);

    for (let i = 0; i < demos.length; i++){
        for (let prop in data) if (demos[i].name === prop) data[prop] = true;
    }
    // demos.forEach( demo => {
    //     for (let prop in data) if (demo.name === prop) data[prop] = true;
    // });
    data.organizationId = id;
    yield put({type: LOAD_DEMO_MODAL.META, expectAnswer: true, errorMessage: null});
    try {
        yield call(updateDemoList, data);
        yield put({type: LOAD_DEMO_MODAL.CLEAR});
        yield delay(500);
        yield call(toastr.success, `${name}`, 'Data was successfully updated.');
        // NOTE remove preloader
        yield put({type: LOAD_DEMO_MODAL.META, expectAnswer: false});
    } catch ({message}) {
        yield delay(500);
        yield call(toastr.error, `Organization: ${name}. Error`, message);
        // NOTE remove preloader
        yield put({type: LOAD_DEMO_MODAL.META, expectAnswer: false});
    }
}

/**
 * update demos
 *
 * @param {Object} data
 * @returns {Promise}
 * @private
 */
function updateDemoList(data) {
    return instanceAPI({method: 'post', url: '/admin/organizations/load-demo-data', data});
}
