
// outsource dependencies

// local dependencies
import { PRIVATE } from '../actions/types';

import agreements from './agreements/reducers';
import auditLogs from './audit-logs/reducers';
import assetClasses from './asset-classes/reducer';
import cacheMetricsData from './cache-metrics-data/reducers';
import changePassword from './change-password/reducer';
import confirmDialog from '../components/confirmation-dialog/reducer';
import dataClasses from './data-classes/reducer';
import hints from './hints/reducer';
import importNews from './import-news/reducer';
import importPaceCourses from './import-pace-courses/reducer';
import importWebinars from './import-webinars/reducer';
import importWhatsNew from './import-whats-new/reducer';
import industries from './industries/reducer';
import languages from './languages/reducers';
import loadDemoModal from './load-demo-modal/reducer';
import organizations from './organizations/reducers';
import regulations from './regulations/reducer';
import permissions from './permissions/reducer';
import profile from './profile/reducer';
import qlikAnalytics from './qlik-analytics/reducers';
import quantificationModels from './quantification-models/reducer';
import roles from './roles/reducer';
import technologyCategories from './technology-categories/reducer';
import users from './users/reducers';

export default {
    agreements,
    assetClasses,
    auditLogs,
    cacheMetricsData,
    changePassword,
    confirmDialog,
    dataClasses,
    hints,
    importNews,
    importPaceCourses,
    importWebinars,
    importWhatsNew,
    industries,
    languages,
    loadDemoModal,
    organizations,
    permissions,
    privateLayout,
    profile,
    qlikAnalytics,
    quantificationModels,
    regulations,
    roles,
    technologyCategories,
    users,
};

function privateLayout ( state = { expanded: true }, action ) {
    let { type, expanded } = action;
    switch ( type ) {
        default:
            break;
        case PRIVATE.TOGGLE_ASIDE:
            state = { ...state, expanded };
            break;
    }
    // /@private/.test(type)&&
    // console.log(`%c REDUCER privateLayout ${type} `, 'color: #fff; background: #232323; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );
    return state;
}
