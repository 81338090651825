
// outsource dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
// STYLES inject ...
import './style';
// IE <=9 support
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// polyfill
import './polyfill';
// local dependencies
import { Maintenance } from './images';
import { history, store } from './store';
import PublicLayout from './public-layout';
import PrivateLayout from './private-layout';
import * as ROUTES from './constants/routes';
import Preloader from './components/preloader';
import CenteredBox from './components/centered-box';
import registerServiceWorker from './registerServiceWorker';

// configuration
let Root = connect( state => ({ ...state.app }), null)(RootView);

// NOTE insert Root Component in ReactDOM
ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();

/**
 * Root view which should contain all common dependencies on views
 *
 * @public
 */
function RootView ({health, ready, auth, location}) {
    if ( !health ) return (<CheckHealth />);
    if ( !ready ) return (<Preloader expectAnswer={true} />);
    return (<div id="main">
        <ConnectedRouter history={history} location={history.location}>
            <Switch>
                { auth ? [
                    (<Route key="1" path={ROUTES.PRIVATE} component={ PrivateLayout } />),
                    (<Redirect key="2" to={{ pathname: ROUTES.PRIVATE_WELCOME_SCREEN.LINK(), state: { from: location } }}/>)
                ] : [
                    (<Route key="1" component={ PublicLayout } />),
                    (<Redirect key="2" to={{ pathname: ROUTES.PUBLIC_WELCOME.LINK(), state: { from: location } }}/>)
                ]}
            </Switch>
        </ConnectedRouter>
        <ReduxToastr
            timeOut={2000}
            progressBar={true}
            newestOnTop={false}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            preventDuplicates={true}
                >
        </ReduxToastr>
    </div>);
}

/**
 * we should explain to the user - the app temporary unavailable
 *
 * @public
 */
function CheckHealth () {
    return (<div id="checkHealth">
        <CenteredBox>
            <div className="container">
                <div className="row content-wrap">
                    <div className="col-xs-12 col-md-5 offset-bottom-3">
                        <h1 className="text-uppercase text-highlighted">
                            <strong> Service unavailable </strong>
                        </h1>
                        <h3>
                            The current page constantly checks the status of the service, and as soon as it becomes available you are redirected to the future.
                        </h3>
                    </div>
                    <div className="col-xs-12 col-md-7">
                        <Maintenance className="img-responsive" />
                    </div>
                </div>
            </div>
        </CenteredBox>
    </div>);
}
