
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/index';

// local dependencies
import * as THEME from '../constants/material-themes';

/**
 *
 * @param {Object} props
 * @public
 */
function Theme ({ name = 'ROOT', ...attr}) {
    return (<MuiThemeProvider theme={THEME[name]} {...attr} />);
}

Theme.propTypes = {
    name: PropTypes.oneOf( Object.keys(THEME) ).isRequired,
};

export default Theme;
