
// outsource dependencies
import { call, put, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { historyPush } from '../../store';
import { APP } from '../../actions/types';
import { PRIVATE_WELCOME_SCREEN } from '../../constants/routes';
import { login, verifyMFA, MFA_ERROR } from '../../services/api.service';

function* signInSaga ({email, password, code}) {
    yield put({type: APP.SIGN_IN.META, errorMessage: null, notification: null });
    try {
        let { hasMultiFactorAuth, mfaToken } = yield select(state => state.app);
        let user = {};
        // NOTE MFA or common authorization flow
        if (!hasMultiFactorAuth) {
            user = yield call(login, {email, password});
        } else {
            user = yield call(verifyMFA, {mfaToken, code});
            yield put({type: APP.META, hasMultiFactorAuth: false, mfaToken: null});
        }
        yield put({type: APP.SIGN_IN.SUCCESS, user});
        yield call(historyPush, PRIVATE_WELCOME_SCREEN.LINK());
    } catch ( e ) {
        // NOTE MFA required
        if (e.error === MFA_ERROR) {
            let notification = 'Security code has been sent to you. Please enter code and try again';
            yield put({type: APP.META, hasMultiFactorAuth: true, mfaToken: e.mfa_token});
            yield put({type: APP.SIGN_IN.ERROR, errorMessage: null, notification});
        } else {
            yield put({type: APP.SIGN_IN.ERROR, errorMessage: e.message});
        }
    }
    yield put({type: APP.SIGN_IN.FINISH});
}

/**
 * connect page sagas
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(APP.SIGN_IN.REQUEST, signInSaga);
}
