// outsource dependencies
import { combineReducers } from 'redux';

// local dependencies
import loading from './import/reducer';
import list from './list/reducer';

export default combineReducers({
    loading,
    list
});

