
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Search, Close } from '@material-ui/icons';
import { FormControl, Input, Grid, IconButton, TextField, InputAdornment } from '@material-ui/core';

// local dependencies

// configure
TextFilter.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    apply: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
};

export default TextFilter;

/**
 *
 * @param { Object } props
 * @public
 */
export function TextFilter ({ apply, onInputChange, clear, value, disabled = false, placeholder = 'Search by name ...' }) {
    return (<form name="searchFilterForm" onSubmit={e => e.preventDefault()||apply(value)}>
        <Grid container>
            <Grid item xs={10}>
                <FormControl fullWidth>
                    <Input
                        name="search"
                        value={value}
                        autoComplete="off"
                        placeholder={placeholder}
                        onChange={e=> onInputChange(String(e.target.value).trimStart())}
                            />
                    {!value?'':(<IconButton onClick={clear} style={{position: 'absolute', right: 0, bottom: 0, padding: '4px'}}> <Close /> </IconButton>)}
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <IconButton type="submit" disabled={disabled} style={{padding: '6px'}}> <Search /> </IconButton>
            </Grid>
        </Grid>
    </form>);
}

/**
 * simple search field which can be used inside filter panel etc
 *
 * @param { Object } props
 * @public
 */
export function SimpleSearchField ({ onChange, clear, value, disabled = false, label, placeholder = 'Search by name ...' }) {
    return (
        <TextField
            value={value}
            fullWidth={true}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            label={label ? label : (<strong> Search </strong>)}
            InputProps={{
                endAdornment: (
                    !value ? '' : (<InputAdornment position="end">
                        <IconButton style={{padding: '4px'}} onClick={clear}>
                            <Close fontSize="small" />
                        </IconButton>
                    </InputAdornment>)
                ),
            }}/>
    );
}

SimpleSearchField.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    clear: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};
