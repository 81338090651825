// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@material-ui/core';
import {reduxForm, Field, change} from 'redux-form';
import {Grid, Row, Col} from 'react-bootstrap';

// local dependencies
import get from "lodash/get";
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPE, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {SubmitBtn, ResetBtn, CancelBtn} from '../../../components/md-button';
import {MdAsyncSelect} from "../../../components/md-select";
import {OrganizationModel} from "../../../models";
import {DATA_CLASSES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
// import { translate, withTranslation } from '../../../services/translate.service';
// import {DATA_CLASSES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";

export const changeField = (field, value) => change('editDataClass', field, value);


class Edit extends Component {
    componentDidMount() {
        this.props.initialize(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, match} = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        {isNew
                                            ? 'Create data class'
                                            : 'Edit data class'
                                        }
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"/>
                                </h2>
                                <Breadcrumbs breadCrumbsMap={ DATA_CLASSES_MAP }  />
                                <ConnectedError/>
                                <ConnectedForm isNew={isNew}/>
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.dataClasses.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.dataClasses.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.dataClasses.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        initialValues: state.dataClasses.edit.data,
        disabled: state.dataClasses.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editDataClass',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel}) => (
    <form autoComplete="off" name="editDataClass" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder='Name'
                    label={(<strong className="required-asterisk"> Name </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder='Description'
                    label={(<strong> Description </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12} lg={6} className="offset-bottom-4">
                <Field
                    isMulti={false}
                    isClearable={true}
                    disabled={disabled}
                    name="organization"
                    component={MdAsyncSelect}
                    label={(<strong> Organization </strong>)}
                    loadOptions={(name, done) => {
                        OrganizationModel.getListByName(name, 6, [], 'Organization').then(done).catch(() => done([]));
                    }}/>
            </Col>
            <Col xs={12} lg={6} className="offset-bottom-4">
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="regulations"
                    type={ENTITY_TYPE.REGULATIONS}
                    placeholder='Regulations'
                    label={(<strong> Regulations </strong>)}
                    getOptionLabel={option => `${get(option, 'name', '')}`}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"/>
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"/>
                <CancelBtn onClick={cancel}/>
            </Col>
        </Row>
    </form>
)));
