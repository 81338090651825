
// outsource dependencies
import { put, call, takeEvery } from 'redux-saga/effects';

// local dependencies
import { EDIT } from './actions';
import { instanceAPI } from '../../services/api.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
}

function* initializeSaga () {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData);
        yield put({type: EDIT.DATA, ...data});
        yield put({type: EDIT.META, initialized: true});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, ...data});
        yield put({type: EDIT.META, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * get data
 * @private
 */
function getData () {
    return instanceAPI({method: 'get', url: `/admin/users/self`});
}

/**
 * update data
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return instanceAPI({method: 'post', url: `/user-profile/update`, data});
}
