
// outsource dependencies
import { fork } from 'redux-saga/effects';

// local dependencies
import edit from "./edit/saga";
import list from "./list/saga";

/**
 * connect all public sagas
 *
 * @public
 */
export default function* () {
    yield fork(edit);
    yield fork(list);
}
