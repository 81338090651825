
// outsource dependencies
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// local dependencies
import SignIn from './sign-in';
import PublicWelcome from './welcome';
import * as ROUTES from '../constants/routes';
import ForgotPassword from './forgot-password';
import ChangePassword from './change-password';
import EmailConfirmation from './email-confirmation';

/**
 * wrapper for public pages
 *
 * @public
 */
export default function () {
    return (<Switch>
        <Route path={ROUTES.SIGN_IN.ROUTE} component={ SignIn } />
        <Route path={ROUTES.FORGOT_PASSWORD.ROUTE} component={ ForgotPassword } />
        <Route path={ROUTES.CHANGE_PASSWORD.ROUTE} component={ ChangePassword } />
        <Route path={ROUTES.EMAIL_CONFIRMATION.ROUTE} component={ EmailConfirmation } />
        <Route component={ PublicWelcome } />
    </Switch>);
}
