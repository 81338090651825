
// outsource dependencies
import { reset } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { put, call, takeEvery } from 'redux-saga/effects';

// local dependencies
import { EDIT } from './actions';
import { instanceAPI } from '../../services/api.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CHANGE_PASSWORD, changePasswordSaga);
}

function* changePasswordSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        yield call(changePassword, options);
        yield call(toastr.success, 'Change password', `Your password has been successfully changed`);
        yield put(reset('changePassword'));
        yield put({type: EDIT.META, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * change user password
 * @param {Object} data
 * @private
 */
function changePassword ( data ) {
    return instanceAPI({method: 'post', url: `/user-profile/change-password`, data});
}

