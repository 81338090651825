// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Grid, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {QLIK_ANALYTICS} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {AddBtn, CloneBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {QLIK_ANALYTICS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {CheckCircleOutline} from "@material-ui/icons";
import {buildRolesString} from "../../../services/utils";

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, list} = this.props;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <h2 className="text-uppercase">
                                Analytic Reports <Preloader expectAnswer={expectAnswer} type="ICON"/>
                            </h2>
                            <Breadcrumbs breadCrumbsMap={QLIK_ANALYTICS_MAP}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} to={QLIK_ANALYTICS.LINK_EDIT()} permission={PERMISSION.QLIK_ANALYTIC.CREATE}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected/>
                        </Col>
                    </Row>
                    <ConnectedError/>
                    {list.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>
                    )}
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.qlikAnalytics.list.expectAnswer, list: state.qlikAnalytics.list.data}),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.qlikAnalytics.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.qlikAnalytics.list.filter,
        disabled: state.qlikAnalytics.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
    />
));

const ConnectedTable = connect(
    state => ({...state.qlikAnalytics.list}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({data, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead>
                <TableRow>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('name')}
                        >
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('description')}
                        >
                            Description
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'organization'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('organization')}
                        >
                            Organization
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'externalAnalyticsType'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('externalAnalyticsType')}
                        >
                            Analytic Type
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        Access
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow key={i}>
                    <TableCell className="text-nowrap">{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.organization && item.organization.name}</TableCell>
                    <TableCell>{item.externalAnalyticsType && item.externalAnalyticsType}</TableCell>
                    <TableCell>
                        {item && item.isPublic ?
                            (<CheckCircleOutline nativeColor="#009703" />)
                            : (item.roles && item.roles.length ? buildRolesString(item.roles)
                                : '')}
                    </TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <div>
                            <Link
                                Btn={CloneBtn}
                                permission={PERMISSION.QLIK_ANALYTIC.UPDATE}
                                to={QLIK_ANALYTICS.LINK_CLONE({
                                    cloneId: item.id,
                                    query: {back: history.location.pathname + history.location.search}
                                })}
                            />
                            <Link
                                Btn={EditBtn}
                                permission={PERMISSION.QLIK_ANALYTIC.UPDATE}
                                className="offset-left-2"
                                to={QLIK_ANALYTICS.LINK_EDIT({
                                    id: item.id,
                                    query: {back: history.location.pathname + history.location.search}
                                })}
                            />
                            <DelBtn permission={PERMISSION.QLIK_ANALYTIC.DELETE} onClick={() => deleteItem(item.id)}
                                    className="offset-left-2"/>
                        </div>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>));

const ConnectedError = connect(
    state => ({message: state.qlikAnalytics.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
