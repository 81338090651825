// outsource dependencies
import axios from 'axios';

// local dependencies
import store from '../store';
import is from './is.service';
import {config} from '../constants';
import {UserModel} from '../models';
import {APP} from '../actions/types';
import storage from './storage.service';
import {getMessage} from '../constants/error-messages';
// absolute url to API
let API_PATH = config.serviceUrl+config.apiPath;
// within "prepareError"  app config unavailable tha the reason to make alias
const DEBUG = Boolean(config.DEBUG);
// private names
const AUTH_STORE = 'sAuth';
const AUTH_BASIC = 'Basic '+window.btoa(config.base);
const AUTH_BEARER = 'Bearer ';
const AUTH_HEADER = 'Authorization';
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const MFA_ERROR = 'mfa_required';

/**
 * @description axios instance with base configuration of app
 * @public
 */
let instanceAPI = axios.create({
    baseURL: API_PATH,
    withCredentials: false,
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    },
});
// NOTE add custom setup auth
instanceAPI.setupAuth = setupAuth.bind(instanceAPI);
// axios.setupAuth = setupAuth.bind(axios);
instanceAPI.setupAuth();
// axios.setupAuth();
// TODO remove - check session token broke on fly
// window.test = () => instanceAPI.setupAuth('fake')&&recordSession({access_token: 'fake', refresh_token: 'fake'});
/**
 * @description correct setup auth headers
 *
 * @param {String} [token=AUTH_BASIC]
 * @return {Object}
 * @private
 */
function setupAuth ( token ) {
    let instance = this;
    instance.defaults.headers[AUTH_HEADER] = token ? AUTH_BEARER + token : AUTH_BASIC;
    return instance;
}


/**
 * @description origin axios instance interceptors
 * @private
 */
axios.interceptors.response.use(
    prepareResponse,
    prepareError
);

/**
 * @description sync check to known is user logged in
 * NOTE to known more {@link https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c | axios-interceptors-refresh-token}
 * @private
 */
instanceAPI.interceptors.response.use(
    prepareResponse,
    error => (isLoggedIn() && error.request.status === 401) ? handleRefreshSession(error) : prepareError(error)
);

/**
 * @description local variables to correctness refreshing session process
 * @private
 */
let stuckRequests = [];

/**
 * stuck request should be
 * @typedef {Object} stuckRequest
 * @property {Error}    error
 * @property {Object}   config
 * @property {Function} resolve
 * @property {Function} reject
 */

/**
 * Obtain IdP Url from the Path
 *
 * @param path
 * @returns {*}
 */
export function getIdPUrl(path) {
    let result = (config.idpUrl ? config.idpUrl : config.serviceUrl) + path;
    return result;
}

/**
 * @description store all requests with 401 refresh session and try send request again
 * @param {Object} error
 * @private
 */
function handleRefreshSession ( error ) {
    let { config } = error;
    // NOTE support request may get 401 (JAVA Spring is fucking genius ...) we must skip restoring for that case
    if ( /logout|\/oauth\/token/.test(config.url) ) {
        return prepareError(error);
    }
    // NOTE try to refresh only once per token
    if ( !handleRefreshSession.isRefreshing ) {
        handleRefreshSession.isRefreshing = true;
        refreshSession()
            .then(session => {
                // NOTE resend all
                stuckRequests.map(({config, resolve, reject}) => {
                    // NOTE set common authentication header
                    config.headers[AUTH_HEADER] = AUTH_BEARER+session[ACCESS_TOKEN];
                    instanceAPI(config).then(resolve).catch(reject);
                    return null;
                });
                // NOTE start new stuck
                stuckRequests = [];
                handleRefreshSession.isRefreshing = false;
            })
            .catch(() => {
                // NOTE sign out using app action to update view
                store.dispatch({type: APP.SIGN_OUT.REQUEST});
                // NOTE reject all
                stuckRequests.map(({error, reject}) => reject(error));
                // NOTE start new stuck
                stuckRequests = [];
                handleRefreshSession.isRefreshing = false;
            });
    }
    // NOTE determine first trying to restore session to prevent recursive restoring session for not allowed request based on user permissions
    if ( !config._wasTryingToRestore ) {
        return new Promise((resolve, reject) => {
            config._wasTryingToRestore = true;
            stuckRequests.push({ config, error, resolve, reject });
        });
    } else {
        return prepareError(error);
    }
}

/**
 * @description prepare results. Solution to prepare data ... or not
 * @param {Object} response
 * @private
 */
function prepareResponse ( response ) {
    // NOTE solution to prepare data
    return response.data;
}

/**
 * @description prepare error
 * @param {Object} error
 * @private
 */
function prepareError ( error ) {
    let { response, request, config } = error;
    DEBUG&&console.log('%c Interceptor ', 'background: #EC1B24; color: #fff; font-size: 14px; font-weigth: bold;'
        ,'\n error:', error
        ,'\n URL:', config.url
        ,'\n config:', config
        ,'\n request:', request
        ,'\n response:', response
    );
    let data = response ? response.data : { exceptionCode: ['CROSS_DOMAIN_REQUEST'] };
    let message = getMessage(data.exceptionCode, data.message);
    return Promise.reject({ ...data, message });
}

/**
 * @description sync check to known is user logged in
 * @param {Object} session
 * @private
 */
function recordSession ( {access_token, refresh_token} ) {
    // NOTE record session at the moment primitive
    storage.set(AUTH_STORE, {
        [ACCESS_TOKEN]: access_token,
        [REFRESH_TOKEN]: refresh_token
    });
}

/**
 * @description sync check to known is user logged in
 * @private
 */
function clearSession () {
    // NOTE clear session at the moment primitive
    storage.remove(AUTH_STORE);
}

/**
 * @description sync check to known is user logged in
 * @example isLoggedIn(); // => true/false
 * @returns {Boolean}
 * @public
 */
function isLoggedIn () {
    return !is.empty(storage.get(AUTH_STORE));
}

/**
 * @description
 * @example logout().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function logout () {
    return new Promise(resolve => {
        if ( !isLoggedIn() ) {
            instanceAPI.setupAuth();
            return resolve({});
        }
        instanceAPI({method: 'get', url: getIdPUrl('/logout')})
            .then(() => {
                clearSession();
                instanceAPI.setupAuth();
                resolve({});
            })
            .catch(() => {
                clearSession();
                instanceAPI.setupAuth();
                resolve({});
            });
    });
}

/**
 * @description manual authentication
 * @example login().then( ... ).catch( ... )
 * @param {Object} credential => {email: 'valid email', password: 'password'}
 * @returns {Promise}
 * @public
 */
function login ( {email, password} ) {
    return new Promise((resolve, reject) => {
        let headers = {'Content-Type': 'application/json'}
        headers[AUTH_HEADER] = AUTH_BASIC;
        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            // params: { grant_type: 'password', username: email, password },
            data: { grant_type: 'password', username: email, password },
            headers: headers
        })
            .then(data => {
                // alias
                let session = data;
                // set common authentication header
                instanceAPI.setupAuth(session[ACCESS_TOKEN]);
                // check token on API
                getSelf()
                    .then(user => {
                        let isAdmin = false;
                        for (let key in user.roleNames) {
                            let roleName = user.roleNames[key];
                            if (roleName.toLowerCase() === 'admin') {
                                isAdmin = true;
                            }
                        }

                        // Forbid access for NON Admin User
                        if (!isAdmin) {
                            throw new Error(getMessage('CREDENTIALS_FORBIDDEN'));
                        }

                        recordSession(session);
                        resolve(user);
                    })
                    // NOTE execute application logout
                    .catch(error => logout().finally(() => {
                        reject({
                            ...error,
                            message: getMessage('CREDENTIALS_FORBIDDEN'),
                        });
                    }));
            })
            // NOTE without preparing error
            .catch(error => logout().finally(() => {
                reject({
                    ...error,
                    message: getMessage('INVALID_CREDENTIALS'),
                });
            }));
    });
}

/**
 * @description check multi-factor authentication
 * @example verifyMFA().then( ... ).catch( ... )
 * @param {Object}
 * @returns {Promise}
 * @public
 */
function verifyMFA ({ mfaToken, code }) {
    return new Promise((resolve, reject) => {
        let headers = {'Content-Type': 'application/json'}
        headers[AUTH_HEADER] = AUTH_BASIC;

        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            data: {grant_type: "mfa", mfa_token: mfaToken, mfa_code: code},
            headers: headers
        })
            .then(data => {
                // alias
                let session = data;
                // set common authentication header
                instanceAPI.setupAuth(session[ACCESS_TOKEN]);
                // check token on API
                getSelf()
                    .then(user => {
                        recordSession(session);
                        resolve(user);
                    })
                    // NOTE execute application logout
                    .catch(error => logout().finally(() => reject(error)));
            })
            // NOTE without preparing error
            .catch(error => logout().finally(() => reject(error)));
    });
}

/**
 * @description try to refresh session using refresh_token
 * @example refreshSession().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function refreshSession () {
    return new Promise((resolve, reject) => {
        // NOTE remove authentication header if it present
        instanceAPI.setupAuth();
        // NOTE get refresh token
        let refresh_token = (storage.get(AUTH_STORE)||{})[REFRESH_TOKEN];
        // NOTE use the axios origin instance to refresh and store new session data
        let headers = {'Content-Type': 'application/json'}
        headers[AUTH_HEADER] = AUTH_BASIC;
        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            data: { grant_type: 'refresh_token', refresh_token },
            headers: headers
        }).then(session => {
            recordSession(session);
            instanceAPI.setupAuth(session[ACCESS_TOKEN]);
            resolve(session);
        }).catch(error => logout().then(() => reject(error) ) );
    });
}

/**
 * @description try to restore session after reloading application page
 * @example restoreSession().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function restoreSession () {
    return new Promise((resolve, reject) => {
        // NOTE do not have session at all
        if ( !isLoggedIn() ) {
            return logout().finally(() => reject({}));
        }
        // NOTE get from storage
        let session = storage.get(AUTH_STORE);
        // NOTE set common authentication header
        instanceAPI.setupAuth(session[ACCESS_TOKEN]);
        // NOTE check token on API
        getSelf().then(user => {
            recordSession(storage.get(AUTH_STORE));
            resolve(user);
        }).catch(error => logout().finally(() => reject(error)) );
    });
}

/**
 * get logged user
 *
 * @returns {Promise}
 * @public
 */
function getSelf () {
    return UserModel.getSelf();
}

/**
 * @description check token for changing password
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function verifyPasswordToken ({ token }) {
    // NOTE used the origin axios instance
    return axios.get(`${API_PATH}/anonymous/reset-password/verify-code/${token}`);
    // return axios.get(`${API_PATH}/anonymous/reset-password/verify-code`, {params: { resetCode: token }});
}

/**
 * @description change password
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function changePassword ( {token, password } ) {
    // NOTE used the origin axios instance
    return axios({
        method: 'post',
        data: {code: token, password},
        url: `${API_PATH}/anonymous/reset-password/apply`,
    });
}

/**
 * create user
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function signUp ( data ) {
    // NOTE used the origin axios instance
    return axios({ data, method: 'post', url: API_PATH+'/oauth/token/sign-up' });
}

/**
 * @description change password
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function forgotPassword ( {email} ) {
    // NOTE used the origin axios instance
    // NOTE api/anonymous/reset-password/send-reset-email
    return axios.post(API_PATH+'/anonymous/reset-password/send-reset-email', {email});
}

/**
 * @description change password
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function emailConfirmation ( {token} ) {
    // NOTE used the origin axios instance
    return axios.get(API_PATH+'/oauth/token/confirmation', { params: {token} });
}

/**
 * @description check health of server
 * @example checkHealth().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function checkHealth () {
    // NOTE used the origin axios instance
    return axios.get(config.serviceUrl+'/actuator/health');
}

/**
 * get download link
 *
 * @param {String} downloadType
 * @public
 */
function getDownloadLink ( downloadType ) {
    return new Promise((resolve, reject) => {
        instanceAPI({ method: 'get', url: `/data-export/get-download-url/${downloadType}` })
            .then(result => resolve(result) )
            .catch(reject);
    });
}

// named export
export {
    instanceAPI,
    API_PATH,
    MFA_ERROR,
    AUTH_STORE,
    AUTH_HEADER,
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    login,
    logout,
    getDownloadLink,
    signUp,
    getSelf,
    verifyMFA,
    isLoggedIn,
    checkHealth,
    forgotPassword,
    restoreSession,
    refreshSession,
    changePassword,
    emailConfirmation,
    verifyPasswordToken,
};
