
// outsource dependencies
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Grid, Row, Col } from 'react-bootstrap';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { InputAdornment, IconButton, Paper } from '@material-ui/core';

// local dependencies
import { EDIT } from './actions';
import { history } from '../../store';
import Input from '../../components/md-input';
import Alert from '../../components/alert-error';
import Preloader, { TYPE } from '../../components/preloader';
import { CancelBtn, SubmitBtn, ResetBtn } from '../../components/md-button';

class Edit extends Component {
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, errorMessage, clearError } = this.props;
        return (
            <Grid fluid>
                <Row className="top-indent-4 offset-bottom-2">
                    <Col xs={12} md={6} mdOffset={3}>
                        <Alert active message={errorMessage} onChange={clearError} />
                    </Col>
                </Row>
                <Row className="offset-bottom-8">
                    <Col xs={12} md={6} mdOffset={3}>
                        <Paper className="indent-5">
                            <h2 className="text-center offset-bottom-4">
                                Change password
                                <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                            </h2>
                            <ConnectedForm />
                        </Paper>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default connect(
    state => ({
        expectAnswer: state.changePassword.expectAnswer,
        errorMessage: state.changePassword.errorMessage,
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null}),
    })
)(Edit)

/**
 * Password component
 * @private
 */
class Password extends Component {
    constructor(props){
        super(props);
        this.state = { visible: false }
    }

    showPassword = () => {
        this.setState({visible: !this.state.visible});
    };

    render () {
        let { visible } = this.state;
        return (
            <Input
                {...this.props}
                type={visible ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={this.showPassword}
                            aria-label="Toggle password visibility"
                                >
                            {visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }/>
        )
    }

}


const ConnectedForm = connect(
    state => ({disabled: state.changePassword.expectAnswer}),
    dispatch => ({ changePassword: ({oldPassword, password}) => dispatch({type: EDIT.CHANGE_PASSWORD, oldPassword, password}) })
)(reduxForm({
    form: 'changePassword',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // old password
        if (!values.oldPassword) { errors.oldPassword = 'Old password is required'; }
        // new password
        if (!values.password || values.password.length < 8) {
            errors.password = 'Name is required and must contain at least 8 symbol character';
        }
        // confirm password
        if ( !values.confirmPassword ) {
            errors.confirmPassword = 'Password confirmation is required';
        } else if ( values.confirmPassword !== values.password ) {
            errors.confirmPassword = 'Passwords confirmation do not match with password';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, changePassword, reset})=>(
    <form autoComplete="off" name="changePassword" onSubmit={handleSubmit(changePassword)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="oldPassword"
                    disabled={disabled}
                    component={Password}
                    placeholder="Old Password"
                    label={(<strong> Old Password</strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="password"
                    disabled={disabled}
                    component={Password}
                    placeholder="New Password"
                    label={(<strong> New Password </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    name="confirmPassword"
                    disabled={disabled}
                    component={Password}
                    placeholder="Confirm Password"
                    label={(<strong> Confirm Password </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn disabled={disabled||pristine||invalid} />
                &nbsp;&nbsp;&nbsp;
                <ResetBtn disabled={disabled||pristine} onClick={reset} />
                &nbsp;&nbsp;&nbsp;
                <CancelBtn disabled={disabled} onClick={() => history.goBack()} />
            </Col>
        </Row>
    </form>
)));

