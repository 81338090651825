
// outsource dependencies
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// local dependencies
import { HINTS } from '../../constants/routes';

// nested pages
import List from './list';
import Edit from './edit';

export default function ( props ) {
    let { location } = props;
    return (
        <Switch>
            <Route path={HINTS.LIST} component={ List } />
            <Route path={HINTS.EDIT} component={ Edit } />
            {/* OTHERWISE */}
            <Redirect to={{ pathname: HINTS.LINK(), state: { from: location } }}/>
        </Switch>
    );
}
