
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, FormHelperText } from '@material-ui/core';

// local dependencies

// configuration

/**
 * mui radio component synchronized with redux-form
 *
 * @public
 */
export default function MdRadio ({ input, meta, options = [], label, helpText = '', skipTouch = false, disabled, optionProps = {}, ...attr }) {
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = meta.error;
    }
    return (<FormControl autoComplete="off" component="fieldset" error={Boolean(message)}>
        {!label ? '' : (<FormLabel>{label}</FormLabel>)}
        <RadioGroup
            autoComplete="off"
            {...attr}
            {...input}
            id={input.name}
            name={input.name}
            value={input.value}
            onChange={(event, value) => input.onChange(value)}
                >
            {options.map((item, index) => (
                <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} disabled={disabled} {...optionProps}/>
            ))}
        </RadioGroup>
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
};

MdRadio.propTypes = {
    optionProps: PropTypes.object,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
};

/**
 * mui radio component
 *
 * @public
 */
export const SimpleRadio = function ({ value, name, options = [], onChange, label, disabled, optionProps = {}, helpText = '', message = '', ...attr }) {
    return (<FormControl autoComplete="off" error={Boolean(message)}>
        {!label ? '' : (<FormLabel>{label}</FormLabel>)}
        <RadioGroup
            autoComplete="off"
            {...attr}
            id={name}
            name={name}
            value={value}
            onChange={(event, value) => onChange(value)}
                >
            {options.map((item, index) => (
                <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} disabled={disabled} {...optionProps}/>
            ))}
        </RadioGroup>
        {!helpText ? '': (<FormHelperText component="label" htmlFor={name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={name}>{message}</FormHelperText>)}
    </FormControl>);
};

SimpleRadio.propTypes = {
    value: PropTypes.any,
    optionProps: PropTypes.object,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};
