
// outsource dependencies
import is from '../services/is.service';

// local dependencies
import BaseModel from './base.model';
import { instanceAPI } from '../services/api.service';
import BusinessUnitModel from './business-unit.model';

/**
 *
 *
 * @constructor UserModel
 * @type {UserModel}
 * @public
 */
class UserModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    get fullName () {
        return (this.firstName||'')+' '+(this.lastName||'');
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {
        !is.array(this.roleNames)&&(this.roleNames = []);
        this.businessUnit&&(this.businessUnit = BusinessUnitModel.create(this.businessUnit));
    }

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @override BaseModel
     * @private
     */
    static get baseRoute () {
        return '/admin/users';
    }

    /**
     * get logged user
     *
     * @override BaseModel
     * @public
     */
    static getSelf () {
        return new Promise((resolve, reject) => {
            instanceAPI({ method: 'get', url: '/admin/users/self' })
                .then(user => resolve( UserModel.create(user)) )
                .catch(reject);
        });
    }

    /**
     * get logged user
     *
     * @override BaseModel
     * @public
     */
    static resetTOTP({id}) {
        return new Promise((resolve, reject) => {
            instanceAPI({method: 'put', url: `/admin/users/totp/reset/${id}`}).then(user => resolve(user) ).catch(reject);
        });
    }
}

export default UserModel;
