// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {IconButton, Paper, Tab, Tabs, Tooltip} from '@material-ui/core';
import {arrayPush, change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';
import {Col, Grid, Row} from 'react-bootstrap';

// local dependencies
import get from "lodash/get";
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {
    ENTITY_TYPE,
    NEW_ID,
    REGULATORY_DATA_SPECIFIC_EXEMPTIONS_MAP,
    REGULATORY_ENTRY_LEVEL_EXEMPTIONS_MAP,
    REGULATORY_JURISDICTION_TYPE,
    REGULATORY_TIME_UNITS,
    REGULATORY_TIME_UNITS_MAP,
    REGULATORY_TYPES,
    REGULATORY_TYPES_MAP
} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {AddIconBtn, CancelBtn, DeleteIconBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {MdAsyncSelect, MdSelect} from "../../../components/md-select";
import {REGULATIONS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {instanceAPI} from "../../../services/api.service";
import MdDatePicker from "../../../components/md-date-picker";
import MdSwitch from "../../../components/md-switch";
import {withStyles} from "@material-ui/core/index";
import {CloudDownload, HelpOutline} from "@material-ui/icons";
import Dropzone from "react-dropzone";
import withDownloadLink from "../../../components/download-link";

export const FORM_NAME = 'editRegulation';
export const changeField = (field, value) => change(FORM_NAME, field, value);


class Edit extends Component {
    componentDidMount() {
        this.props.initialize(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, match} = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={12} mdOffset={0}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        {isNew
                                            ? 'Create regulation'
                                            : 'Edit regulation'
                                        }
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"/>
                                </h2>
                                <Breadcrumbs breadCrumbsMap={ REGULATIONS_MAP }  />
                                <ConnectedError/>
                                <ConnectedForm isNew={isNew}/>
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.regulations.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.regulations.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.regulations.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        initialValues: state.regulations.edit.data,
        disabled: state.regulations.edit.expectAnswer,
        currentTab: state.regulations.edit.currentTab,
        formValues: getFormValues(FORM_NAME)(state),
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeTab: currentTab => dispatch({type: EDIT.META, currentTab}),
        addRegulationAge: (data) => dispatch(arrayPush(FORM_NAME, `regulationAges`, data)),
        removeDocument: () => dispatch(changeField('document', null)),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }
        if (!values.jurisdictionType) {
            errors.jurisdictionType = 'Jurisdiction type is required';
        }
        if ( !values.acronym ) {
            errors.acronym = 'Acronym is required';
        } else if ( values.acronym.length > 24 ) {
            errors.acronym = 'Acronym must be no more than 24 characters';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, changeTab, currentTab, addRegulationAge, formValues = {}, removeDocument}) => {
    return (
        <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>

            <Tabs
                centered={false}
                value={currentTab}
                indicatorColor="primary"
                className="material-tab-container offset-top-6"
                onChange={(e, tab) => {
                    changeTab(tab)
                }}
                variant="scrollable"
            >
                <Tab value={1} label='General' className="material-tab" />
                <Tab value={2} label='Scope' className="material-tab" />
                <Tab value={3} label='Business Obligations' className="material-tab" />
                <Tab value={4} label='Individual Rights' className="material-tab" />
                <Tab value={5} label='SLAs' className="material-tab" />
                <Tab value={6} label='Data Specific' className="material-tab" />
                <Tab value={7} label='Security Requirements' className="material-tab" />
            </Tabs>

            <TabPanel value={1} index={currentTab}>
                <Row>
                    <Col xs={6}>
                        <Row>
                            <Col xs={6} className="offset-bottom-4 offset-top-4">
                                <Field
                                    name="acronym"
                                    component={MdInput}
                                    disabled={disabled}
                                    placeholder='Acronym'
                                    label={(<strong className="required-asterisk"> Acronym </strong>)}
                                />
                            </Col>
                            <Col xs={6} className="offset-bottom-4 offset-top-4">
                                <Field
                                    labelKey="label"
                                    valueKey="value"
                                    name="jurisdictionType"
                                    disabled={disabled}
                                    component={MdSelect}
                                    placeholder="jurisdictionType"
                                    options={[REGULATORY_JURISDICTION_TYPE.NONE, REGULATORY_JURISDICTION_TYPE.US, REGULATORY_JURISDICTION_TYPE.US_STATE, REGULATORY_JURISDICTION_TYPE.EU, REGULATORY_JURISDICTION_TYPE.INTERNATIONAL]}
                                    optionsLabel={
                                        ({value})=> {
                                            if (value === REGULATORY_JURISDICTION_TYPE.NONE){return 'None'}
                                            if (value === REGULATORY_JURISDICTION_TYPE.US){return 'US Federal'}
                                            if (value === REGULATORY_JURISDICTION_TYPE.US_STATE){return 'US state'}
                                            if (value === REGULATORY_JURISDICTION_TYPE.EU){return 'EU'}
                                            if (value === REGULATORY_JURISDICTION_TYPE.INTERNATIONAL){return 'International'}
                                        }
                                    }
                                    label={(<strong className="required-asterisk"> Jurisdiction type </strong>)}
                                    simpleValue={(value) => {
                                        let lbl = '';
                                        if (value === REGULATORY_JURISDICTION_TYPE.NONE){lbl = 'None'}
                                        if (value === REGULATORY_JURISDICTION_TYPE.US){lbl = 'US Federal'}
                                        if (value === REGULATORY_JURISDICTION_TYPE.US_STATE){lbl = 'US state'}
                                        if (value === REGULATORY_JURISDICTION_TYPE.EU){lbl = 'EU'}
                                        if (value === REGULATORY_JURISDICTION_TYPE.INTERNATIONAL){lbl = 'International'}
                                        return {value, label: lbl} }}
                                />
                            </Col>
                            <Col xs={12} className="offset-bottom-4">
                                <Field
                                    name="name"
                                    component={MdInput}
                                    disabled={disabled}
                                    placeholder='Name'
                                    label={(<strong className="required-asterisk"> Name </strong>)}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Row>
                            <Col xs={12}>
                                {
                                    get(formValues, 'document') && (
                                        <div>
                                            <strong> Laws Document: </strong>
                                            <strong>
                                                {get(formValues, 'document.fileName')} &nbsp;
                                                {get(formValues, 'document.downloadUrl') && (
                                                    <DownloadLink className="offset-bottom-1 offset-left-2" link={() => get(formValues, 'document.downloadUrl')}/>
                                                )}
                                                &nbsp;<DeleteIconBtn className="offset-bottom-1" style={{padding: '5px'}} onClick={() => removeDocument()}/>
                                            </strong>
                                        </div>
                                    )
                                }
                                {!get(formValues, 'document') && (<div><strong> Upload Laws Document </strong></div>)}
                            </Col>
                            <Col xs={12} className="dropzone">
                                <UploadFile/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="description"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Description'
                            label={(<strong> Description </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="body"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Regulatory body'
                            label={(<strong> Regulatory body </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="regulatedParty"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Regulated party'
                            label={(<strong> Regulated party </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="protectedParty"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Protected party'
                            label={(<strong> Protected party </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="protectedInformation"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Protected information'
                            label={(<strong> Protected information </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={7}>
                        <Field
                            multiline={true}
                            name="urlForNotification"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='URL for notification'
                            label={(<strong> URL for notification </strong>)}
                        />
                    </Col>
                    <Col xs={5}>
                        <Field
                            type="number"
                            name="notificationRequirements"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Notification requirements (hrs)'
                            label={(<strong> Notification requirements (hrs) </strong>)}
                        />
                    </Col>
                    <Col xs={12} className="offset-top-4">
                        <Field
                            name="currentVersion"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Current version'
                            label={(<strong> Current version </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={4}>
                        <Field
                            disabled={disabled}
                            name="dateEnacted"
                            component={MdDatePicker}
                            label={(<strong> Date enacted </strong>)}
                        />
                    </Col>
                    <Col xs={4}>
                        <Field
                            disabled={disabled}
                            name="dateEnforced"
                            component={MdDatePicker}
                            label={(<strong> Date Enforced </strong>)}
                        />
                    </Col>
                    <Col xs={4} className="offset-bottom-4">
                        <Field
                            disabled={disabled}
                            name="dateUpdated"
                            component={MdDatePicker}
                            label={(<strong> Date updated </strong>)}
                            // helpText={(<code> help  </code>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={6}>
                        <Field
                            labelKey="label"
                            valueKey="value"
                            component={MdSelect}
                            name="regulatoryType"
                            disabled={disabled}
                            label={(<strong> Regulatory Type </strong>)}
                            options={REGULATORY_TYPES}
                            simpleValue={(value) => {
                                return {value: value, label: REGULATORY_TYPES_MAP[value] ? REGULATORY_TYPES_MAP[value] : 'Please Select Type'}
                            }}
                            optionsLabel={({value}) => REGULATORY_TYPES_MAP[value]}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4 offset-top-4">
                    <Col xs={3}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="criminalPenalties"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Criminal Penalties </strong>)}
                        />
                    </Col>
                    <Col xs={12}>
                        <Field
                            name="criminalPenaltiesComment"
                            multiline={true}
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Criminal Penalties Comment'
                            label={(<strong> Criminal Penalties Comment </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4 offset-top-4">
                    <Col xs={3}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="civilPenalties"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Civil Penalties </strong>)}
                        />
                    </Col>
                    <Col xs={12}>
                        <Field
                            name="civilPenaltiesComment"
                            multiline={true}
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Civil Penalties Comment'
                            label={(<strong> Civil Penalties Comment </strong>)}
                        />
                    </Col>
                </Row>

            </TabPanel>

            <TabPanel value={2} index={currentTab}>

                <Row className="offset-bottom-4 offset-top-4">
                    <Col xs={4} className="offset-bottom-4 offset-top-4">
                        <Field
                            name="revenueRequirement"
                            type="number"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Revenue Requirement ($M)'
                            label={(<strong> Revenue Requirement ($M) </strong>)}
                        />
                    </Col>
                    <Col xs={12} className="offset-bottom-4 offset-top-2">
                        <strong>During a calendar year,</strong>
                    </Col>
                    <Col xs={6} className="offset-bottom-4">
                        <Field
                            name="personalDataProcessedNumber"
                            valueKey="value"
                            labelKey="label"
                            component={MdSelect}
                            disabled={disabled}
                            options={[10000, 25000, 50000, 75000, 100000]}
                            optionsLabel={({value}) => value}
                            simpleValue={value => {return {value, label: value}}}
                            label={(<strong> 1. Controls or processes personal data of </strong>)}
                            helpText={(<strong>or more consumers</strong>)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="offset-bottom-8">
                        <Field
                            name="personalDataGrossRevenuePercent"
                            valueKey="value"
                            labelKey="label"
                            component={MdSelect}
                            disabled={disabled}
                            options={[0, 10, 25, 50, 75, 100]}
                            optionsLabel={({value}) => value}
                            simpleValue={value => {return {value, label: value}}}
                            label={(<strong> 2. Derives over </strong>)}
                            helpText={(<strong>% of the entiy's gross revenue from the sale of personal data</strong>)}
                        />
                    </Col>
                    <Col xs={6} className="offset-bottom-8">
                        <Field
                            name="personalDataProcessedNumberForGrossRevenue"
                            valueKey="value"
                            labelKey="label"
                            component={MdSelect}
                            disabled={disabled}
                            options={[10000, 25000, 50000, 75000, 100000]}
                            optionsLabel={({value}) => value}
                            simpleValue={value => {return {value, label: value}}}
                            label={(<strong> and controls or processes personal data of </strong>)}
                            helpText={(<strong>or more consumers</strong>)}
                        />
                    </Col>
                </Row>

                {/*<Row className="offset-bottom-4 offset-top-4">*/}
                {/*    <Col xs={6}>*/}
                {/*        <Field*/}
                {/*            color="primary"*/}
                {/*            fullWidth={false}*/}
                {/*            name="exemptionForEmployee"*/}
                {/*            component={MdSwitch}*/}
                {/*            disabled={disabled}*/}
                {/*            label={(<strong> Exemption for employee data </strong>)}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*    <Col xs={6}>*/}
                {/*        <Field*/}
                {/*            color="primary"*/}
                {/*            fullWidth={false}*/}
                {/*            name="nonProfitsCovered"*/}
                {/*            component={MdSwitch}*/}
                {/*            disabled={disabled}*/}
                {/*            label={(<strong> Non-profits covered </strong>)}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row>
                    <Col xs={6} className="offset-bottom-4">
                        <Field
                            valueKey="value"
                            labelKey="label"
                            component={MdSelect}
                            name="entryLevelExemptions"
                            disabled={disabled}
                            label={(<strong> Entry Level Exemptions </strong>)}
                            options={Object.keys(REGULATORY_ENTRY_LEVEL_EXEMPTIONS_MAP)}
                            optionsLabel={({value}) => REGULATORY_ENTRY_LEVEL_EXEMPTIONS_MAP[value ? value : 'NONE']}
                            simpleValue={value => {return {value, label: REGULATORY_ENTRY_LEVEL_EXEMPTIONS_MAP[value ? value : 'NONE']}}}
                        />
                    </Col>

                    <Col xs={6} className="offset-bottom-4">
                        <Field
                            valueKey="value"
                            labelKey="label"
                            sx={{width: 'calc(100% - 50px)'}}
                            component={MdSelect}
                            name="dataSpecificExemptions"
                            disabled={disabled}
                            label={(<strong> Data Specific Exemptions </strong>)}
                            options={Object.keys(REGULATORY_DATA_SPECIFIC_EXEMPTIONS_MAP)}
                            optionsLabel={({value}) => REGULATORY_DATA_SPECIFIC_EXEMPTIONS_MAP[value ? value : 'NONE']}
                            simpleValue={value => {return {value, label: REGULATORY_DATA_SPECIFIC_EXEMPTIONS_MAP[value ? value : 'NONE']}}}
                        />

                        <DescriptionTooltip title={"- Protected Health Information subject to HIPAA, health records, patient identifying information for the purposes of 42 U.S.C. § 290dd–2, information and documents created for purposes of the Health Care Quality Improvement Act of 1986, patient safety work product for purposes of the Patient Safety and Quality Improvement Act of 2005, or identifiable private information used in connection with human clinical trials and research.\n\n" +
                            "- Information originating from, and \"intermingled to be indistinguishable with, or information treated in the same manner as, information exempt under this section\" that is maintained by a HIPAA-covered entity or HIPAA-defined business associate.\n\n" +
                            "- Personal information covered by and/or processed in accordance with the Fair Credit Reporting Act, Driver's Privacy Protection Act, Family Educational Rights and Privacy Act of 1974, the Farm Credit Act of 1971, and several others.\n\n" +
                            "- Personal data processed by a person in the course of a purely personal or household activity.\n\n" +
                            "- Emergency contact information."} />
                    </Col>

                    {/*<Col xs={6} className="offset-bottom-4">*/}
                    {/*    <SelectEntities*/}
                    {/*        // TODO apply sectoral laws selection*/}
                    {/*        isMulti*/}
                    {/*        disabled={disabled}*/}
                    {/*        name="sectoralLawsCarveout"*/}
                    {/*        type={ENTITY_TYPE.INDUSTRIES}*/}
                    {/*        placeholder='Select sectoral laws'*/}
                    {/*        label={(<strong> Sectoral laws carveout </strong>)}*/}
                    {/*        getOptionLabel={option => `${get(option, 'name', '')}`}*/}
                    {/*    />*/}
                    {/*</Col>*/}

                    <Col xs={6} className="offset-bottom-4">
                        <Field
                            isMulti
                            name="stateLevelPreemptions"
                            // defaultOptions={states}
                            component={MdAsyncSelect}
                            label={(<strong> State Level pre-emption </strong>)}
                            disabled={disabled}
                            loadOptions={(name, done) => {
                                instanceAPI({
                                    method: 'post',
                                    url: 'states/filter',
                                    data: { page: 0, size: 6, filter: { name, countryIds: [229] } },
                                }).then(({items})=>done(items)).catch(done.bind(null, []));
                            }}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12} className="offset-bottom-4">
                        <SelectEntities
                            isMulti
                            disabled={disabled}
                            name="industries"
                            type={ENTITY_TYPE.INDUSTRIES}
                            placeholder='Select industries'
                            label={(<strong> Industries </strong>)}
                            getOptionLabel={option => `${get(option, 'name', '')}`}
                        />
                    </Col>
                    <Col xs={12} className="offset-bottom-4">
                        <Field
                            isMulti
                            component={MdAsyncSelect}
                            disabled={disabled}
                            name="dataTypes"
                            placeholder='Select data types'
                            label={(<strong> Data types </strong>)}
                            loadOptions={(name, done) => {
                                instanceAPI({
                                    method: 'post',
                                    url: '/admin/data-type-classifications/filter',
                                    data: { page: 0, size: 10, filter: {name, globalOnly: true} },
                                }).then(({items}) => done(items)).catch(done.bind(null, []));
                            }}
                            getOptionLabel={option => {
                                let optionName = get(option, 'name', '');
                                let organizationInfo = get(option, 'organization', null);
                                if (organizationInfo && organizationInfo.name) {
                                    optionName += ` (${organizationInfo.name})`;
                                }

                                return optionName;
                            }}
                        />
                    </Col>
                    <Col xs={12} className="offset-bottom-4">
                        <SelectEntities
                            isMulti
                            disabled={disabled}
                            name="technologyCategories"
                            type={ENTITY_TYPE.TECHNOLOGY_CATEGORIES}
                            placeholder='Select technology types'
                            label={(<strong> Technology types </strong>)}
                            getOptionLabel={option => `${get(option, 'name', '')}`}
                        />
                    </Col>
                </Row>

                <LocationConnected/>

            </TabPanel>

            <TabPanel value={3} index={currentTab}>
                <Row className="offset-bottom-4 offset-top-4">
                    <Col xs={6}>
                        <Row>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="purposeLimitation"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Avoid Secondary Use/Purpose Limitation </strong>)}
                                />

                                <DescriptionTooltip title={"Limit the collection of personal data to what is adequate, relevant, and reasonably necessary in relation to disclosed purposes for which such data is processed. "} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="dataBreachNotification"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Data breach notification </strong>)}
                                />
                                <DescriptionTooltip title={"The state requires data breach notification and for processors are required to assist with c complying with data breach notification requirements."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="dataMinimization"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Data Minimization </strong>)}
                                />
                                <DescriptionTooltip title={"Data minimization is a fundamental principle that emphasizes collecting and processing the least amount of personal data necessary for a specific purpose. It's essentially doing more with less when it comes to user data."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="dataSecurityRequirements"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Data Security Requirements </strong>)}
                                />
                                <DescriptionTooltip title={"Controllers must adopt and implement reasonable administrative, technical and physical practices for data security, appropriate to the volume and nature of the data."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="dataProtectionOfficer"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Data Protection Officer </strong>)}
                                />
                                <DescriptionTooltip title={"A Data Protection Officer (DPO) is a role within an organization that ensures compliance with data protection laws and regulations, such as the General Data Protection Regulation (GDPR) in the European Union or the California Consumer Privacy Act (CCPA) in the United States. They act as a bridge between the organization and individuals whose data is collected and processed."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="exercisingConsumerRights"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Exercising Consumer Rights </strong>)}
                                />
                                <DescriptionTooltip title={"In privacy law, \"exercising consumer rights\" refers to individuals taking advantage of the legal protections granted to them regarding their personal data. These rights vary depending on the specific law and jurisdiction, but generally aim to provide control and transparency over how businesses collect, use, and share their information. Here are some key points: Right to Access: Consumers have the right to know what personal data a business holds about them, obtain a copy of it, and understand how it's being used. Right to rectification: Individuals can request businesses to correct any inaccurate or incomplete personal data they possess. Right to deletion: Under certain circumstances, consumers can request their data be deleted, also known as the \"right to be forgotten.\" Right to Object: Individuals can object to their data being used for certain purposes, such as marketing or profiling. Right to restrict processing: In some cases, consumers can request limitations on how their data is processed, such as restricting its use to specific purposes. Right to Data Portability: Consumers may have the right to receive their personal data in a machine-readable format and transfer it to another service provider. Right to Not Be Discriminated Against:: Under some laws, individuals cannot be discriminated against for exercising their privacy rights."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="finingAuthority"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Fining authority </strong>)}
                                />
                                <DescriptionTooltip title={"A fining authority refers to the entity empowered to levy financial penalties against organizations found to be in violation of the law. It's important to understand who has the power to fine and the potential consequences of non-compliance, as they act as an important deterrent against privacy violations."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="internationalDataTransferRestrictions"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> International data transfer restrictions </strong>)}
                                />
                                <DescriptionTooltip title={"International data transfers, the movement of personal data across borders, are increasingly scrutinized by privacy laws around the world. This is due to concerns about disparities in data protection standards and potential risks to individuals' privacy when their data leaves their home country."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="legalBasisOfProcessing"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Legal basis for processing </strong>)}
                                />
                                <DescriptionTooltip title={"The legal basis for processing refers to the justification an organization has for collecting, storing, and using someone's personal data. This is a crucial requirement that ensures data processing is done lawfully and protects individuals' privacy."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="noticesOfChangesToPrivacyPolicy"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Notices of Changes to Privacy Policy </strong>)}
                                />
                                <DescriptionTooltip title={"Notifications that companies send to users when they update their privacy policies."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="noticeTransparencyRequirements"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Privacy Notice </strong>)}
                                />
                                <DescriptionTooltip title={"Provide a clear privacy policy that includes the categories of personal data processed, the purpose for processing personal data, the categories of data shared with third parties, the types of third parties, the consumer's rights, and the manner in which consumers may exercise their rights, including an appeal.  "} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="privacyByDesign"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Privacy by Design </strong>)}
                                />
                                <DescriptionTooltip title={"Privacy by Design (PbD) is a fundamental principle in data privacy that emphasizes integrating privacy considerations into the development and design of systems, processes, and technologies. It's not just about adding privacy features later; it's about embedding privacy safeguards throughout the entire development lifecycle, from conception to implementation."} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Row>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="processingAgreementsRequiredBtwControllersAndServiceProviders"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Processing Agreement Required between Controllers and Service Providers </strong>)}
                                />
                                <DescriptionTooltip title={"Processing Agreement Required between Controllers and Service Providers"} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="privateRightOfAction"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Private Right of Action </strong>)}
                                />
                                <DescriptionTooltip title={"\"A right to private action\" refers to the ability of individuals to initiate legal action against organizations for violating their privacy rights. This means individuals can take the organization to court and potentially seek compensation for damages caused by the privacy violation."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="processorServiceProviderRequirements"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Processor/Service Provider Requirements </strong>)}
                                />
                                <DescriptionTooltip title={"Purpose and Limitation of Use: The contract should clearly specify the purpose for which the service provider can use the personal data and restrict them from using it for any other purpose. Security: The service provider must implement appropriate technical and organizational measures to protect the data from unauthorized access, disclosure, alteration, or destruction. Data Retention: The contract should specify how long the service provider can keep the data and when they must delete it. Subprocessors: If the service provider uses subcontractors to process the data, they must notify you and ensure they have contracts with those subcontractors that meet the same requirements. Data Subject Rights: The service provider may need to assist you in responding to data subject rights requests, such as access, rectification, or deletion. Reporting and Auditing: The service provider may be required to report data breaches or provide you with access to audit their security practices."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="protectionsForSensitivePI"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Protections for sensitive PI </strong>)}
                                />
                                <DescriptionTooltip title={"Usually includes: Enhanced consent requirements: Stricter and more explicit consent may be required before collecting or processing sensitive PI.. Prohibitions on certain processing activities: Some laws might prohibit sensitive PI processing for specific purposes, like targeted advertising or profiling. Stricter security measures: Stronger safeguards are often mandated to protect the confidentiality, integrity, and availability of sensitive PI. Data minimization: Organizations are encouraged to collect and process only the minimum amount of sensitive PI necessary for defined purposes. Data subject rights: Individuals may have additional rights regarding access, rectification, and deletion of their sensitive PI."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="prohibitionOnDiscrimination"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Prohibition on Discrimination </strong>)}
                                />
                                <DescriptionTooltip title={"Process consumer data in a non-discriminatory manner, and refrain from discriminating against consumers who exercise the rights granted by the statute. "} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="protectionAssessments"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Protection Assessments </strong>)}
                                />
                                <DescriptionTooltip title={"Conduct a data protection impact assessment on the processing of personal data for targeted advertising, the sale of personal data, profiling, sensitive data, and any processing activities that involve personal data that present a heightened risk of harm to consumers. "} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="recordsOfProcessing"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Records of Processing </strong>)}
                                />
                                <DescriptionTooltip title={"Records of processing, also known as Records of Processing Activities (RoPA), are a key element of privacy law in many jurisdictions, particularly the General Data Protection Regulation (GDPR) in Europe. They serve as an internal document maintained by organizations that control or process personal data, detailing various aspects of their data processing activities. As specified in Article 30 of the GDPR, RoPAs must contain a range of information for each processing activity, including: name and contact details of the controller (responsible entity) and processor (if applicable), the purposes of the processing (why data is collected and used), categories of data subjects (types of individuals whose data is processed), categories of personal data collected (types of information collected), recipients of the data (who the data is shared with)."} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="registrationWithAuthorities"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Registration with authorities </strong>)}
                                />
                                <DescriptionTooltip title={"The process where organizations notify or register their data processing activities with a designated government authority. "} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="ruleMakingAuthority"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Rule-making authority </strong>)}
                                />
                                <DescriptionTooltip title={"Does the legislative body or regulatory agency process rule making powers?"} />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="transparencyAndPurposeRequirements"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Transparency and Purpose Requirements </strong>)}
                                />
                                <DescriptionTooltip title={"Transparency and Purpose Requirements"} />
                            </Col>
                            {/*<Col xs={6}>*/}
                            {/*    <Field*/}
                            {/*        color="primary"*/}
                            {/*        fullWidth={false}*/}
                            {/*        name="transparencyAndPurposeSpecification"*/}
                            {/*        component={MdSwitch}*/}
                            {/*        disabled={disabled}*/}
                            {/*        label={(<strong> Transparency and Purpose Specification </strong>)}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                            <Col xs={12}>
                                <Field
                                    color="primary"
                                    fullWidth={false}
                                    name="universalOptOutMechanism"
                                    component={MdSwitch}
                                    disabled={disabled}
                                    label={(<strong> Universal Opt Out Mechanism </strong>)}
                                />
                                <DescriptionTooltip title={"In the context of privacy law, a Universal Opt-Out (UOOM) is a mechanism that allows consumers to express a single preference to opt out of certain data processing activities across a variety of online platforms and services. UOOMs offer a standardized and centralized way for users to control their privacy preferences regarding data use. By opting out through a UOOM, a consumer sends a signal to participating businesses that they do not want their personal data used for specific purposes, such as: targeted advertising, selling of personal data to third parties, certain types of data tracking and profiling."} />
                            </Col>
                        </Row>
                    </Col>

                    {/*<Col xs={6}>*/}
                    {/*    <Field*/}
                    {/*        color="primary"*/}
                    {/*        fullWidth={false}*/}
                    {/*        name="dataSecurity"*/}
                    {/*        component={MdSwitch}*/}
                    {/*        disabled={disabled}*/}
                    {/*        label={(<strong> Data Security </strong>)}*/}
                    {/*    />*/}
                    {/*</Col>*/}

                    {/*<Col xs={6}>*/}
                    {/*    <Field*/}
                    {/*        color="primary"*/}
                    {/*        fullWidth={false}*/}
                    {/*        name="protectionAssessments"*/}
                    {/*        component={MdSwitch}*/}
                    {/*        disabled={disabled}*/}
                    {/*        label={(<strong> Protection Assessments </strong>)}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                </Row>
            </TabPanel>

            <TabPanel value={4} index={currentTab}>
                <Row className="offset-top-4">
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="ageBasedOptInRight"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Age based opt in right </strong>)}
                            onClick={() => {
                                if (formValues && !formValues.ageBasedOptInRight) {
                                    if (!formValues.regulationAges || !formValues.regulationAges.length) {
                                        addRegulationAge({isNew: true, ageId: 0});
                                    }
                                }
                            }}
                        />

                        {formValues && formValues.ageBasedOptInRight ? (
                            <AddIconBtn disabled={disabled} onClick={() => addRegulationAge({isNew: true, ageId: 0})} />
                        ) : ''}
                    </Col>
                </Row>

                {formValues && formValues.ageBasedOptInRight ? (
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <FieldArray name={`regulationAges`} component={RegulationAges} />
                        </Col>
                    </Row>
                ) : ''}

                <Row className="offset-bottom-4 offset-top-4">
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightOfNotToBeSubjectedToFullyAutomatedDecisionMaking"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right of not to be subjected to fully automated decision making </strong>)}
                        />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToAccess"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Access </strong>)}
                        />
                        <DescriptionTooltip title={"Consumers have the right to access their personal data collected by an organization. This allows them to understand what information is being held about them and verify its accuracy. Access may include details like the categories of data collected, the sources of the data, the purposes for which it's used, and who it's shared with."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToAppeal"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Appeal </strong>)}
                        />
                        <DescriptionTooltip title={"The \"right to appeal\" in privacy laws refers to the ability of individuals to challenge decisions made by organizations regarding their personal data. This right exists in various privacy laws around the world, although the specific scope and procedures may differ.  If an organization denies your request to access your personal data, you may have the right to appeal their decision. I f an organization refuses to correct inaccurate data or delete it as requested, you may be able to appeal their decision. If you object to your data being used for certain purposes, and the organization rejects your objection, you could potentially appeal. In some cases, other decisions impacting your data subject rights, like restricting processing, might also be appealable."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToConsent"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Consent </strong>)}
                        />
                        <DescriptionTooltip title={"Individuals have the right to withdraw consent at any time, and consent must be freely given, specific, informed, and unambiguous."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToCorrect"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Correction </strong>)}
                        />
                        <DescriptionTooltip title={"Consumers have the right to request the correction or rectification of inaccurate or incomplete personal data. This ensures the information used about them is truthful and up-to-date."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToDataPortability"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Data Portability </strong>)}
                        />
                        <DescriptionTooltip title={"In some cases, consumers might have the right to request the portability of their personal data. This allows them to transfer their data to another organization in a machine-readable format. This gives them more control over their data and enables them to switch service providers more easily."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToDelete"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Deletion </strong>)}
                        />
                        <DescriptionTooltip title={"Consumers may have the right to request the deletion of their personal data in certain circumstances. This allows them to control the information held about them and potentially erase their digital footprint. However, deletion rights may be subject to exceptions, such as if the data is needed to comply with legal obligations or for public interest purposes. Transfers of data to other countries (if applicable). Retention periods (how long data is stored). Technical and organizational security measures (how data is protected)."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToNotBeDiscriminatedAgainst"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Not Be Discriminated Against </strong>)}
                        />
                        <DescriptionTooltip title={"individuals cannot be discriminated against for exercising their privacy rights."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToOptInOfAllSensitiveDataProcessing"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Opt In </strong>)}
                        />
                        <DescriptionTooltip title={"Individuals must affirmatively agree before their data can be used for those purposes."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToOptDownOfAllSpecificProcessing"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Opt Our of Sale </strong>)}
                        />
                        <DescriptionTooltip title={"The \"right to opt out of sale\" in privacy law empowers individuals to prohibit organizations from selling their personal information to third parties. "} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToPortability"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Portability </strong>)}
                        />
                        {/*<DescriptionTooltip title={""} />*/}
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToPrivateAction"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Private Action </strong>)}
                        />
                        <DescriptionTooltip title={"The \"right to private action\" in privacy law refers to the ability of individuals to directly sue organizations for violating their privacy rights and seek legal remedies, such as compensation or injunctive relief. This right exists in some, but not all, privacy laws around the world and can be a powerful tool for individuals to enforce their privacy protections."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToRefreshConsent"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Refreshing Consent </strong>)}
                        />
                        <DescriptionTooltip title={"This doesn't involve explicitly asking for consent again, but rather prompting individuals to review and potentially adjust their existing consent preferences. This can be achieved through: Providing periodic information about how data is being used and reminding individuals about their existing consent choices, offering user-friendly interfaces where individuals can review and adjust their consent settings for different purposes or data categories, continuously demonstrating clear data practices and respecting individuals' rights, fostering trust and encouraging them to engage with their consent preferences."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToReSeekConsent"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Re-seek Consent </strong>)}
                        />
                        <DescriptionTooltip title={"This essentially means asking for someone's consent again, even though they may have already consented previously. However, it's not simply a repeat request. Re-seeking consent is appropriate in specific situations: If the way an organization processes personal data changes significantly, they might need to re-seek consent, ensuring individuals understand the updated approach and can make informed choices, if a significant amount of time has passed since the initial consent (often exceeding two years), some laws might recommend or require re-seeking consent to refresh awareness and reaffirm the individual's decision. if new privacy laws introduce stricter consent requirements, organizations might need to re-seek consent under the updated legal framework."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToRestrictOfProcessing"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Restriction of Processing </strong>)}
                        />
                        <DescriptionTooltip title={"Consumers may have the right to object to or restrict the processing of their personal data for specific purposes. This allows them to limit how their data is used, for example, by opting out of targeted advertising"} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToRevokeConsent"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Revoke Consent </strong>)}
                        />
                        <DescriptionTooltip title={"The right to revoke consent, also known as the right to withdraw consent, is a fundamental principle in many privacy laws around the world. It empowers individuals to regain control over their personal data and limit how organizations can use it. It grants individuals the right to withdraw their previously given consent for processing their personal data at any time. This right applies to various data processing activities, including marketing, profiling, and personalized advertising. Laws usually require organizations to provide simple and easily accessible mechanisms for individuals to revoke consent. This could involve online forms, email requests, or even phone calls. Once revoked, the organization must stop processing the individual's data for the specific purpose to which they consented. However, it might not erase existing data already processed based on the initial consent."} />
                    </Col>
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="rightToObject"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Right to Object </strong>)}
                        />
                        <DescriptionTooltip title={"Individuals can object to their data being used for certain purposes, such as marketing or profiling."} />
                    </Col>

                    {/*<Col xs={6}>*/}
                    {/*    <Field*/}
                    {/*        color="primary"*/}
                    {/*        fullWidth={false}*/}
                    {/*        name="optOutOfSale"*/}
                    {/*        component={MdSwitch}*/}
                    {/*        disabled={disabled}*/}
                    {/*        label={(<strong> Opt out of Sale </strong>)}*/}
                    {/*    />*/}
                    {/*    <DescriptionTooltip title={""} />*/}
                    {/*</Col>*/}
                </Row>
            </TabPanel>

            <TabPanel value={5} index={currentTab}>
                <Row className="offset-bottom-4 offset-top-4">
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="respondingToRequests"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Responding to Requests </strong>)}
                        />
                    </Col>
                    <Col xs={6}>
                        <Field
                            valueKey="value"
                            labelKey="value"
                            component={MdSelect}
                            name="typeOfDisclosure"
                            disabled={disabled}
                            simpleValue={value => value && ({value})}
                            label={(<strong className={isNew ? 'required-asterisk' : ''}> Types of Disclosures </strong>)}
                            options={['Notice to Authority', 'Notice to Individuals']}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="disclosureSpecifications"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Disclosure Specifications/Factors'
                            label={(<strong> Disclosure Specifications/Factors </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={9}>
                        <Field
                            type="number"
                            name="disclosureTimeRequirements"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Disclosure Time Requirements'
                            label={(<strong> Disclosure Time Requirements </strong>)}
                        />
                    </Col>
                    <Col xs={3}>
                        <Field
                            valueKey="value"
                            labelKey="value"
                            component={MdSelect}
                            name="disclosureTimeUnit"
                            disabled={disabled}
                            simpleValue={value => value && ({value})}
                            label={(<strong> Time unit </strong>)}
                            options={['Hours', 'Days']}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="disclosureTypeRequirements"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Disclosure Type Requirements'
                            label={(<strong> Disclosure Type Requirements </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={6}>
                        <Field
                            type="number"
                            name="responseToConsumerRequests"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Response to Consumer Requests (# of days)'
                            label={(<strong> Response to Consumer Requests (# of days) </strong>)}
                        />
                    </Col>
                    <Col xs={6}>
                        <Field
                            type="number"
                            name="curePeriod"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Cure Period (# of days)'
                            label={(<strong> Cure Period (# of days) </strong>)}
                        />
                    </Col>
                </Row>
            </TabPanel>

            <TabPanel value={6} index={currentTab}>
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="aggregatedOrDeidetifiedDataDefinition"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Aggregated or Deidentified DataDefinition'
                            label={(<strong> Aggregated or Deidentified DataDefinition </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="aggregatedOrDeidetifiedDataUse"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Aggregated or Deidentified Data Use'
                            label={(<strong> Aggregated or Deidentified Data Use </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            isMulti
                            component={MdAsyncSelect}
                            disabled={disabled}
                            name="classifications"
                            placeholder='Classification'
                            label={(<strong> Classification </strong>)}
                            loadOptions={(name, done) => {
                                instanceAPI({
                                    method: 'post',
                                    url: '/admin/data-type-classifications/filter',
                                    data: { page: 0, size: 10, filter: {name, globalOnly: true} },
                                }).then(({items}) => done(items)).catch(done.bind(null, []));
                            }}
                            getOptionLabel={option => {
                                let optionName = get(option, 'name', '');
                                let organizationInfo = get(option, 'organization', null);
                                if (organizationInfo && organizationInfo.name) {
                                    optionName += ` (${organizationInfo.name})`;
                                }

                                return optionName;
                            }}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={9}>
                        <Field
                            name="dataRetention"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Data Retention'
                            label={(<strong> Data Retention </strong>)}
                        />
                    </Col>
                    <Col xs={3}>
                        <Field
                            valueKey="value"
                            labelKey="value"
                            component={MdSelect}
                            name="dataRetentionTimeUnit"
                            disabled={disabled}
                            simpleValue={value => value && ({value})}
                            label={(<strong> Time unit </strong>)}
                            options={['Months', 'Years']}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="purposeLimitations"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Purpose Limitations'
                            label={(<strong> Purpose Limitations </strong>)}
                        />
                    </Col>
                </Row>
            </TabPanel>

            <TabPanel value={7} index={currentTab}>
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="accessControl"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Access Control'
                            label={(<strong> Access Control </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="encryptionRequirements"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Encryption Requirements'
                            label={(<strong> Encryption Requirements </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="notificationRequirementsText"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Notification Requirements'
                            label={(<strong> Notification Requirements </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={6}>
                        <Field
                            valueKey="value"
                            labelKey="label"
                            component={MdSelect}
                            name="penTestCadence"
                            disabled={disabled}
                            label={(<strong> Pen Test Cadence </strong>)}
                            options={REGULATORY_TIME_UNITS}
                            optionsLabel={({value}) => REGULATORY_TIME_UNITS_MAP[value ? value : 'None']}
                            simpleValue={value => {return {value, label: REGULATORY_TIME_UNITS_MAP[value ? value : 'None']}}}
                        />
                    </Col>
                    <Col xs={6}>
                        <Field
                            valueKey="value"
                            labelKey="label"
                            component={MdSelect}
                            name="vulnerabilityScanCadence"
                            disabled={disabled}
                            label={(<strong> Vulnerability Scan Cadence </strong>)}
                            options={REGULATORY_TIME_UNITS}
                            simpleValue={value => {return {value, label: REGULATORY_TIME_UNITS_MAP[value ? value : 'None']}}}
                            optionsLabel={({value}) => REGULATORY_TIME_UNITS_MAP[value ? value : 'None']}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={6}>
                        <Field
                            valueKey="value"
                            labelKey="label"
                            component={MdSelect}
                            name="policiesAndProceduresReview"
                            disabled={disabled}
                            simpleValue={value => {return {value, label: REGULATORY_TIME_UNITS_MAP[value ? value : 'None']}}}
                            label={(<strong> Policies And Procedures Review </strong>)}
                            options={REGULATORY_TIME_UNITS}
                            optionsLabel={({value}) => REGULATORY_TIME_UNITS_MAP[value ? value : 'None']}
                        />
                    </Col>
                    <Col xs={6}>
                        <Field
                            valueKey="value"
                            labelKey="label"
                            component={MdSelect}
                            name="riskImpactAssessments"
                            disabled={disabled}
                            simpleValue={value => {return {value, label: REGULATORY_TIME_UNITS_MAP[value ? value : 'None']}}}
                            label={(<strong> Risk Impact Assessments </strong>)}
                            options={REGULATORY_TIME_UNITS}
                            optionsLabel={({value}) => REGULATORY_TIME_UNITS_MAP[value ? value : 'None']}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="detailsOfVendorProgram"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Details Of Vendor Program'
                            label={(<strong> Details Of Vendor Program </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            multiline={true}
                            name="vendorDueDiligenceRequirements"
                            component={MdInput}
                            disabled={disabled}
                            placeholder='Vendor Due Diligence Requirements'
                            label={(<strong> Vendor Due Diligence Requirements </strong>)}
                        />
                    </Col>
                </Row>

                <Row className="offset-bottom-4 offset-top-4">
                    <Col xs={6}>
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="mfa"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> MFA </strong>)}
                        />
                    </Col>
                </Row>
            </TabPanel>


            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"/>
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"/>
                    <CancelBtn onClick={cancel}/>
                </Col>
            </Row>
        </form>
    )
}));

const LocationConnected = connect(
    state => ({
        states: state.regulations.edit.states,
        values: state.form.editRegulation.values,
        disabled: state.regulations.edit.expectAnswer,
        countryIds: state.regulations.edit.countryIds,
    }),
    dispatch => ({
        setupStates: (countries) => dispatch({type: EDIT.GET_STATES, countries}),
    })
)(({disabled, values, countryIds, states, setupStates}) => (
    <Row>
        <Col xs={12} md={6} className="offset-bottom-4">
            <SelectEntities
                isMulti
                disabled={disabled}
                name="countries"
                type={ENTITY_TYPE.COUNTRIES}
                placeholder='Select countries'
                onChange={(e, countries) => setupStates(countries) }
                label={(<strong> Countries </strong>)}
                getOptionLabel={option => `${get(option, 'name', '')}`}
            />
        </Col>
        <Col xs={12} md={6} className="offset-bottom-4">
            <Field
                isMulti
                name="states"
                defaultOptions={states}
                component={MdAsyncSelect}
                label={(<strong> States </strong>)}
                disabled={disabled || !get(values, 'countries.length', null)}
                loadOptions={(name, done) => {
                    instanceAPI({
                        method: 'post',
                        url: 'states/filter',
                        data: { page: 0, size: 6, filter: { name, countryIds } },
                    }).then(({items})=>done(items)).catch(done.bind(null, []));
                }}
            />
        </Col>
    </Row>
));


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    // console.log("Tab Panel props: ", props);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <div className="offset-bottom-6 offset-top-6">{children}</div>
            )}
        </div>
    );
}

const RegulationAges = connect(
    state => ({ disabled: state.regulations.edit.expectAnswer }),
)(({fields, disabled}) => {
    return fields.map((mKey, i) => {
        let item = fields.get(i);
        return (
            <div key={i}>
                <div style={{position: 'relative', paddingRight: 50}}>
                    <Row>
                        <Col xs={3} md={3} mdOffset={0}>
                            <Field
                                skipTouch
                                autoFocus
                                type="number"
                                component={MdInput}
                                name={`${mKey}.age`}
                                disabled={disabled}
                                label={(<strong className={item.isNew ? 'required-asterisk' : ''}> Age #{i+1} </strong>)}
                            />
                        </Col>
                        <Col xs={9} md={9} mdOffset={0}>
                            <Field
                                skipTouch
                                autoFocus
                                component={MdInput}
                                name={`${mKey}.comments`}
                                disabled={disabled}
                                label={(<strong className={item.isNew ? 'required-asterisk' : ''}> Comment #{i+1} </strong>)}
                            />
                            <div style={{position: 'absolute', top: 5, right: 0}}>
                                <DeleteIconBtn disabled={disabled} onClick={() => fields.remove(i)}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    })
});

/**
 * Tooltip for Description
 *
 * @param {Object} props
 * @private
 */
export const DescriptionTooltip = withStyles(theme => ({ tooltip: { padding: 10, fontSize: 14, whiteSpace: 'pre-line', maxWidth: 500, backgroundColor: theme.palette.grey[800] } }))(
    ({classes, title=''}) => (
        <Tooltip title={title} classes={{ tooltip: classes.tooltip }} placement="right">
            <span>
                <IconButton color="primary" aria-label={"Description"} style={{padding: 6, marginTop: 6}}>
                    <HelpOutline fontSize="small" />
                </IconButton>
            </span>
        </Tooltip>
    )
);

const UploadFile = connect(
    state => ({
        uploaded: state.regulations.edit.uploaded,
        disabled: state.regulations.edit.expectAnswer
    }),
    dispatch => ({uploadFile: file => dispatch({type: EDIT.UPLOAD_FILE, file})})
)(({uploadFile, uploaded, disabled, ...attr}) => (
    <div className="text-center">
        {/*<InputLabel htmlFor="fileUpload"> <strong> Upload Laws Document </strong> </InputLabel>*/}
        <Dropzone
            {...attr}
            id="fileUpload"
            className="dropzone"
            useFsAccessApi={false}
            disabled={!uploaded || disabled}
            activeClassName="dropzone-active"
            disabledClassName="dropzone-disabled"
            accept={{
                'application/pdf': ['.pdf']
            }}
            onDrop={(acceptedFiles, rejectedFiles) => {
                if (!rejectedFiles.length) {
                    return uploadFile(acceptedFiles[0]);
                }
            }}
        >
            {({rejectedFiles, getRootProps, getInputProps}) => {
                if (!uploaded) {
                    return (<h3 className="text-center text-muted">Loading</h3>);
                }
                return (<div {...getRootProps()} className="text-center text-muted">
                    <input {...getInputProps()} />
                    <p style={{fontSize: '50px'}}><i className="fa fa-upload"/></p>
                    <h4 className="offset-top-0">Click here or drop files to upload!</h4>
                </div>);
            }}
        </Dropzone>
    </div>
));


const DownloadLink = withDownloadLink()(props => (
    <Tooltip title={"Download"} className="offset-bottom-2"><span>
        <IconButton {...props} color="primary" style={{padding: '5px'}} aria-label={"Download"}>
            <CloudDownload fontSize="small"/>
        </IconButton>
    </span></Tooltip>
));
