
// outsource dependencies
// import is from '../services/is.service';

// local dependencies
import BaseModel from './base.model';
import { instanceAPI } from '../services/api.service';
import {NEW_ID} from "../constants/spec";

/**
 *
 *
 * @constructor OrganizationModel
 * @type {OrganizationModel}
 * @public
 */
class OrganizationModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {

    }

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @override BaseModel
     * @private
     */
    static get baseRoute () {
        return 'admin/organizations';
    }

    /**
     * get self organization
     *
     * @override BaseModel
     * @public
     */
    static getSelf () {
        return new Promise((resolve, reject) => {
            instanceAPI({ method: 'get', url: '/organizations/self' })
                .then(user => resolve( OrganizationModel.create(user)) )
                .catch(reject);
        });
    }

    /**
     * create/update method
     * NOTE solution on server side to use one method to create and update entities
     *
     * @param {Number|String} [id=NEW_ID]
     * @param {Object} data
     * @public
     */
    static synchronizeWithZoomInfo ( id = NEW_ID, data ) {
        let apiUrl = '/integrations/zoominfo/organizations/apply/' + id;
        return new Promise((resolve,  reject) => {
            // NOTE id is important things to known is exist item on server side
            instanceAPI({
                data,
                url: apiUrl,
                method: 'put'
            }).then(success => {
                resolve(OrganizationModel.create(success));
            }).catch(reject);
        });
    }

}

export default OrganizationModel;
