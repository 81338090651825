// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@material-ui/core';
import {Col, Row} from 'react-bootstrap';

// local dependencies
import {ACTIONS} from '../actions';
import {DOWNLOAD_TYPES} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import UploadFile from '../../../components/data-import';
import {PrimaryBtn} from '../../../components/md-button';
import ErrorMessage from '../../../components/alert-error';
import withDownloadLink from '../../../components/download-link';

class Import extends Component {
    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {disabled, message, clearError, uploadFile, data} = this.props;
        return (
            <Row className="offset-top-10">
                <Col xs={12} md={10} mdOffset={1} lg={6} lgOffset={3}>
                    <Paper className="indent-8">
                        <Row>
                            <Col xs={12} className="offset-bottom-4">
                                <h2 className="text-center text-uppercase">
                                    Import and export News &nbsp; <Preloader expectAnswer={disabled} type="ICON"/>
                                </h2>
                            </Col>
                        </Row>
                        <ErrorMessage active message={message} onChange={clearError}/>
                        <Row className="text-center offset-bottom-5"> <Col xs={12}>
                            <DownloadLink disabled={disabled}/>
                        </Col> </Row>
                        <Row>
                            <Col xs={12}>
                                <UploadFile data={data} loading={disabled} uploadFile={uploadFile}/>
                            </Col>
                        </Row>
                    </Paper>
                </Col>
            </Row>)
    }
}

export default connect(
    state => ({
        data: state.importNews.loading.data,
        message: state.importNews.loading.errorMessage,
        disabled: state.importNews.loading.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: ACTIONS.CLEAR}),
        uploadFile: file => dispatch({type: ACTIONS.UPLOAD_FILE, file}),
        clearError: () => dispatch({type: ACTIONS.META, errorMessage: null}),
    })
)(Import)

const DownloadLink = withDownloadLink({downloadType: DOWNLOAD_TYPES.ADMIN_NEWS.CSV_LIST})(props => (
    <PrimaryBtn tooltip="Download Data" {...props}>
        <i className="fa fa-download" style={{fontSize: 20}} aria-hidden="true"/>&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true"/>
    </PrimaryBtn>
));
