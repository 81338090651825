
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { takeEvery, put, call } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../types';
import { history } from '../../../store';
import { AgreementModel } from '../../../models';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';

function * initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let result = yield call(getData, id);
        yield put({type: EDIT.DATA, ...result});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, ...params});
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false, initialized: true});
}

function * updateDataSaga ( {type, id, ...data} ) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let result = yield call(updateData, id, data);
        yield put({type: EDIT.DATA, ...result});
        if ( id === NEW_ID ) {
            // NOTE update url take id from results
            yield call(history.push, ROUTES.AGREEMENTS.LINK_EDIT(result));
        }
        yield call(toastr.success, 'Agreement', 'Data was successfully updated.');
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

/**
 * connect all public sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
}

/**
 *
 * @public
 */
function getData ( id ) {
    return id === NEW_ID ? AgreementModel.create({id: NEW_ID}) : AgreementModel.getById(id);
}
/**
 *
 * @param {String|Number} id
 * @param {Object} data
 * @public
 */
function updateData ( id, data = {} ) {
    // NOTE "spread" break the model instance
    return AgreementModel.partiallyUpdate(id, data);
}


/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
