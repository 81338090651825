
// outsource dependencies

// local dependencies
import { LOAD_DEMO_MODAL } from "./actions";


const demosList = [
    {id: 0, label:"Load Demo Business Units", name: "loadDemoBusinessUnits", },
    {id: 1, label:"Load Demo Subsidiaries", name: "loadDemoSubsidiaries",},
    {id: 2, label:"Load Demo Systems", name: "loadDemoSystems", },
    {id: 3, label:"Load Demo Users", name: "loadDemoUsers", },
    {id: 4, label:"Load Demo Vendors", name: "loadDemoVendors", },
    {id: 5, label:"Load Demo GDPR Articles", name: "loadDemoGDPRArticles", },
    {id: 9, label:"Load Demo Technologies", name: "loadDemoTechnologies", },
    {id: 101, label:"Load Demo Quant Metrics", name: "loadDemoQuantMetrics", },

    {id: 801, label:"Load Demo Scoring Questions - Amplified", name: "loadDemoScoringQuestionsAmplified", },
    {id: 802, label:"Load Demo Scoring Questions - Confidentiality", name: "loadDemoScoringQuestionsConfidentiality", },
    {id: 803, label:"Load Demo Scoring Questions - FFIEC CAT Inherent Risk", name: "loadDemoScoringQuestionsFFIECInherent", },
    {id: 804, label:"Load Demo Scoring Questions - FFIEC CAT Cyber Org Maturity Level", name: "loadDemoScoringQuestionsFFIECOrgMaturity", },
    {id: 805, label:"Load Demo Scoring Questions - Impact System", name: "loadDemoScoringQuestionsImpactSystem", },
    {id: 806, label:"Load Demo Scoring Questions - Impact Vendor", name: "loadDemoScoringQuestionsImpactVendor", },
    {id: 807, label:"Load Demo Scoring Questions - Impact GDPR", name: "loadDemoScoringQuestionsImpactGDPR", },
    {id: 808, label:"Load Demo Scoring Questions - Integrity", name: "loadDemoScoringQuestionsIntegrity", },
    {id: 809, label:"Load Demo Scoring Questions - Likelihood System", name: "loadDemoScoringQuestionsLikelihoodSystem", },
    {id: 810, label:"Load Demo Scoring Questions - Likelihood Vendor", name: "loadDemoScoringQuestionsLikelihoodVendor", },
    {id: 811, label:"Load Demo Scoring Questions - Likelihood GDPR", name: "loadDemoScoringQuestionsLikelihoodGDPR", },
    {id: 812, label:"Load Demo Scoring Questions - Maturity", name: "loadDemoScoringQuestionsMaturity", },

    /*
    {id: 6, label:"Load Demo Qualitative Questions Core", name: "loadDemoQualitativeQuestionsCore", },
    {id: 7, label:"Load Demo Qualitative Questions GDPR", name: "loadDemoQualitativeQuestionsGDPR", },
    {id: 8, label:"Load Demo Qualitative Questions Vendor Internal", name: "loadDemoQualitativeQuestionsVendorInternal", },
    {id: 9, label:"Load Demo Cyber Security Maturity", name: "loadDemoCyberSecurityMaturity", },
    */

    {id: 10, label:"Load Demo Security Requirements", name: "loadDemoSecurityRequirements", },
    {id: 11, label:"Load Test Framework PCI_DSS", name: "loadDemoFramework_PCI_DSS", },
    {id: 12, label:"Load Test Framework NIST_SP800", name: "loadDemoFramework_NIST_SP800", },
    {id: 13, label:"Load Test Framework NIST_CSF", name: "loadDemoFramework_NIST_CSF", },
    {id: 14, label:"Load Test Framework ISO_IEC_27001_2005", name: "loadDemoFramework_ISO_IEC_27001_2005", },
    {id: 15, label:"Load Test Framework ISO_IEC_27001_2013", name: "loadDemoFramework_ISO_IEC_27001_2013", }
];

let initial = {
    list: {demos: demosList},
    availableDemosList: demosList,
    organization: {},
    meta: {
        showNew: false,
        showModal: false,
        initialized: false,
        errorMessage: null,
        expectAnswer: false,
        isAllSelected: true,
    }
};

export default function ( state = initial, action ) {
    // eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case LOAD_DEMO_MODAL.CLEAR:
            state = initial;
            break;
        case LOAD_DEMO_MODAL.META:
            state = { ...state, meta: {...state.meta, ...options} };
            break;
        case LOAD_DEMO_MODAL.LIST:
            state = { ...state, ...options };
            break;
        case LOAD_DEMO_MODAL.ORGANIZATION:
            state = { ...state, ...options };
            break;
    }
    // /@models-modal/.test(type)&&
    // console.log(`%c LOAD_DEMO_MODAL LIST ${type} `, 'color: #fff; background: #232323; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n options:', options
    // );
    return state;
}

/**
 * description API entity "RiskModel"
 * @typedef {Object} RiskModel
 * @property {Number} id
 * @property {String} name
 * @property {String} description
 * addition client side fields
 * @property {Boolean} expanded
 * @property {String} formName - to get an access to the redux form data for each entity separated
 */

