
// outsource dependencies

// local dependencies

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        CHANGE_PASSWORD: `${prefix}CHANGE_PASSWORD`
    }
})('@change-password/');
