
// outsource dependencies

// local dependencies
import signIn from './sign-in/reducer';
import forgotPassword from './forgot-password/reducer';
import changePasswordPublic from './change-password/reducer';
import emailConfirmation from './email-confirmation/reducer';

export default {
    signIn,
    forgotPassword,
    emailConfirmation,
    changePasswordPublic,
};
