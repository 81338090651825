
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// local dependencies
import config from '../constants';
import { Humanize } from './filter';
import { getModelByType } from '../models';
import { MdAsyncSelect } from './md-select';
import { ENTITY_TYPE } from '../constants/spec';

// configure
SelectEntities.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.PropTypes.oneOf(Object.keys(ENTITY_TYPE).map(name=>ENTITY_TYPE[name])).isRequired,
    selected: PropTypes.array,
};

/**
 *
 * @param { Object } props
 * @public
 */
export function SelectEntities ({type, name, selected = [], ...attr}) {
    return (
        <Field
            name={name}
            cacheOptions
            skipTouch={true}
            component={MdAsyncSelect}
            label={(<Humanize tag="strong" text={type} />)}
            { ...attr }
            loadOptions={(name, done) => {
                let excludes = [];
                selected.map(i => i&&excludes.push(i));
                config.DEBUG&&
                console.log(`%c Select entity ${type} `, 'color: #eea236; background: #232323; font-size: 14px;'
                    ,'\n selected:', selected
                    ,'\n excludes:', excludes
                    ,'\n name:', name
                );
                getModelByType(type).getListByName(name, 10, excludes)
                    .then(done).catch(()=> done([]));
            }}/>
    );
}

export default SelectEntities;
