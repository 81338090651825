
// outsource dependencies
import 's-is';

// take s-is from window
let is = window['is']; // window.is;
// named export
export {is};
//
export default is;
