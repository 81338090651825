
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Delete, CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, TableSortLabel } from '@material-ui/core';

// local dependencies
import { LIST } from '../types';
import { history } from '../../../store';
import Page from '../../../components/page';
import Alert from '../../../components/alert-error';
import { LANGUAGES } from '../../../constants/routes';
import { Humanize } from '../../../components/filter';
import SearchFilter  from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import Preloader, { TYPE } from '../../../components/preloader';
import { Link, EditBtn, DangerBtn, DictionaryBtn } from '../../../components/md-button';
import {LANGUAGES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

// configure
export const allowedSort = ['name', 'code', 'locale', 'isPublic'];

/**
 * Table
 *
 * @private
 */
const ConnectedTable = connect(
    state => ({
        list: state.languages.list.list,
        page: state.languages.list.page,
        size: state.languages.list.size,
        totalPages: state.languages.list.totalPages,
        disabled: state.languages.list.expectAnswer,
    }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        changePage: page => dispatch({type: LIST.UPDATE_LIST, page}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
        changeSize: size => dispatch({type: LIST.UPDATE_LIST, size, page: 0}),
    })
)(({ list, disabled, page, size, totalPages, changePage, changeSize }) => (<Paper>
    <div style={{overflowX: 'auto'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <SortableCell name="name" />
                    <SortableCell name="code" />
                    <SortableCell name="locale" />
                    <SortableCell name="isPublic" />
                    <TableCell align="right" />
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (<TableRow key={index}>
                    <TableCell component="th" scope="row"> {item.name} </TableCell>
                    <TableCell component="th" scope="row"> {item.code} </TableCell>
                    <TableCell component="th" scope="row"> {item.locale} </TableCell>
                    <TableCell component="th" scope="row"> { item.isPublic ? (<CheckCircleOutline color="primary" />) : (<HighlightOff color="error" />) } </TableCell>
                    <TableCell component="th" scope="row" className="text-nowrap" align="right">
                        <Link
                            Btn={EditBtn}
                            disabled={disabled}
                            className="offset-right-2"
                            to={LANGUAGES.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                                />
                        <Link
                            Btn={DictionaryBtn}
                            style={{color: 'white'}}
                            disabled={disabled}
                            tooltip="Dictionary"
                            to={LANGUAGES.LINK_DICTIONARY({langId: item.id, query: {back: history.location.pathname + history.location.search} })}
                        />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={disabled}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</Paper>));

/**
 * Wrapper for sorting TH
 *
 * @private
 */
const SortableCell = connect(
    state => ({
        disabled: state.languages.list.expectAnswer,
        sortF: state.languages.list.sortF,
        sortD: state.languages.list.sortD,
    }),
    dispatch => ({ changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}) })
)(({disabled, sortF, sortD, name, changeSort, ...attr})=>(
    <TableCell className="th" {...attr}>
        <Tooltip
            enterDelay={300}
            placement="top-start"
            title={(<span>Sort by "<Humanize tag="strong" text={name} />"</span>)}
                >
            <span>
                <TableSortLabel
                    disabled={disabled}
                    active={sortF===name}
                    direction={sortD?'asc':'desc'}
                    onClick={()=>changeSort(name)}
                        >
                    <Humanize tag="strong" text={name} />
                </TableSortLabel>
            </span>
        </Tooltip>
    </TableCell>
));

/**
 *
 * @private
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.languages.list.filter,
        disabled: state.languages.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_LIST, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

/**
 * Table wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.languages.list }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
        deleteAllTranslation: () => toastr.confirm(<ConfirmDeleting />, { onOk: () => dispatch({type: LIST.DELETE_ALL_TRANSLATION}) }),
    })
)(({ list, init, initialized, expectAnswer, errorMessage, clearError, deleteAllTranslation }) => (
    <Page init={init} initialized={initialized}>
        <Row className="offset-bottom-4">
            <Col xs={12} md={6}> <h2 className="text-uppercase offset-bottom-1">
                Languages&nbsp;<Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
            </h2>
                <Breadcrumbs breadCrumbsMap={ LANGUAGES_MAP }  />
            </Col>
            <Col xs={12} md={6} className="text-right top-indent-3">
                <DangerBtn disabled={expectAnswer} onClick={deleteAllTranslation} tooltip="Delete all translation">
                    <Delete /> Delete all translation
                </DangerBtn>
            </Col>
        </Row>
        <Row> <Col xs={12} md={8} mdOffset={2}> <Alert active message={errorMessage} onChange={clearError} /> </Col> </Row>
        <Row> <Col xs={12}>
            <div style={{width: '300px', marginBottom: '10px'}}> <SearchFilterConnected /> </div>
        </Col> </Row>
        <Row> <Col xs={12}>
            {list.length ? (<ConnectedTable />) : (<h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>)}
        </Col> </Row>
    </Page>
));

/**
 * prepared confirmation for translation deleting
 *
 * @public
 */
const ConfirmDeleting = () => (<div>
    <h3> Confirm deleting </h3>
    <p> Are you sure you want to delete all translation for all languages? </p>
</div>);
