
// outsource dependencies
import get from 'lodash/get';

// local dependencies
import BaseModel from './base.model';

/**
 *
 *
 * @constructor BusinessUnitModel
 * @type {BusinessUnitModel}
 * @public
 */
class BusinessUnitModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    get fullLabel() {
        let label = this.name;
        let parent = this.parent, parentNames=[];
        while ( parent ) {
            parentNames.push(get(parent, 'name', ''));
            parent = get(parent, 'parent', null);
        }
        if ( parentNames.length ) {
            parentNames = parentNames.join('/');
            label = label + '/' + parentNames;
        }
        return label;
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {}

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @override BaseModel
     * @private
     */
    static get baseRoute () {
        return '/admin/business-units';
    }

    /**
     * format business unit label including its parents name
     *
     * @public
     */
    static formatLabel (data) {
        data = data || {};
        let label = data.name;
        let parent = get(data, 'parent'), parentNames=[];
        while ( parent ) {
            parentNames.push(get(parent, 'name', ''));
            parent = get(parent, 'parent', null);
        }
        if ( parentNames.length ) {
            parentNames = parentNames.join('/');
            label = label + '/' + parentNames;
        }
        return label;
    }

}

export default BusinessUnitModel;
