// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@material-ui/core';
import {reduxForm} from 'redux-form';
import {Grid, Row, Col} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import { NEW_ID} from '../../../constants/spec';
import {CancelBtn} from '../../../components/md-button';
import {CACHE_METRICS_DATA_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import moment from "moment/moment";
import {config} from "../../../constants";


class Edit extends Component {
    componentDidMount() {
        this.props.initialize(this.props.match.params.id, this.props.match.params.q);
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, match} = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        Cache metric data details
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"/>
                                </h2>
                                <Breadcrumbs breadCrumbsMap={ CACHE_METRICS_DATA_MAP }  />
                                <ConnectedError/>
                                <ConnectedForm isNew={isNew}/>
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.cacheMetricsData.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.cacheMetricsData.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.cacheMetricsData.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        data: state.cacheMetricsData.edit.data,
        disabled: state.cacheMetricsData.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'viewCacheMetricsData',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }
        return errors;
    }
})(({handleSubmit, data, update, cancel}) => (
    <form autoComplete="off" name="viewCacheMetricsData">
        <Row><Col xs={12} style={{ textAlign: 'center', backgroundColor: '#cdffd8' }}>
            <h4><strong>Organization:</strong> {data.organizationName || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Risk model:</strong> {data.riskModelName || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Date of creation:</strong> {data.createdAt && moment(data.createdAt).format(config.clientTimeFormat)}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Business Unit: </strong> {data.businessUnitName || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Data Asset Classification:</strong> {data.dataAssetClassificationName || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Data Class:</strong> {data.dataClassNames || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12} style={{ textAlign: 'center', backgroundColor: '#cdffd8' }}>
            <h4><strong>System:</strong> {data.systemName || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Number of records:</strong> {data.systemNumberOfRecords || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12} style={{ textAlign: 'center', backgroundColor: '#cdffd8' }}>
            <h4><strong>Vendor:</strong> {data.vendorName || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Is cloud vendor:</strong> {data.isCloudVendor || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Is service vendor:</strong> {data.isServiceVendor || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Is system vendor:</strong> {data.isSystemVendor || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Is technology vendor:</strong> {data.isTechnologyVendor || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12} style={{ textAlign: 'center', backgroundColor: '#cdffd8' }}>
            <h4><strong>Metric:</strong> {data.metricName || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Metric Domain:</strong> {data.metricDomainName || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Metric formula:</strong> {data.metricFormula || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Metric level:</strong> {data.metricLevel || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Metric type:</strong> {data.metricType || '-'}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Metric value:</strong> {data.metricValue || '-'}</h4>
        </Col></Row>
        <Row>
            <Col xs={12} className="text-right">
                <CancelBtn onClick={cancel}/>
            </Col>
        </Row>
    </form>
)));