// outsource dependencies
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import {get} from "lodash";
import {changeField} from "./index";
import {toastr} from "react-redux-toastr";

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.GET_STATES, getStatesSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPLOAD_FILE, uploadFileSaga);
    // yield takeEvery(EDIT.ADD_AGE_ID, addIgeIdSaga);
    // yield takeEvery(EDIT.REMOVE_AGE_ID, removeAgeIdSaga);
}

function* initializeSaga({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        // NOTE: initialize lists of states for current countries
        let countries = yield get(data, 'countries', null);
        if (countries) yield put({type: EDIT.GET_STATES, countries});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* getStatesSaga({countries = []}) {
    yield put({type: EDIT.META, expectAnswer: true, states: []});
    try {
        // NOTE get list of states for chosen country
        let countryIds = countries.map(country => country.id);
        if(countryIds.length){
            let states = yield call(getStates, countryIds);
            yield put({type: EDIT.META, states, countryIds});
        }
        yield put({type: EDIT.META, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga() {
    let {back} = yield select(state => state.regulations.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get regulation by id
 * @param {Number|String} id
 * @private
 */
function getData(id) {
    return new Promise((resolve, reject) => {
        if (id === NEW_ID) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/regulations/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update regulation
 * @param {Object} data
 * @private
 */
function updateData(data) {
    return new Promise((resolve, reject) => {
        let promise;
        if (!data.id || data.id === NEW_ID) { // CREATE
            promise = instanceAPI({method: 'post', url: `/regulations`, data});
        } else { // UPDATE
            promise = instanceAPI({method: 'put', url: `/regulations`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}

/**
 * get list of states for current country
 * @param {Array} countryIds
 * @private
 */
function getStates(countryIds) {
    return new Promise((resolve, reject) => {
        instanceAPI({method: 'post', url: `/states/filter`, data: {page: 0, size: 6, filter: {countryIds}}})
            .then(({items}) => resolve(items)).catch(reject)
    });
}

function* uploadFileSaga ({file}) {
    yield put({type: EDIT.META, expectAnswer: true, uploaded: false, errorMessage: null});
    try {
        let document = yield call(uploadFile, file);
        yield call(toastr.success, "Regulations", "Successfully uploaded Laws document");
        yield put(changeField('document', document));
        yield put({type: EDIT.META, expectAnswer: false, uploaded: true});
    } catch ({message}) {
        yield call(toastr.error, "Error", message);
        // NOTE clear input value
        yield put({type: EDIT.META, expectAnswer: false, uploaded: true, errorMessage: message});
    }
}

/**
 * upload file
 * @param {File} file
 * @private
 */
function uploadFile ( file ) {
    const data = new FormData();
    data.append('file', file);
    return instanceAPI({method: 'post', url: '/documents/upload/REGULATION_DOCUMENT', data});
}

/*
function* addIgeIdSaga ({igeId}) {
    yield put({type: EDIT.META, expectAnswer: true });
    let { availableIgeIds } = yield select(state => state.users.edit);
    // NOTE excluded added idp ids from available list
    availableIgeIds = availableIgeIds.filter(i => i !== igeId);
    // NOTE add item to form values with first empty userIdentity field
    yield put(arrayPush(FORM_NAME, 'regulationAges', {igeId, regulationAges: [{igeId, isNew: true}]}));
    yield put({type: EDIT.META, availableIgeIds, expectAnswer: false});
}

function* removeAgeIdSaga ({ageId, index}) {
    yield put({type: EDIT.META, expectAnswer: true });
    let { availableAgeIds } = yield select(state => state.regulations.edit);
    // NOTE added age id to available list
    availableAgeIds = [...availableAgeIds, ageId];
    // NOTE remove item from form values
    yield put(arrayRemove(FORM_NAME, 'regulationAges', index));
    yield put({type: EDIT.META, availableAgeIds, expectAnswer: false});
}
*/
