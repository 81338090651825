
// local dependencies

export const PERMISSIONS = (prefix => {
    return {
        PREFIX: new RegExp(prefix, 'i'),
        // simple action
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex actions
        INITIALIZE: `${prefix}INITIALIZE`,
        UPDATE_MAP: `${prefix}UPDATE_MAP`,
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        CHANGE_PERMISSION: `${prefix}CHANGE_PERMISSION`,
    };
})('@permissions/');
