
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { takeEvery, put, call, take, select } from 'redux-saga/effects';

// local dependencies
import { LIST } from '../types';
import { initial } from './reducer';
import { allowedSort } from './index';
import { UserModel } from '../../../models';
import is from '../../../services/is.service';
import { USERS } from '../../../constants/routes';
import query from '../../../services/query.service';
import store, { history, historyPush } from '../../../store';
import BusinessUnitModel from '../../../models/business-unit.model';

function * initializeSaga () {
    yield put({type: LIST.CLEAR});
    // NOTE take data from location and setup verified params
    const params = yield call(getQueryParams, query.parse(history.location.search));
    // NOTE update list
    yield put({type: LIST.UPDATE_LIST, ...params});
    yield take(LIST.GET_DATA.FINISH);
    // NOTE show advanced search depends on filtering params
    let showAdvanced = Boolean(params.email || params.firstName || params.lastName || params.role || params.organization || params.businessUnit || params.isDeleted);
    // NOTE initialized
    yield put({type: LIST.META, initialized: true, showAdvanced});
}

function * getDataSaga () {
    try {
        let reducer = yield select(state=> state.users.list);
        let result = yield call(getList, reducer);
        yield put({type: LIST.DATA, list: result.items});
        yield put({type: LIST.META, totalPages: result.pages});
        // NOTE update location
        yield call(updateLocation, reducer);
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_DATA.FINISH});
}

function * updateListSaga ({type, ...options}) {
    // NOTE apply options, remove error and enable preloader
    yield put({type: LIST.META, ...options, expectAnswer: true, errorMessage: null });
    // NOTE send request
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    // NOTE preloader off
    yield put({type: LIST.META, expectAnswer: false });
}

function * changeSortSaga ({type, field}) {
    let params = {sortF: field, sortD: true};
    let { sortF, sortD } = yield select(state=> state.users.list);
    // NOTE toggle sort direction for same field
    if ( field === sortF ) {
        params.sortD = !sortD;
    }
    // NOTE update list
    yield put({type: LIST.UPDATE_LIST, ...params});
}

function* cancelAdvancedSearchSaga () {
    // NOTE clear filtering data
    yield put({type: LIST.UPDATE_LIST, email: '', firstName: '', lastName: '', role: null, organization: null, businessUnit: null, page: 0});
    // NOTE close search panel
    yield put({type: LIST.META, showAdvanced: false});
}

function* deleteItemSaga ({type, ...options}) {
    yield put({type: LIST.META, expectAnswer: true});
    try {
        yield call( deleteItem, options );
        //NOTE update list
        yield put({type: LIST.UPDATE_LIST});
        yield put({type: LIST.META, expectAnswer: false});
        yield call(toastr.success, 'User', 'User has been deleted.');
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: LIST.META, errorMessage: message, expectAnswer: false});
    }
}

/**
 * connect all public sagas
 *
 * @public
 */
export default function * () {
    yield takeEvery(LIST.INITIALIZE, initializeSaga);
    yield takeEvery(LIST.UPDATE_LIST, updateListSaga);
    yield takeEvery(LIST.CHANGE_SORT, changeSortSaga);
    yield takeEvery(LIST.DELETE_ITEM, deleteItemSaga);
    yield takeEvery(LIST.GET_DATA.REQUEST, getDataSaga);
    yield takeEvery(LIST.CANCEL_ADVANCED_SEARCH, cancelAdvancedSearchSaga);

    // NOTE setup listener on location change to listen history back event (POP)
    yield call(history.listen, historyBackListen);
}

function historyBackListen ({pathname}, action) {
    if ( action !== 'POP' || pathname !== USERS.LIST ) return;
    // NOTE reinitialize search from query string after the page path was changed
    // NOTE this event will fired before the url was changed
    setTimeout(()=>store.dispatch({type: LIST.INITIALIZE}), 0);
}

/**
 * wrapper to get list
 *
 * @param {Object} reducer
 * @public
 */
function getList ({ page, size, sortD, sortF, filter, email, role, firstName, lastName, organization, businessUnit, isDeleted }) {
    return UserModel.getPage({
        page,
        size,
        sort: { field: sortF, order: sortD ? 'ASC' : 'DESC' },
        filter: {
            name: filter,
            email,
            firstName,
            lastName,
            isDeleted,
            roleId: role ? role.id : null,
            organizationId: organization ? organization.id : null,
            businessUnitId: businessUnit ? businessUnit.id : null
        },
    });
}

/**
 * delete user
 * @param {Object} data
 * @private
 */
function deleteItem(data) {
    return UserModel.remove(data);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ( query ) {
    return {
        page: is.countable(query.p) ? Number(query.p) : initial.page,
        size: is.countable(query.s) ? Number(query.s) : initial.size,
        filter: is.defined(query.f) ? String(query.f) : initial.filter,
        email: is.defined(query.em) ? String(query.em) : initial.email,
        lastName: is.defined(query.ln) ? String(query.ln) : initial.lastName,
        firstName: is.defined(query.fn) ? String(query.fn) : initial.firstName,
        sortD: is.countable(query.sd) ? Boolean(Number(query.sd)) : initial.sortD,
        isDeleted: is.countable(query.isDel) ? Boolean(Number(query.isDel)) : initial.isDeleted,
        sortF: (allowed=> (allowed.indexOf(query.sf) > -1) ? query.sf : allowedSort[0] )(allowedSort),
        role: is.countable(query.rId) && is.defined(query.rName) ? {id: Number(query.rId), name: String(query.rName)} : initial.role,
        organization: is.countable(query.orgId) && is.defined(query.orgName) ? {id: Number(query.orgId), name: String(query.orgName)} : initial.organization,
        businessUnit: is.countable(query.buId) && is.defined(query.buName) ? new BusinessUnitModel({id: Number(query.buId), name: String(query.buName)}) : initial.businessUnit,
    };
}

/**
 * helper to setup correctness url params
 *
 * @param {Object} reducer
 * @public
 */
function updateLocation ({ page, size, sortD, sortF, filter, email, firstName, lastName, role, organization, businessUnit, isDeleted }) {
    let params = {};
    // NOTE setup data to url which has difference with default data
    size !== initial.size &&(params.s = size);
    page !== initial.page && (params.p = page);
    sortF !== initial.sortF && (params.sf = sortF);
    filter !== initial.filter && (params.f = filter);
    email !== initial.email && (params.em = email);
    lastName !== initial.lastName && (params.ln = lastName);
    firstName !== initial.firstName && (params.fn = firstName);
    sortD !== initial.sortD && (params.sd = Number(Boolean(sortD)));
    isDeleted !== initial.isDeleted && (params.isDel = Number(Boolean(isDeleted)));
    role !== initial.role && (params.rId = role.id) && (params.rName = role.name);
    organization !== initial.organization && (params.orgId = organization.id) && (params.orgName = organization.name);
    businessUnit !== initial.businessUnit && (params.buId = businessUnit.id) && (params.buName = businessUnit.name);
    let search = query.format(params);
    // NOTE update url if it has difference
    if ( search !== history.location.search ) {
        historyPush(history.location.pathname+search);
    }
}
