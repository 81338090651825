
// outsource dependencies
import React from 'react';

// local dependencies

// configuration
export default CenteredBox;

/**
 * Sign In page
 * @constructor CenteredBox
 * @public
 */
function CenteredBox ( props ) {
    let { children, className, ...attr } = props;
    return (
        <div className="centered-box">
            <div className={'box-holder '+(className ? className : '')} {...attr}>
                { children }
            </div>
        </div>
    );
}
