
// outsource dependencies

// local dependencies
import { EDIT } from '../actions';
import { DIFF_MODE } from './index';
import { AUDIT_LOGS } from '../../../constants/routes';

let initial = {
    data: {},
    diff: [],
    list: [],
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
    diffMode: DIFF_MODE.ALL,
    // previous path
    back: AUDIT_LOGS.LINK(),
};

export default function ( state = initial, action ) {
    //eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case EDIT.CLEAR:
            state = initial;
            break;
        case EDIT.DATA:
            state = { ...state, data: options.data  };
            break;
        case EDIT.META:
            state = { ...state, ...options, data: state.data };
            break;
    }

    return state;
}
