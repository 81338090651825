// outsource dependencies
import {toastr} from 'react-redux-toastr';
import {call, put, select, take, takeEvery} from 'redux-saga/effects';

// local dependencies
import {LIST} from './types';
import {initial} from './reducer';
import {allowedSort} from './index';
import is from '../../services/is.service';
import {UserRoleModel} from '../../models';
import {ROLES} from '../../constants/routes';
import query from '../../services/query.service';
import store, {history, historyPush} from '../../store';
import CONFIRM_DIALOG from "../../components/confirmation-dialog/actions";
import {instanceAPI} from "../../services/api.service";

function * initializeSaga () {
    yield put({type: LIST.CLEAR});
    // NOTE take data from location and setup verified params
    const params = yield call(getQueryParams, query.parse(history.location.search));
    // NOTE update list
    yield put({type: LIST.UPDATE_LIST, ...params});
    yield take(LIST.GET_DATA.FINISH);
    // NOTE initialized
    yield put({type: LIST.META, initialized: true});
}

function * getDataSaga () {
    try {
        let reducer = yield select(state=> state.roles);
        let result = yield call(getList, reducer);
        yield put({type: LIST.DATA, list: result.items});
        yield put({type: LIST.META, totalPages: result.pages});
        // NOTE update location
        yield call(updateLocation, reducer);
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_DATA.FINISH});
}

function * updateListSaga ({type, ...options}) {
    // NOTE apply options, remove error and enable preloader
    yield put({type: LIST.META, ...options, expectAnswer: true, errorMessage: null });
    // NOTE send request
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    // NOTE preloader off
    yield put({type: LIST.META, expectAnswer: false });
}

function * changeSortSaga ({type, field}) {
    let params = {sortF: field, sortD: true};
    let { sortF, sortD } = yield select(state=> state.roles);
    // NOTE toggle sort direction for same field
    if ( field === sortF ) {
        params.sortD = !sortD;
    }
    // NOTE update list
    yield put({type: LIST.UPDATE_LIST, ...params});
}

/**
 * connect all public sagas
 *
 * @public
 */
export default function * () {
    yield takeEvery(LIST.INITIALIZE, initializeSaga);
    yield takeEvery(LIST.UPDATE_LIST, updateListSaga);
    yield takeEvery(LIST.CHANGE_SORT, changeSortSaga);
    yield takeEvery(LIST.GET_DATA.REQUEST, getDataSaga);
    yield takeEvery(LIST.DELETE, deleteItemSaga);

    // NOTE setup listener on location change to listen history back event (POP)
    yield call(history.listen, historyBackListen);

}

function historyBackListen ({pathname}, action) {
    if ( action !== 'POP' || pathname !== ROLES.LIST ) return;
    // NOTE reinitialize search from query string after the page path was changed
    // NOTE this event will fired before the url was changed
    setTimeout(()=>store.dispatch({type: LIST.INITIALIZE}), 0);
}

/**
 * wrapper to get list
 *
 * @param {Object} reducer
 * @public
 */
function getList ({ page, size, sortD, sortF, filter }) {
    return UserRoleModel.getPage({
        page,
        size,
        filter: { name: filter },
        sort: { field: sortF, order: sortD ? 'ASC' : 'DESC' },
    });
}

function* deleteItemSaga({type, ...options}) {
    // NOTE ask confirmation of deleting
    yield put({type: CONFIRM_DIALOG.REQUEST, message: 'Are you sure you want to delete this role?'});
    let answer = yield take([CONFIRM_DIALOG.SUCCESS, CONFIRM_DIALOG.ERROR]);
    // NOTE do nothing if confirmation dismiss
    if (answer.type === CONFIRM_DIALOG.ERROR) return;
    yield put({type: LIST.META, expectAnswer: true});
    try {
        yield call(deleteItem, options);
        // update list
        yield put({type: LIST.GET_DATA.REQUEST});
        yield take(LIST.GET_DATA.FINISH);
        yield put({type: LIST.META, expectAnswer: false});
    } catch ({message}) {
        yield put({type: LIST.META, errorMessage: message, expectAnswer: false});
    }
}

/**
 * delete item
 * @param {Object} data
 * @private
 */
function deleteItem(data) {
    return instanceAPI({method: 'delete', url: `/admin/roles`, data});
}


/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ( query ) {
    return {
        page: is.countable(query.p) ? Number(query.p) : initial.page,
        size: is.countable(query.s) ? Number(query.s) : initial.size,
        filter: is.defined(query.f) ? String(query.f) : initial.filter,
        sortD: is.countable(query.sd) ? Boolean(Number(query.sd)) : initial.sortD,
        sortF: (allowed=> (allowed.indexOf(query.sf) > -1) ? query.sf : allowedSort[0] )(allowedSort),
    };
}

/**
 * helper to setup correctness url params
 *
 * @param {Object} reducer
 * @public
 */
function updateLocation ({ page, size, sortD, sortF, filter }) {
    let params = {};
    // NOTE setup data to url which has difference with default data
    size !== initial.size && (params.s = size);
    page !== initial.page && (params.p = page);
    sortF !== initial.sortF && (params.sf = sortF);
    filter !== initial.filter && (params.f = filter);
    sortD !== initial.sortD && (params.sd = Number(Boolean(sortD)));
    let search = query.format(params);
    // NOTE update url if it has difference
    if ( search !== history.location.search ) {
        historyPush(history.location.pathname+search);
    }
}
