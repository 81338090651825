// outsource dependencies

// local dependencies
import {LIST} from '../actions';

export const initial = {
    data: [],
    // meta
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
    // pagination
    page: 0,
    size: 10,
    totalPages: 0,
    // sort
    sortF: 'date',
    sortD: false,
    // filter
    filter: '',
};

export default function (state = initial, action) {
    let {type, ...options} = action;
    switch (type) {
        default:
            break;
        case LIST.CLEAR:
            state = initial;
            break;
        case LIST.DATA:
            state = { ...state, data: options.data };
            break;
        case LIST.META:
            state = { ...state, ...options, data: state.data  };
            break;
    }
    return state;
}
