
// outsource dependencies
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Close, Cloud } from '@material-ui/icons';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, TableSortLabel, Card, CardHeader, CardContent, CardActions, IconButton } from '@material-ui/core';

// local dependencies
import { LIST } from '../types';
import { history } from '../../../store';
import Page from '../../../components/page';
import Alert from '../../../components/alert-error';
import { ORGANIZATIONS } from '../../../constants/routes';
import { ORGANIZATION_TYPES } from '../../../constants/spec';
import { OrganizationModel, UserModel } from '../../../models';
import MdTablePagination from '../../../components/pagination';
import { Humanize, Truncate } from '../../../components/filter';
import Preloader, { TYPE } from '../../../components/preloader';
import { LOAD_DEMO_MODAL } from  '../../load-demo-modal/actions'
import { SimpleSelect, SimpleAsyncSelect } from '../../../components/md-select';
import SearchFilter, { SimpleSearchField }  from '../../../components/search-filter';
import { Link, EditBtn, DelBtn, AddBtn, PrimaryBtn, WarningBtn, LoadDemoModelBtn } from '../../../components/md-button';
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {ORGANIZATION_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";

import noLogoImage from '../../../images/no-image-icon.png';

// configure
export const allowedSort = ['name', 'organizationType', 'description', 'owner', 'parent', 'rootParent'];

/**
 * Table
 *
 * @private
 */
const ConnectedTable = connect(
    state => ({
        list: state.organizations.list.list,
        page: state.organizations.list.page,
        size: state.organizations.list.size,
        totalPages: state.organizations.list.totalPages,
        disabled: state.organizations.list.expectAnswer,
    }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        loadDemo: data => dispatch({type: LOAD_DEMO_MODAL.INITIALIZE, ...data}),
        changePage: page => dispatch({type: LIST.UPDATE_LIST, page}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
        changeSize: size => dispatch({type: LIST.UPDATE_LIST, size, page: 0}),
        deleteItem: id => toastr.confirm(<ConfirmDeleting />, { onOk: () => dispatch({type: LIST.DELETE_ITEM, id}) }),
    })
)(({ list, disabled, page, size, totalPages, changePage, changeSize, loadDemo, deleteItem }) => { return (<Paper>
    <div style={{overflowX: 'auto'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <SortableCell name="name" />
                    <SortableCell name="organizationType" style={{whiteSpace: 'nowrap'}} />
                    <SortableCell name="description" />
                    <SortableCell name="owner" />
                    <SortableCell name="parent" />
                    <SortableCell name="rootParent" style={{whiteSpace: 'nowrap'}} />
                    <TableCell align="right" style={{ minWidth: '420px', width: '420px'}} />
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (<TableRow key={index}>
                    <TableCell component="th" scope="row">
                        {!item.logo ? '' :(
                            <img alt={item.name} src={item.logo} height={18} onError={(event) => event.currentTarget.src = noLogoImage} />
                        )}
                        {!item.logo ? '' : '\u00a0'}
                        {item.name}&nbsp;{item.isCloudVendor && <Cloud color="primary" className="align-middle" />}
                    </TableCell>
                    <TableCell component="th" scope="row"> {item.organizationType} </TableCell>
                    <TableCell component="th" scope="row">
                        <Truncate tag="h5" text={item.description||''} options={{length: 40, end: '...', breakOnWord: true}} />
                    </TableCell>
                    <TableCell component="th" scope="row"> {get(item, 'owner.fullName')} </TableCell>
                    <TableCell component="th" scope="row"> {get(item, 'parent.name')} </TableCell>
                    <TableCell component="th" scope="row"> {get(item, 'rootParent.name')} </TableCell>
                    <TableCell align="right" component="th" scope="row">
                        {item.organizationType === ORGANIZATION_TYPES.ORGANIZATION ? <LoadDemoModelBtn style={{color: 'white'}} disabled={disabled} onClick={ e => loadDemo(item) }/> : null}
                        &nbsp;&nbsp;&nbsp;
                        <Link
                            Btn={EditBtn}
                            disabled={disabled}
                            to={ORGANIZATIONS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                                />
                        &nbsp;&nbsp;&nbsp;
                        <DelBtn disabled={disabled} onClick={() => deleteItem(item.id)}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={disabled}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</Paper>)});

/**
 * prepared confirmation for organization deleting
 *
 * @public
 */
const ConfirmDeleting = () => (<div>
    <h3> Confirm deleting </h3>
    <p> Are you sure you want to delete the current organization? </p>
</div>);

/**
 * Wrapper for sorting TH
 *
 * @private
 */
const SortableCell = connect(
    state => ({
        disabled: state.organizations.list.expectAnswer,
        sortF: state.organizations.list.sortF,
        sortD: state.organizations.list.sortD,
    }),
    dispatch => ({ changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}) })
)(({disabled, sortF, sortD, name, changeSort, ...attr})=>(
    <TableCell className="th" {...attr}>
        <Tooltip
            enterDelay={300}
            placement="top-start"
            title={(<span>Sort by "<Humanize tag="strong" text={name} />"</span>)}
                >
            <span>
                <TableSortLabel
                    disabled={disabled}
                    active={sortF===name}
                    direction={sortD?'asc':'desc'}
                    onClick={()=>changeSort(name)}
                        >
                    <Humanize tag="strong" text={name} />
                </TableSortLabel>
            </span>
        </Tooltip>
    </TableCell>
));

/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = connect(
    state => ({showAdvanced: state.organizations.list.showAdvanced}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_LIST, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => dispatch({type: LIST.META, showAdvanced: true}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_LIST, filter, page: 0}),
    })
)( ({showAdvanced, update, openAdvanced, closeAdvanced}) => (<div>
    { showAdvanced ? (
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title="Advanced search"
                        action={
                            <Tooltip title="Cancel">
                                <IconButton aria-label="Cancel advanced search" onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }/>
                    <CardContent> <SearchForm /> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn onClick={update} tooltip="Apply"> Apply </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={closeAdvanced} tooltip="Cancel"> Cancel </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected />
            </Col>
            <Col xs={12} sm={6}>
                <PrimaryBtn onClick={openAdvanced} tooltip="Advanced search"> Advanced search </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>));

/**
 *
 * @private
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.organizations.list.filter,
        disabled: state.organizations.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_LIST, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

const SearchForm = connect(
    state => ({...state.organizations.list}),
    dispatch => ({ changeFilterValue: data => dispatch({type: LIST.META, ...data}) })
)( ({ expectAnswer, filter, organizationType, owner, parent, rootParent, changeFilterValue }) => (<div>
    <Row>
        <Col xs={12} md={6} lg={4} className="offset-bottom-2">
            <SimpleSearchField
                value={filter}
                disabled={expectAnswer}
                clear={() => changeFilterValue({filter: ''})}
                onChange={e => changeFilterValue({filter: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={6} lg={4} className="offset-bottom-2">
            <SimpleSelect
                valueKey="value"
                labelKey="value"
                isClearable={true}
                disabled={expectAnswer}
                name="organizationType"
                value={organizationType}
                label={(<strong> Type </strong>)}
                simpleValue={value => value && ({value})}
                onChange={organizationType => changeFilterValue({organizationType})}
                options={[ORGANIZATION_TYPES.ORGANIZATION, ORGANIZATION_TYPES.VENDOR, ORGANIZATION_TYPES.SUBSIDIARY]}
                    />
        </Col>
        <Col xs={12} lg={4} className="offset-bottom-2">
            <SimpleAsyncSelect
                value={owner}
                labelKey="fullName"
                isClearable={true}
                placeholder="Owner"
                disabled={expectAnswer}
                label={(<strong> Owner </strong>)}
                onChange={owner => changeFilterValue({owner})}
                loadOptions={(name, done) => {
                    UserModel.getListByName(name, 6).then(done).catch(()=> done([]));
                }}/>
        </Col>
    </Row>
    <Row>
        <Col xs={12} md={6} className="offset-bottom-2">
            <SimpleAsyncSelect
                value={parent}
                isClearable={true}
                placeholder="Parent"
                disabled={expectAnswer}
                label={(<strong> Parent </strong>)}
                onChange={parent => changeFilterValue({parent})}
                loadOptions={(name, done) => {
                    OrganizationModel.getListByName(name, 6).then(done).catch(()=> done([]));
                }}/>
        </Col>
        <Col xs={12} md={6} className="offset-bottom-2">
            <SimpleAsyncSelect
                value={rootParent}
                isClearable={true}
                disabled={expectAnswer}
                placeholder="Root parent"
                label={(<strong> Root parent </strong>)}
                onChange={rootParent => changeFilterValue({rootParent})}
                loadOptions={(name, done) => {
                    OrganizationModel.getListByName(name, 6).then(done).catch(()=> done([]));
                }}/>
        </Col>
    </Row>
</div>));

/**
 * Table wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.organizations.list }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
    })
)(({ list, init, initialized, expectAnswer, errorMessage, clearError }) => (
    <Page init={init} initialized={initialized}>
        <Row>
            <Col xs={8}>
                <h2 className="text-uppercase offset-bottom-4">
                    Organization List &nbsp; <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                </h2>
                <Breadcrumbs breadCrumbsMap={ ORGANIZATION_MAP }  />
            </Col>
            <Col xs={4} className="text-right top-indent-4">
                <Link Btn={AddBtn} to={ORGANIZATIONS.LINK_EDIT()} disabled={expectAnswer} tooltip="Create organization" />
            </Col>
        </Row>
        <Row> <Col xs={12} md={8} mdOffset={2}> <Alert active message={errorMessage} onChange={clearError} /> </Col> </Row>
        <Row> <Col xs={12}> <FiltersPanelConnected /> </Col> </Row>
        <Row> <Col xs={12}>
            {list.length ? (<ConnectedTable />) : (<h3 className="text-uppercase text-center text-highlighted"> There is no organizations </h3>)}
        </Col> </Row>
    </Page>
));
