
// outsource dependencies
import React from 'react';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';

// local dependencies

// configuration

/**
 * Pretty checkbox component
 *
 * @public
 */
export default function ({ input, meta, label, fullWidth = true, ...attr }) {
    return (<FormControl fullWidth={fullWidth}>
        <FormControlLabel
            label={label}
            control={
                <Switch
                    color="primary"
                    {...attr}
                    id={input.name}
                    name={input.name}
                    checked={Boolean(input.value)}
                    onChange={(event, value) => input.onChange(value)}/>
            }/>
    </FormControl>);
};


/**
 * simple checkbox field which can be used inside filter panel etc
 *
 * @param { Object } props
 * @public
 */
export function SimpleSwitch ({ value, onChange, label, fullWidth = true, ...attr }) {
    return (<FormControl fullWidth={fullWidth}>
        <FormControlLabel
            label={label}
            control={
                <Switch
                    {...attr}
                    color="primary"
                    checked={Boolean(value)}
                    onChange={(e,value) => onChange(value)}
                />
            }/>
    </FormControl>);
}
