
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, TablePagination } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, LastPage, FirstPage } from '@material-ui/icons';

// local dependencies

// configure
Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    changeSize: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,

    totalPages: PropTypes.number,
    disabled: PropTypes.bool,
    sizes: PropTypes.array,
    label: PropTypes.node,
};
export default Pagination;

/**
 * Table pagination
 *
 * @param {Object} props
 * @public
 */
function Pagination ({ page, size, disabled, label, totalPages, changePage, changeSize, sizes = [10, 15, 20, 30] }) {
    return (
        <TablePagination
            page={page}
            component="div"
            count={totalPages}
            rowsPerPage={size}
            disabled={disabled}
            rowsPerPageOptions={sizes}
            ActionsComponent={PageActions}
            labelDisplayedRows={PageLabels}
            // unknown reason material pagination duplicate firing function without event
            onChangePage={(e, page) => e&&changePage(page)}
            labelRowsPerPage={label||(<strong> SIZE </strong>)}
            onChangeRowsPerPage={e => changeSize(e.target.value)}
            SelectProps={{ disabled, renderValue: value => (<strong>&nbsp;{value}&nbsp;&nbsp;</strong>) }}
                />
    );
}

/**
 * Pagination actions
 *
 * @param {Object} props
 * @public
 */
function PageLabels ({ count, page }) {
    return (<div>
        <Tooltip title="Current Page" placement="bottom" enterDelay={300}>
            <span> Page <strong>{page+1}</strong> </span>
        </Tooltip>
        <Tooltip title="Total Pages" placement="bottom" enterDelay={300}>
            <span> from <strong>{count}</strong> </span>
        </Tooltip>
    </div>);
}

/**
 * Pagination actions
 *
 * @param {Object} props
 * @public
 */
function PageActions ({ className, count, page, onChangePage, disabled }) {
    let disableNext = disabled || page >= (count-1);
    let disablePrev = disabled || page === 0;
    return (<div className={className}>
        <Tooltip title="First Page" placement="bottom" enterDelay={300}><span>
            <IconButton onClick={e=>onChangePage(e, 0)} disabled={disablePrev} aria-label="First Page">
                <FirstPage />
            </IconButton>
        </span></Tooltip>
        <Tooltip title="Prev Page" placement="bottom" enterDelay={300}><span>
            <IconButton onClick={e=>onChangePage(e, page-1)} disabled={disablePrev} aria-label="Previous Page">
                <KeyboardArrowLeft />
            </IconButton>
        </span></Tooltip>
        <Tooltip title="Next Page" placement="bottom" enterDelay={300}><span>
            <IconButton onClick={e=>onChangePage(e, page+1)} disabled={disableNext} aria-label="Next Page">
                <KeyboardArrowRight />
            </IconButton>
        </span></Tooltip>
        <Tooltip title="Last Page" placement="bottom" enterDelay={300}><span>
            <IconButton onClick={e=>onChangePage(e, count-1)} disabled={disableNext} aria-label="Last Page">
                <LastPage />
            </IconButton>
        </span></Tooltip>
    </div>);
}
