
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { initial } from './reducer';
import { DICTIONARY } from '../types';
import { allowedSort } from './index';
import is from '../../../services/is.service';
import { LanguageModel } from '../../../models';
import query from '../../../services/query.service';
import { LANGUAGES } from '../../../constants/routes';
import { instanceAPI } from '../../../services/api.service';
import store, { history, historyPush } from '../../../store';

function * initializeSaga ({langId}) {
    yield put({type: DICTIONARY.CLEAR});
    const language = yield call(getLanguage, langId);
    // NOTE take data from location and setup verified params
    const params = yield call(getQueryParams, query.parse(history.location.search));
    // NOTE update list
    yield put({type: DICTIONARY.UPDATE_LIST, ...params, language});
    // NOTE initialized
    yield put({type: DICTIONARY.META, initialized: true});
}

function * getDataSaga () {
    try {
        let reducer = yield select(state => state.languages.dictionary);
        let result = yield call(getList, reducer);
        yield put({type: DICTIONARY.DATA, list: result.items});
        yield put({type: DICTIONARY.META, totalPages: result.pages});
        // NOTE update location
        yield call(updateLocation, reducer);
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: DICTIONARY.META, errorMessage: message});
    }
}

function * updateListSaga ({type, ...options}) {
    // NOTE apply options, remove error and enable preloader
    yield put({type: DICTIONARY.META, ...options, expectAnswer: true, errorMessage: null });
    // NOTE send request
    yield call(getDataSaga);
    // NOTE preloader off
    yield put({type: DICTIONARY.META, expectAnswer: false });
}

function * updateTranslationSaga ({item}) {
    yield put({type: DICTIONARY.META, expectAnswer: true, errorMessage: null });
    try {
        let language = yield select(state => state.languages.dictionary.language);
        yield call( updateLanguageConst, item, language.code );
        //NOTE update list
        yield put({type: DICTIONARY.UPDATE_LIST});
        yield put({type: DICTIONARY.META, expectAnswer: false});
        yield call(toastr.success, 'Dictionary', 'Translation has been updated successfully.');
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: DICTIONARY.META, errorMessage: message, expectAnswer: false});
    }
}

function * changeSortSaga ({type, field}) {
    let params = {sortF: field, sortD: true};
    let { sortF, sortD } = yield select(state=> state.languages.dictionary);
    // NOTE toggle sort direction for same field
    if ( field === sortF ) {
        params.sortD = !sortD;
    }
    // NOTE update list
    yield put({type: DICTIONARY.UPDATE_LIST, ...params});
}

function* deleteTranslationSaga () {
    yield put({type: DICTIONARY.META, expectAnswer: true});
    try {
        let language = yield select(state => state.languages.dictionary.language);
        yield call( deleteTranslation, language.code );
        //NOTE update list
        yield put({type: DICTIONARY.UPDATE_LIST});
        yield put({type: DICTIONARY.META, expectAnswer: false});
        yield call(toastr.success, 'Dictionary', 'Dictionary has been deleted.');
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: DICTIONARY.META, errorMessage: message, expectAnswer: false});
    }
}

/**
 * connect all public sagas
 *
 * @public
 */
export default function * () {
    yield takeEvery(DICTIONARY.GET_DATA, getDataSaga);
    yield takeEvery(DICTIONARY.INITIALIZE, initializeSaga);
    yield takeEvery(DICTIONARY.UPDATE_LIST, updateListSaga);
    yield takeEvery(DICTIONARY.CHANGE_SORT, changeSortSaga);
    yield takeEvery(DICTIONARY.DELETE_TRANSLATION, deleteTranslationSaga);
    yield takeEvery(DICTIONARY.UPDATE_TRANSLATION, updateTranslationSaga);

    // NOTE setup listener on location change to listen history back event (POP)
    yield call(history.listen, historyBackListen);
}

function historyBackListen ({pathname}, action) {
    if ( action !== 'POP' || pathname !== LANGUAGES.DICTIONARY ) return;
    // NOTE reinitialize search from query string after the page path was changed
    // NOTE this event will fired before the url was changed
    setTimeout(()=>store.dispatch({type: DICTIONARY.INITIALIZE}), 0);
}

/**
 * wrapper to get list
 *
 * @param {Object} reducer
 * @public
 */
function getList ({ language, page, size, sortD, sortF, filter }) {
    return instanceAPI({
        method: 'post',
        headers: {'language-code': language.code},
        url: '/admin/language-constants/values/filter',
        data: { filter: { value: filter }, page, size, sort: { field: sortF, order: sortD ? 'ASC' : 'DESC' } }
    });
}

/**
 * update language constant
 *
 * @param {Object} data
 * @param {String} code - current language code
 * @public
 */
function updateLanguageConst (data, code) {
    return instanceAPI({data: [data], headers: {'language-code': code}, method: 'put', url: '/admin/language-constants/values'});
}

/**
 * delete all translation for current language
 *
 * @param {String} code
 * @private
 */
function deleteTranslation(code) {
    return instanceAPI({
        method: 'delete',
        headers: {'language-code': code},
        url: '/admin/language-constants/values'
    });
}

/**
 * get current language by id
 *
 * @param {String} id
 * @private
 */
function getLanguage ( id ) {
    return LanguageModel.getById(id);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ( query ) {
    return {
        back: query.back || initial.back,
        page: is.countable(query.p) ? Number(query.p) : initial.page,
        size: is.countable(query.s) ? Number(query.s) : initial.size,
        filter: is.defined(query.f) ? String(query.f) : initial.filter,
        sortD: is.countable(query.sd) ? Boolean(Number(query.sd)) : initial.sortD,
        sortF: (allowed=> (allowed.indexOf(query.sf) > -1) ? query.sf : allowedSort[0] )(allowedSort),
    };
}

/**
 * helper to setup correctness url params
 *
 * @param {Object} reducer
 * @public
 */
function updateLocation ({ page, size, sortD, sortF, filter, back }) {
    let params = { back };
    // NOTE setup data to url which has difference with default data
    size !== initial.size && (params.s = size);
    page !== initial.page && (params.p = page);
    sortF !== initial.sortF && (params.sf = sortF);
    filter !== initial.filter && (params.f = filter);
    sortD !== initial.sortD && (params.sd = Number(Boolean(sortD)));
    let search = query.format(params);
    // NOTE update url if it has difference
    if ( search !== history.location.search ) {
        historyPush(history.location.pathname+search);
    }
}
