
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const LIST = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        DELETE_ITEM: `${prefix}DELETE_ITEM`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
    }
})('@import-whats-new/list/');

export const ACTIONS = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
    };
})('@import-whats-new/import/');