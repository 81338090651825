// outsource dependencies
import React, { Component } from 'react';
import { EditorState, ContentState, /*convertFromHTML,*/ convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// options
let toolbarOptions = {
    options: [
        'inline', 'blockType', 'fontSize',  /*'fontFamily',*/ 'list', 'colorPicker', 'textAlign',
        'link',  /* 'emoji','embedded', 'image', */ 'remove', 'history',
    ],
    inline: {
        inDropdown: false,
        className: null,
        component: null,
        dropdownClassName: null,
        // NOTE superscript and subscript not working propperly in case to restore data
        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
        // bold: { icon: bold, className: null },
        // italic: { icon: italic, className: null },
        // underline: { icon: underline, className: null },
        // strikethrough: { icon: strikethrough, className: null },
        // monospace: { icon: monospace, className: null },
        // superscript: { icon: superscript, className: null },
        // subscript: { icon: subscript, className: null },
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', /*'Blockquote', 'Code'*/],
        className: null,
        component: null,
        dropdownClassName: null,
    },
    fontSize: {
        // icon: fontSize,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: null,
        component: null,
        dropdownClassName: null,
    },
    fontFamily: {
        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Gotham', 'GE_Flow'],
        className: null,
        component: null,
        dropdownClassName: null,
    },
    list: {
        inDropdown: false,
        className: null,
        component: null,
        dropdownClassName: null,
        options: ['unordered', 'ordered'],
        // unordered: { icon: unordered, className: null },
        // ordered: { icon: ordered, className: null },
        // indent: { icon: indent, className: null },
        // outdent: { icon: outdent, className: null },
    },
    textAlign: {
        inDropdown: false,
        className: null,
        component: null,
        dropdownClassName: null,
        options: ['left', 'center', 'right', 'justify'],
        // left: { icon: left, className: null },
        // center: { icon: center, className: null },
        // right: { icon: right, className: null },
        // justify: { icon: justify, className: null },
    },
    colorPicker: {
        // icon: color,
        className: null,
        component: null,
        popupClassName: null,
        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    link: {
        inDropdown: true,
        className: null,
        component: null,
        popupClassName: null,
        dropdownClassName: null,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        // link: { icon: link, className: null },
        // unlink: { icon: unlink, className: null },
    },
    emoji: {
        // icon: emoji,
        className: null,
        component: null,
        popupClassName: null,
        emojis: [
            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
            '✅', '❎', '💯',
        ],
    },
    image: {
        // icon: image,
        className: null,
        component: null,
        popupClassName: null,
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: null,
        previewImage: false,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: { present: false, mandatory: false },
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    },
    remove: {
        // icon: eraser,
        className: null,
        component: null
    },
    history: {
        inDropdown: false,
        className: null,
        component: null,
        dropdownClassName: null,
        options: ['undo', 'redo'],
        // undo: { icon: undo, className: null },
        // redo: { icon: redo, className: null },
    },
};

/**
 *
 *
 * @public
 */
class HtmlEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            editorState: EditorState.createEmpty(),
        };
    }

    /**
     * correct convert html to editor
     *
     * @public
     */
    htmlToEditor ( html ) {
        // let converted = convertFromHTML(html);
        let converted = htmlToDraft(html);
        if ( converted.contentBlocks ) {
            let state = ContentState.createFromBlockArray(converted.contentBlocks, converted.entityMap);
            let editorState = EditorState.createWithContent(state);
            this.setState({ editorState, value: html });
        }
    }

    /**
     * update component value with redux form
     *
     * @public
     */
    onChange ( editorState ) {
        let { input: { onChange } } = this.props;
        let html = draftToHtml( convertToRaw(editorState.getCurrentContent()) );
        // console.log('%c class HtmlEditor onChange ( editorState )', 'background: #0f9d58; color: #fff; font-size: 12px;'
        //     ,'\n props:', this.props
        //     ,'\n state:', this.state
        //     ,'\n editorState:', editorState
        //     ,'\n html:', html
        // );
        onChange(html);
        this.setState({ editorState, value: html });
    }

    render() {

        let { input, label, className = '', meta } = this.props;
        let { editorState } = this.state;
        let message = meta.valid ? '' : meta.error;
        let statusClassName = meta.valid ? 'has-success' : 'has-error';

        return (
            <div className={statusClassName+' form-group offset-bottom-1 cbc-html-wrapper '+(className||'')}>
                <input className="hidden-control" {...input} type="text" onFocus={()=> this.ref.focus()}/>
                <label style={{color: "#0000008a"}} htmlFor={input.name} className="input-group"> { label } </label>
                <Editor
                    toolbar={toolbarOptions}
                    editorRef={r=>this.ref=r}
                    editorState={editorState}
                    wrapperClassName=""
                    editorClassName="cbc-html-editor"
                    toolbarClassName="cbc-html-toolbar"
                    onEditorStateChange={(...args)=>this.onChange(...args)}
                        />
                { !message ? ('') : (
                    <div className={'input-group '+statusClassName}>
                        <label htmlFor={input.name} className="help-block offset-bottom-0"> { message } </label>
                    </div>
                )}
            </div>
        )
    }

    /**
     * update component value from redux form
     *
     * @public
     */
    componentDidUpdate ( prev ) {
        // handle difference between the item and expectation
        let { input } = this.props;
        let { value } = this.state;
        // check is the value same
        if ( prev.input.value !== input.value && value !== input.value ) {
            // console.log('%c componentDidUpdate ()', 'background: #0f9d58; color: #fff; font-size: 12px;'
            //     ,'\n prev.input.value:', prev.input.value
            //     ,'\n input.value:', input.value
            //     ,'\n value:', value
            // );
            this.htmlToEditor( input.value );
        }
    }

    /**
     * update component value from redux form
     *
     * @public
     */
    componentDidMount () {
        let { input } = this.props;
        this.htmlToEditor( input.value );
    }

}

export default HtmlEditor;
