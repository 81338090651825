// outsource dependencies
import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {show} from 'redux-modal';
import {connect} from 'react-redux';

// local dependencies
import is from '../services/is.service';
import cloudNormal from '../images/cloud_normal.svg';
import {Edit} from '@material-ui/icons';

// configuration

/**
 * @description input component with helpers to display validation results
 * @example
 <Field
 required
 name="avatar"
 placeholder="avatar"
 component={ InputImage }
 className="form-control"
 label={ <span> @ </span> }
 />
 * @constructor Input
 * @public
 */
class InputImage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: props.value,
            file: {},
        };
        // NOTE fix react warnings
        let oss = this.setState.bind(this);
        this.setState = (...args) => !this.isUnmauonted && oss(...args);

        // this.useStrictValue = props.useStrictValue;
        this.processValue = props.processValue ? props.processValue : file => file;
        this.disabled = props.disabled ? props.disabled : false;
    }

    // NOTE fix react warnings
    componentWillUnmount() {
        this.isUnmauonted = true;
    }

    /**
     * handle updating redux form value
     *
     * @public
     */
    // handleChange ( url ) {
    //     let { input: { onChange, value }, success } = this.props;
    //     (url !== value)&&onChange( url );
    //     (url !== this.state.url)&&this.setState({ url });
    //     is.function(success)&&success( url );
    // }
    handleChange(file) {
        let {input: {onChange, value}, success} = this.props;
        (file !== value) && onChange(this.processValue(file));
        (file !== this.state.file) && this.setState({file});
        is.function(success) && success(file);
    }

    /**
     * handle updating file and initializing image crop modal window
     *
     * @public
     */
    changeUrl(accepted, rejected) {
        let {onRejectFile, validateFile, help = '', maxWeight = 1, crop = {aspect: 16/9}, title = 'Image crop'} = this.props;

        let file = accepted[0];
        if (file) {
            if ((!is.function(validateFile)) || validateFile(file)) {
                return this.props.show('image-crop-modal', {
                    file,
                    crop,
                    help,
                    title,
                    maxWeight, // MB
                    // success: res => this.handleChange( res.url )
                    success: res => this.handleChange({logoDocument: res})
                });
            }
        }
        is.function(onRejectFile) && onRejectFile(file || rejected[0]);
    }

    render() {
        let {input, meta, label, disabled, placeholder, height, style, defImage = cloudNormal, preview = true, url} = this.props;
        // let { url } = this.state;
        let message = meta.valid ? '' : meta.error,
            statusClassName = meta.valid ? 'is-valid' : 'is-invalid';

        // prevent setup result image to the component view
        url = preview ? (url || defImage) : defImage;

        let ref = null;

        if (!disabled) {
            return (
                <div  className={'form-group text-center offset-bottom-1 input-image ' + statusClassName}>
                    <input className="hidden-control" name={input.name} id={input.name} type="text"
                           onFocus={() => ref.open()} onChange={e => this.handleChange(e.target.value)}/>
                    <div>
                        <label htmlFor={input.name} className="input-group"> {label} </label>
                        <Dropzone
                            accept={{
                                'image/jpeg': ['.jpeg','.jpg'],
                                'image/gif':['.gif'],
                                'image/png': ['.png'],
                            }}
                            useFsAccessApi={false}
                            ref={r => ref = r}
                            className={'drop-zone'}
                            rejectClassName="reject"
                            disabledClassName="disabled"
                            acceptClassName="success"
                            activeClassName="active"
                            onDrop={(...args) => this.changeUrl(...args)}
                        >
                            {({rejectedFiles, getRootProps, getInputProps}) => (
                                <div className='drop-zone' style={{position: 'relative'}} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <img className="no-events" src={url} height={height ? height : 80} alt={placeholder} style={style}/>
                                    <Edit className="edit-image" fontSize="small" />
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    {!message ? ('') : (
                        <div className={'input-group ' + statusClassName}>
                            <label htmlFor={input.name} className="form-text offset-bottom-0 text-danger"> {message} </label>
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div  className={'form-group text-center offset-bottom-1 input-image ' + statusClassName}>
                    <img className="no-events" src={url} height={height ? height : 80} alt={placeholder} style={style}/>
                </div>
            );
        }
    }

    /**
     * update component value from redux form
     *
     * @public
     */
    componentDidUpdate() {
        // handle difference between the item and expectation
        let {input, preview = true} = this.props;
        let {url} = this.state;
        // check is the value same
        if (preview && url !== input.value) {
            this.handleChange(input.value);
        }
    }

    /**
     * update component value from redux form
     *
     * @public
     */
    componentDidMount() {
        let {input} = this.props;
        let {url} = this.state;
        if (url !== input.value) {
            this.handleChange(input.value);
        }
    }

}

export default connect(null, {show})(InputImage);
