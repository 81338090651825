
// outsource dependencies
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { Grid, Row, Col } from 'react-bootstrap';

// local dependencies
import { EDIT } from '../actions';
import { NEW_ID } from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import { SubmitBtn, ResetBtn, CancelBtn } from '../../../components/md-button';
import {MdSelect} from "../../../components/md-select";
import {ASSET_CLASSES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

class Edit extends Component {
    componentDidMount() { this.props.initialize( this.props.match.params.id ); }
    componentWillUnmount() { this.props.clear(); }


    render() {
        let { expectAnswer, match } = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        {isNew
                                            ? 'Create asset class'
                                            : 'Edit asset class'
                                        }
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                </h2>
                                <Breadcrumbs breadCrumbsMap={ ASSET_CLASSES_MAP }  />
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.assetClasses.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.assetClasses.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.assetClasses.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        initialValues: state.assetClasses.edit.data,
        disabled: state.assetClasses.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData})
    })
)(reduxForm({
    form: 'editAssetClass',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel})=>(
    <form autoComplete="off" name="editAssetClass" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder='Name'
                    label={(<strong className="required-asterisk"> Name </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder="Description"
                    label={(<strong> Description </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="threshold"
                    disabled={disabled}
                    component={MdSelect}
                    // placeholder="Risk threshold"
                    options={[1, 2, 3, 4, 5]}
                    optionsLabel={
                        ({value})=> {
                            if (value === 1){return 'Low'}
                            if (value === 2){return 'Medium Low'}
                            if (value === 3){return 'Medium'}
                            if (value === 4){return 'Medium High'}
                            if (value === 5){return 'High'}
                        }
                    }
                    label={(<strong> Risk threshold </strong>)}
                    simpleValue={(value) => {
                        let lbl = '';
                        if (value === 1){lbl = 'Low'}
                        if (value === 2){lbl = 'Medium Low'}
                        if (value === 3){lbl = 'Medium'}
                        if (value === 4){lbl = 'Medium High'}
                        if (value === 5){lbl = 'High'}
                        return {value, label: lbl} }}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" />
                <CancelBtn onClick={cancel} />
            </Col>
        </Row>
    </form>
)));
