
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

// local dependencies
import { history } from '../store';
import { Avatar } from '../images/index';
import { filters } from '../components/filter';
import { APP, PRIVATE } from '../actions/types';
import { PROFILE, PRIVATE_CHANGE_PASSWORD  } from '../constants/routes';

/**
 * Connected user menu
 *
 */
export default connect(
    state => ({
        user: state.app.user,
        expanded: state.privateLayout.expanded
    }),
    dispatch => ({
        signOut: () => dispatch({type: APP.SIGN_OUT.REQUEST}),
        toggleMenu: expanded => dispatch({type: PRIVATE.TOGGLE_ASIDE, expanded}),
    })
)(({ user, signOut, expanded, toggleMenu }) => (<Dropdown id="user-action-dropdown" className="pull-right">
    <button className="btn btn-user-avatar no-events" disabled={true}>
        <strong> { user.fullName } </strong>&nbsp;
        <Avatar width="35" height="35" src={ user.avatar } alt={ user.fullName } />
    </button>
    <Dropdown.Toggle bsSize="lg"/>
    <Dropdown.Menu>
        <MenuItem disabled={true}> { (user.roleNames||[]).map(role=>filters.humanize(role)).join() } </MenuItem>
        <LinkContainer
            to={PROFILE.LINK()}
            isActive={()=>(PROFILE.REGEXP.test(history.location.pathname))}
                >
            <MenuItem eventKey="1">
                <i className="fa fa-user" aria-hidden="true"> </i>
                &nbsp;Profile
            </MenuItem>
        </LinkContainer>
        <LinkContainer
            to={PRIVATE_CHANGE_PASSWORD.LINK()}
            isActive={()=>(PRIVATE_CHANGE_PASSWORD.REGEXP.test(history.location.pathname))}
                >
            <MenuItem eventKey="2"> Change password </MenuItem>
        </LinkContainer>
        <MenuItem eventKey="2" onClick={e=>toggleMenu(!expanded)}> Toggle menu </MenuItem>
        <MenuItem divider={true} />
        <MenuItem eventKey="3" onClick={signOut}>
            <i className="fa fa-sign-out" aria-hidden="true"> </i>
            &nbsp;Sign Out
        </MenuItem>
    </Dropdown.Menu>
</Dropdown>));
