
// outsource dependencies

// local dependencies
import { EDIT } from '../types';
import { USERS } from '../../../constants/routes';

// configuration
let initial = {
    initialized: false,
    expectAnswer: true,
    errorMessage: null,
    data: {},
    availableIdpIds: [],
    // previous path
    back: USERS.LINK(),
};

export default function ( state = initial, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case EDIT.CLEAR:
            state = initial;
            break;
        case EDIT.DATA:
            state = {...state, data: {...options}};
            break;
        case EDIT.META:
            state = {...state, ...options, data: state.data};
            break;
    }

    // EDIT.PREFIX.test(type)&&
    // console.log(`%c USER EDIT ${type} `, 'color: #fff; background: #232323; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );

    return state;
}
