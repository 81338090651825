
import { ENTITY_TYPE } from '../constants/spec';
import BaseModel from './base.model';
import UserModel from './user.model';
import LanguageModel from './language.model';
import IndustryModel from './industry.model';
import UserRoleModel from './user-role.model';
import DataTypeModel from './data-type.model';
import CountriesModel from './countries.model';
import AgreementModel from './agreement.model';
import RegulationModel from './regulation.model';
import TechnologyCategoriesModel from './technology-categories.model';
import OrganizationModel from './organization.model';
import BusinessUnitModel from './business-unit.model';

/**
 * Associate models with on ENTITY_TYPE constant to provide ability determine Models programmatically
 *
 * @param { String } type
 * @public
 */
function getModelByType ( type ) {
    switch ( type ) {
        default: console.log(`%c getModelByType ${type} !!!`, 'color: #fff; background: #a41e22; font-size: 20px;');
            return UserModel;
        case ENTITY_TYPE.USERS: return UserModel;
        case ENTITY_TYPE.LANGUAGES: return LanguageModel;
        case ENTITY_TYPE.USER_ROLES: return UserRoleModel;
        case ENTITY_TYPE.AGREEMENTS: return AgreementModel;
        case ENTITY_TYPE.ORGANIZATIONS: return OrganizationModel;
        case ENTITY_TYPE.REGULATIONS: return RegulationModel;
        case ENTITY_TYPE.INDUSTRIES: return IndustryModel;
        case ENTITY_TYPE.COUNTRIES: return CountriesModel;
        case ENTITY_TYPE.TECHNOLOGY_CATEGORIES: return TechnologyCategoriesModel;
        case ENTITY_TYPE.DATA_TYPES: return DataTypeModel;
        case ENTITY_TYPE.BUSINESS_UNIT: return BusinessUnitModel;
    }
}

export {
    getModelByType,
    BaseModel,
    UserModel,
    LanguageModel,
    UserRoleModel,
    DataTypeModel,
    IndustryModel,
    AgreementModel,
    RegulationModel,
    TechnologyCategoriesModel,
    OrganizationModel,
    BusinessUnitModel,
};

