
// outsource dependencies
// import is from '../services/is.service';

// local dependencies
import BaseModel from './base.model';
// import { instanceAPI } from '../services/api.service';

/**
 *
 *
 * @constructor UserRoleModel
 * @type {UserRoleModel}
 * @public
 */
class UserRoleModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {


    }

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @override BaseModel
     * @private
     */
    static get baseRoute () {
        return '/roles';
    }

}

export default UserRoleModel;
