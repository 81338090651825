// outsource dependencies
import React from 'react';
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {Col, Grid, Row} from 'react-bootstrap';
import {CheckCircleOutline, HighlightOff} from '@material-ui/icons';
import {Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from '@material-ui/core';

// local dependencies
import {PERMISSIONS} from './types';
import Page from '../../components/page';
import Alert from '../../components/alert-error';
import {filters} from '../../components/filter';
import {DOWNLOAD_TYPES} from '../../constants/spec';
import {PrimaryBtn} from '../../components/md-button';
import Preloader, {TYPE} from '../../components/preloader';
import withDownloadLink from '../../components/download-link';

// configure

/**
 * Table wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.permissions }),
    dispatch => ({
        init: () => dispatch({type: PERMISSIONS.INITIALIZE}),
        uploadFile: (file, forceSynchronize) => dispatch({type: PERMISSIONS.UPLOAD_FILE, file, forceSynchronize}),
        clearError: () => dispatch({type: PERMISSIONS.META, errorMessage: null}),
    })
)(({ init, initialized, expectAnswer, errorMessage, clearError, uploadFile, uploadedFile, data }) => {

    return (
        <div style={{height: '100%', overflowY: 'auto'}}>
            <Page init={init} initialized={initialized}> <Grid fluid id="permissionsPage">
                <Row className="offset-bottom-4">
                    <Col xs={12} sm={6}> <h2 className="text-uppercase">
                        Permissions&nbsp;<Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                    </h2> </Col>
                    <Col xs={12} sm={6} className="text-right top-indent-4">
                        <DownloadBtn className="text-nowrap  offset-bottom-1" />
                        <PrimaryBtn
                            component="label"
                            htmlFor="fileUpload"
                            tooltip="Upload CSV"
                            className="text-nowrap offset-left-2 offset-bottom-1"
                        >
                            <i className="fa fa-upload" style={{fontSize: 20}} aria-hidden="true"> </i>
                            &nbsp;CSV
                            <input
                                type="file"
                                accept=".csv"
                                id="fileUpload"
                                value={uploadedFile}
                                style={{display: 'none'}}
                                onChange={e => uploadFile(e.target.files[0])}
                            />
                        </PrimaryBtn>

                        <PrimaryBtn
                            component="label"
                            htmlFor="fileUpload2"
                            tooltip="Upload CSV and Remove unused permissions"
                            className="text-nowrap offset-left-2 offset-bottom-1"
                        >
                            <i className="fa fa-upload" style={{fontSize: 20}} aria-hidden="true"> </i>
                            &nbsp;CSV and Sync
                            <input
                                type="file"
                                accept=".csv"
                                id="fileUpload2"
                                value={uploadedFile}
                                style={{display: 'none'}}
                                onChange={e => uploadFile(e.target.files[0], true)}
                            />
                        </PrimaryBtn>
                    </Col>
                </Row>
                <Row> <Col xs={12} md={8} mdOffset={2}> <Alert active message={errorMessage} onChange={clearError} /> </Col> </Row>
                <Row> <Col xs={12}>
                    { !isEmpty(data) ? <ConnectedTable />: <h3 className="text-uppercase text-center text-highlighted"> There is no permissions </h3> }
                </Col> </Row>
            </Grid> </Page>
        </div>
    )
});

const ConnectedTable = connect(
    state => ({
        data: state.permissions.data,
        selectedColumn: state.permissions.selectedColumn
    }),
    dispatch => ({
        selectColumn: (event) => dispatch({type: PERMISSIONS.META, selectedColumn: event}),
        selectRow: (event) => dispatch({type: PERMISSIONS.META, selectedRow: event}),
    })
)( ({data, selectColumn, selectedColumn, selectRow, selectedRow}) => {
    let {roles = [], rowMap = []} = data;
    console.log(selectedColumn);
    console.log(selectRow);
    return (<Paper className="table-wrap">
        <Table padding="checkbox" className="permission-table">
            <TableHead>
                <TableRow>
                    <TableCell className="table-header text-nowrap" style={{minWidth: 300, width: 300}}>
                        <strong> Permissions / Roles </strong>
                    </TableCell>
                    {roles.map((item, index) =>
                        <TableCell key={index} align="center" className="table-header" style={{minWidth: 100}}>
                            <Tooltip title={item.description ? item.description : item.name} placement="top-start">
                                <Button variant="text"
                                        style={{fontSize: 14}}
                                        onClick={(event) => selectColumn({index: index, item: item, selected: selectedColumn && selectedColumn.index === index && !selectedColumn.selected})}
                                >
                                    <strong>{item.name}</strong>
                                </Button>
                            </Tooltip>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {rowMap.map((row, index) => {
                    if (!row.delimiter) {
                        return ( <RowView key={index} {...row} selectedColumn={selectedColumn} /> )
                    } else {
                        return (
                            <TableRow key={index}>
                                <TableCell colSpan={100}>
                                    <strong className="group-title">{row.name}</strong>
                                </TableCell>
                            </TableRow>
                        )
                    }
                })}
            </TableBody>
        </Table>
    </Paper>);
});


/**
 * permission row component
 */
const RowView = connect(
    state => ({expectAnswer: state.permissions.expectAnswer}),
    dispatch =>  ({change: (role, permission) => dispatch({type: PERMISSIONS.CHANGE_PERMISSION, role, permission})})
)(({name, key, cols = [], selectedColumn, change, disabled}) => (<TableRow className="content-row">
    <TableCell><strong>{name}</strong></TableCell>
    {cols.map(({permission = {}, role = {}, enabled = false, editable = false}, index) => {
        let selectedStyle = selectedColumn && selectedColumn.index === index && !selectedColumn.selected ? {backgroundColor: '#f9f9f9'} : {};
        return (
            <Tooltip
                key={index}
                placement="top"
                enterDelay={200}
                title={role.name+ ': ' + filters.humanize(permission.name)}
            >
                <TableCell
                    align="center"
                    onClick={() => editable&&!disabled&&change(role, permission)}
                    className={"editable-cell" + (!editable || disabled ? ' disabled' : '')}
                    style={selectedStyle}
                >
                    { enabled ? (<CheckCircleOutline color="primary" />) : (<HighlightOff color="error" />) }
                </TableCell>
            </Tooltip>
        )
    })
    }
</TableRow>));


const DownloadBtn = withDownloadLink({downloadType: DOWNLOAD_TYPES.ADMIN_PERMISSIONS.CSV_LIST})(props => (
    <PrimaryBtn {...props} tooltip="Download data">
        <i className="fa fa-download" style={{fontSize: 20}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
));