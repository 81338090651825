
// outsource dependencies
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import { List as ListIcon, PlaylistAddCheck } from '@material-ui/icons';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Button } from '@material-ui/core';

// local dependencies
import { EDIT } from '../actions';
import { config } from '../../../constants';
import Preloader from '../../../components/preloader';
import { CancelBtn } from '../../../components/md-button';
import ErrorMessage from '../../../components/alert-error';
import {AUDIT_LOGS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

// config
export const DIFF_MODE = {
    ALL: 'ALL',
    MODIFIED: 'MODIFIED',
};

class Edit extends Component {
    componentDidMount() { this.props.initialize( this.props.match.params.id ); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { disabled, message, clearError, data } = this.props;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12}>
                            <Paper className="indent-5">
                                <Row className="offset-bottom-6">
                                    <Col xs={12}>
                                        <h2 className="text-uppercase">
                                            Audit logs details&nbsp;<Preloader expectAnswer={disabled} type="ICON" />
                                        </h2>
                                        <Breadcrumbs breadCrumbsMap={ AUDIT_LOGS_MAP }  />
                                    </Col>
                                </Row>
                                <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                                {isEmpty(data) ? (
                                    <h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>
                                ) : (
                                    <Row><Col xs={12}> <Info /> </Col></Row>
                                )}
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({
        data: state.auditLogs.edit.data,
        message: state.auditLogs.edit.errorMessage,
        disabled: state.auditLogs.edit.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({ type: EDIT.META, errorMessage: null})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.auditLogs.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const Info = connect(
    state => ({
        data: state.auditLogs.edit.data,
        diffMode: state.auditLogs.edit.diffMode,
        disabled: state.auditLogs.edit.expectAnswer,
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        changeMode: diffMode => dispatch({type: EDIT.CHANGE_MODE, diffMode})
    })
) (({data, cancel, changeMode, diffMode, disabled}) => {
    return (<Fragment>
        <Row><Col xs={12}>
            <h4><strong>Auditor name:</strong> {data.auditUserName}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Auditor email:</strong> {data.auditUserEmail}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Operation:</strong> {data.operationTypeInfo}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Item type:</strong> {get(data, 'itemTypeInfo.name')}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>Update data:</strong> {data.logDate && moment(data.logDate).format(config.clientTimeFormat)}</h4>
        </Col></Row>
        <Row className="offset-bottom-4"> <Col xs={12}>
            <h3 className="text-center text-uppercase">Diff</h3>
        </Col> </Row>
        <Row className="offset-bottom-4"> <Col xs={12} className="text-center">
            <div className="md-btn-group">
                <Tooltip title="All items"><span>
                    <Button
                        variant="contained"
                        disabled={disabled}
                        onClick={()=>changeMode(DIFF_MODE.ALL)}
                        className={diffMode===DIFF_MODE.ALL? 'md-btn active': 'md-btn'}
                            >
                        <ListIcon />
                    </Button>
                </span></Tooltip>
                <Tooltip title="Modified items"><span>
                    <Button
                        variant="contained"
                        disabled={disabled}
                        onClick={()=>changeMode(DIFF_MODE.MODIFIED)}
                        className={diffMode===DIFF_MODE.MODIFIED? 'md-btn active': 'md-btn'}
                            >
                        <PlaylistAddCheck />
                    </Button>
                </span></Tooltip>
            </div>
        </Col> </Row>
        <Row className="offset-bottom-8"><Col xs={12}><Diff /></Col></Row>
        <Row><Col xs={12} className="text-right"> <CancelBtn onClick={cancel} /> </Col></Row>
    </Fragment>)
});

const Diff = connect(
    state => ({ diff: state.auditLogs.edit.list}),
    null
) (({diff}) => {
    return (<Table className="md-table diff" padding="checkbox">
        <TableHead>
            <TableRow>
                <TableCell className="th"> Property </TableCell>
                <TableCell className="th text-nowrap"> Old value </TableCell>
                <TableCell className="th text-nowrap"> New value </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {diff.length ? null : (<TableRow><TableCell colSpan={100} className="cell">
                <h3 className="text-center text-uppercase">No changes</h3>
            </TableCell></TableRow>)}
            {diff.map((item, i) => item.delimiter ? (
                <TableRow key={i} className="delimiter">
                    <TableCell colSpan={100} className="cell"> <strong>{item.property}</strong> </TableCell>
                </TableRow>
            ) : (
                <TableRow key={i} className={item.status}>
                    <TableCell>{item.property}</TableCell>
                    <TableCell>{item.oldValue}</TableCell>
                    <TableCell>{item.newValue}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>);
});
