
// outsource dependencies
// import is from '../services/is.service';

// local dependencies
import BaseModel from './base.model';
import { instanceAPI } from '../services/api.service';

/**
 *
 *
 * @constructor DataTypeModel
 * @type {DataTypeModel}
 * @public
 */
class DataTypeModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {

    }

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @override BaseModel
     * @private
     */
    static get baseRoute () {
        return '/admin/data-type-classifications';
    }


    /**
     * get self data type
     *
     * @override BaseModel
     * @public
     */
    static getSelf () {
        return new Promise((resolve, reject) => {
            instanceAPI({ method: 'get', url: 'data-type-classifications/self' })
                .then(user => resolve( DataTypeModel.create(user)) )
                .catch(reject);
        });
    }

}

export default DataTypeModel;
