
export const ORGANIZATION_MAP = {
    '/private/organization/list': {id: 1, title: 'Organization', parent: null},
    '/private/organization/edit': {id: 11, title: 'Edit organization', parent: 1},
    '/private/organization/edit/new': {id: 12, title: 'Create organization', parent: 1},
};

export const USERS_MAP = {
    '/private/users/list': {id: 1, title: 'Users', parent: null},
    '/private/users/edit': {id: 12, title: 'Edit user', parent: 1},
    '/private/users/edit/new': {id: 13, title: 'Create user', parent: 1},
};

export const ROLES_MAP = {
    '/private/roles/list': {id: 1, title: 'Roles', parent: null},
    '/private/roles/edit': {id: 12, title: 'Edit role', parent: 1},
    '/private/roles/edit/new': {id: 13, title: 'Create role', parent: 1},
};

export const DATA_CLASSES_MAP = {
    '/private/data-classes/list': {id: 1, title: 'Data classes', parent: null},
    '/private/data-classes/edit': {id: 12, title: 'Edit data class', parent: 1},
    '/private/data-classes/edit/new': {id: 13, title: 'Create data class', parent: 1},
};
export const TECHNOLOGY_CATEGORIES_MAP = {
    '/private/technology-categories/list': {id: 1, title: 'Technology categories', parent: null},
    '/private/technology-categories/edit': {id: 12, title: 'Edit technology category', parent: 1},
    '/private/technology-categories/edit/new': {id: 13, title: 'Create technology category', parent: 1},
};
export const HINTS_MAP = {
    '/private/hints/list': {id: 1, title: 'Hints', parent: null},
    '/private/hints/edit': {id: 12, title: 'Edit hint', parent: 1},
    '/private/hints/edit/new': {id: 13, title: 'Create hint', parent: 1},
};

export const CACHE_METRICS_DATA_MAP = {
    '/private/cache-metrics-data/list': {id: 1, title: 'Cache metrics data', parent: null},
    '/private/cache-metrics-data/details': {id: 12, title: 'Cache metrics data details', parent: 1},
};

export const REGULATIONS_MAP = {
    '/private/regulations/list': {id: 1, title: 'Regulations', parent: null},
    '/private/regulations/edit': {id: 12, title: 'Edit regulation', parent: 1},
    '/private/regulations/edit/new': {id: 13, title: 'Create regulation', parent: 1},
};

export const ASSET_CLASSES_MAP = {
    '/private/asset-classes/list': {id: 1, title: 'Asset classes', parent: null},
    '/private/asset-classes/edit': {id: 12, title: 'Edit asset class', parent: 1},
    '/private/asset-classes/edit/new': {id: 13, title: 'Create asset class', parent: 1},
};

export const INDUSTRIES_MAP = {
    '/private/industries/list': {id: 1, title: 'Industries', parent: null},
    '/private/industries/edit': {id: 12, title: 'Edit industry', parent: 1},
    '/private/industries/edit/new': {id: 13, title: 'Create industry', parent: 1},
};

export const QLIK_ANALYTICS_MAP = {
    '/private/qlik-analytics/list': {id: 1, title: 'Qlik analytics', parent: null},
    '/private/qlik-analytics/edit': {id: 12, title: 'Edit qlik analytic', parent: 1},
    '/private/qlik-analytics/edit/new': {id: 13, title: 'Create qlik analytic', parent: 1},
};

export const AGREEMENTS_MAP = {
    '/private/agreements/list': {id: 1, title: 'Agreements', parent: null},
    '/private/agreements/edit': {id: 12, title: 'Edit agreement', parent: 1},
    '/private/agreements/edit/new': {id: 13, title: 'Create agreement', parent: 1},
};

export const LANGUAGES_MAP = {
    '/private/languages/list': {id: 1, title: 'Languages', parent: null},
    '/private/languages/edit': {id: 12, title: 'Edit language', parent: 1},
    '/private/languages/dictionary': {id: 13, title: 'Dictionary', parent: 1},
    // '/private/languages/dictionary': {id: 14, title: 'Dictionary עברית ', parent: 1},
    // '/private/languages/dictionary': {id: 15, title: 'Русский dictionary ', parent: 1},
};

export const AUDIT_LOGS_MAP = {
    '/private/audit-logs/list': {id: 1,  title: 'Audit logs', parent: null},
    '/private/audit-logs/details': {id: 11, title: 'Details', parent: 1},
};

export const QUANTIFICATION_MODELS_MAP = {
    '/private/quantification-models/list': {id: 1, title: 'Exposure metrics', parent: null},
    '/private/quantification-models/edit': {id: 12, title: 'Edit exposure metric', parent: 1},
    '/private/quantification-models/edit/new': {id: 13, title: 'Create exposure metric', parent: 1},
};
