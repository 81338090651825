// outsource dependencies
import React from 'react';
import {Grid} from "react-bootstrap";
import {Route} from 'react-router-dom';

// local dependencies
import {IMPORT_NEWS} from '../../constants/routes';

// nested pages
import List from './list';
import Import from './import';

export default function () {
    return (
        <Grid fluid>

            <Route path={IMPORT_NEWS.ROUTE} component={Import}/>

            <Route path={IMPORT_NEWS.ROUTE} component={List}/>

        </Grid>
    );
}