
// outsource dependencies
import { uniq, filter } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { arrayPush, arrayRemove } from 'redux-form';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../types';
import { history } from '../../../store';
import { UserModel } from '../../../models';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { changeField, FORM_NAME, idpIds } from './index';
import { forgotPassword } from '../../../services/api.service';

/**
 * connect all public sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.ADD_IDP_ID, addIdpIdSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.REMOVE_IDP_ID, removeIdpIdSaga);
    yield takeEvery(EDIT.RESET_PASSWORD, resetPasswordSaga);
    yield takeEvery(EDIT.RESET_TOTP, resetTOTPSaga);
    yield takeEvery(EDIT.CHANGE_PASSWORD, changePasswordSaga);
}


function * initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let result = yield call(getData, id);
        // NOTE prepare data for view
        let preparedData = prepareData(result);
        preparedData.useMultiFactorAuthForOrganization = preparedData && preparedData.organization && preparedData.organization.useMultiFactorAuth;
        yield put({type: EDIT.DATA, ...preparedData});
        // NOTE get available idp ids
        let idpUsers = (preparedData.idpUsers || []).map(i => i.idpId);
        let availableIdpIds = idpIds.filter(i => !idpUsers.includes(i));
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, ...params, availableIdpIds});

        // Change Organization Multi Factor Field
        // yield put(changeField("useMultiFactorAuthForOrganization", preparedData && preparedData.organization && preparedData.organization.useMultiFactorAuth));
        // yield put(changeField("useMultiFactorAuthForOrganization", true));

    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false, initialized: true});
}

function * updateDataSaga ( {type, id, ...data} ) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let result = yield call(updateData, id, data);
        // NOTE prepare data for view
        let preparedData = prepareData(result);
        yield put({type: EDIT.DATA, ...preparedData});
        // NOTE get available idp ids
        let idpUsers = (preparedData.idpUsers || []).map(i => i.idpId);
        let availableIdpIds = idpIds.filter(i => !idpUsers.includes(i));
        yield put({type: EDIT.META, availableIdpIds});
        if ( id === NEW_ID ) {
            // NOTE update url take id from results
            yield call(history.push, ROUTES.USERS.LINK_EDIT(result));
        }
        yield call(toastr.success, 'User', 'Data was successfully updated.');
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function * resetPasswordSaga () {
    // yield console.log(`%c updateDataSaga `, 'color: #fff; background: blue; font-size: 18px;'
    //     ,'\n data:', data
    // );
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let { email } = yield select(state => state.users.edit.data);
        // NOTE send reset request with user email
        yield call(forgotPassword, { email });
        yield call(toastr.success, 'Reset password', `Your password has been successfully reset. Email has been sent to the ${email}.`);
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function * resetTOTPSaga () {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let {id, email} = yield select(state => state.users.edit.data);
        yield call(UserModel.resetTOTP, {id});
        yield call(toastr.success, 'Reset TOTP', `TOTP settings for User has been successfully reset. Please try to login again as ${email}.`);
    } catch ( {message} ) {
        yield call(toastr.error, 'Error', message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function * changePasswordSaga () {
    yield put(changeField('passwordPlain', ''));
    yield put(changeField('confirmPassword', ''));
}

function* addIdpIdSaga ({idpId}) {
    yield put({type: EDIT.META, expectAnswer: true });
    let { availableIdpIds } = yield select(state => state.users.edit);
    // NOTE excluded added idp ids from available list
    availableIdpIds = availableIdpIds.filter(i => i !== idpId);
    // NOTE add item to form values with first empty userIdentity field
    yield put(arrayPush(FORM_NAME, 'idpUsers', {idpId, userIdentities: [{idpId, isNew: true}]}));
    yield put({type: EDIT.META, availableIdpIds, expectAnswer: false});
}

function* removeIdpIdSaga ({idpId, index}) {
    yield put({type: EDIT.META, expectAnswer: true });
    let { availableIdpIds } = yield select(state => state.users.edit);
    // NOTE added idp id to available list
    availableIdpIds = [...availableIdpIds, idpId];
    // NOTE remove item from form values
    yield put(arrayRemove(FORM_NAME, 'idpUsers', index));
    yield put({type: EDIT.META, availableIdpIds, expectAnswer: false});
}

/**
 *
 * @public
 */
function getData ( id ) {
    return id === NEW_ID ? UserModel.create({id: NEW_ID}) : UserModel.getById(id);
}
/**
 *
 * @param {String|Number} id
 * @param {Object} data
 * @public
 */
function updateData ( id, data = {} ) {
    // NOTE prepare data to request
    delete data.idpIds;
    let idpUsers = data.idpUsers || [], prepared = [];
    // NOTE convert idpUsers to basic structure with simple array with objects
    idpUsers = idpUsers.map(i=>i.userIdentities);
    for (let i = 0; i < idpUsers.length; i++ ) {
        prepared = prepared.concat(idpUsers[i]);
    }
    data.idpUsers = prepared;
    // NOTE "spread" break the model instance
    return UserModel.partiallyUpdate(id, data);
}

/**
 * helper to prepare user data for view
 * unite user identities in groups based on idpId
 *
 * @param {Object} data
 * @return {Object}
 * @private
 */
function prepareData(data) {
    let idpUsers = data.idpUsers || [], prepared = [];
    if (idpUsers.length) {
        // NOTE get all idpIds from all idpUsers
        let temp = idpUsers.map((item={}) => item.idpId);
        // NOTE rest only unique idpIds
        prepared = uniq(temp);
        // NOTE unite user identities in groups based on idpId
        prepared = prepared.map(item => {
            let temp={};
            temp.idpId = item;
            temp.userIdentities = filter(idpUsers, el => temp.idpId === el.idpId);
            return temp;
        });
    }
    data.idpUsers = prepared;
    return data;
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
