
// outsource dependencies
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// local dependencies
import { CACHE_METRICS_DATA } from '../../constants/routes';

// nested pages
import List from './list';
import Edit from './edit';

export default function ( props ) {
    let { location } = props;
    return (
        <Switch>
            <Route path={CACHE_METRICS_DATA.LIST} component={ List } />
            <Route path={CACHE_METRICS_DATA.EDIT} component={ Edit } />
            {/* OTHERWISE */}
            <Redirect to={{ pathname: CACHE_METRICS_DATA.LINK(), state: { from: location } }}/>
        </Switch>
    );
}
