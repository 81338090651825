
// outsource dependencies
import React from 'react';

// local dependencies
import is from '../services/is.service';

// configuration

/**
 * Input component renderer with helpers to display validation results
 * @example
 <Field
    required
    type="mail"
    name="email"
    placeholder="Email"
    component={ Input }
    className="form-control"
    label={ <span> @ </span> }
        />
 * @public
 */
export default function ( { input, meta, label, filter, skipTouch = false, ...attr } ) {
    filter = is.function(filter) ? filter : e => e;
    let message = '', status = '';
    if ( skipTouch || meta.touched ) {
        message = meta.error;
        status = meta.valid ? 'has-success' : 'has-error';
    }
    return (<div className={'form-group offset-bottom-1 '+status}>
        <label htmlFor={input.name} className="input-group"> {label} </label>
        <input
            id={input.name}
            autoComplete="off"
            className="form-control"
            {...input}
            {...attr}
            onChange={e => input.onChange(filter(e))}
                />
        <ErrorMessage htmlFor={input.name} message={message} className={status}/>
    </div>);
};

/**
 * Input error message
 *
 * @returns {string}
 * @private
 */
function ErrorMessage ( { message, htmlFor, className } ) {
    return !message ? '' : (<div className={'input-group '+className }>
        <label htmlFor={htmlFor} className="help-block offset-bottom-0"> { message } </label>
    </div>);
}
