
// outsource dependencies
import React from 'react';
import { Paper } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';

// local dependencies
import { BookLogo } from '../../images';
import CenteredBox from '../../components/centered-box';

export default function ( props ) {
    return (<CenteredBox>
        <Paper className="indent-10 text-center">
            <Row><Col className="offset-bottom-4" xs={12} xsOffset={0} md={12} mdOffset={0} lg={12} lgOffset={0}>
                <BookLogo style={{width: '100%', borderRadius: '15px'}}/>
            </Col></Row>
            <Row><Col xs={12}>
                <h1 className=""> Welcome to <span className="text-highlighted"> Risk-Q Administration</span> </h1>
            </Col></Row>
            <Row><Col xs={12}>
                <h3>Cyber Risk Management and Risk Engine</h3>
            </Col></Row>
        </Paper>
    </CenteredBox>)
}
