
// outsource dependencies
import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Card, CardHeader, CardContent, CardActions, Collapse } from '@material-ui/core';

// local dependencies
import { LIST } from '../actions';
import { history } from '../../../store';
import { config } from '../../../constants';
import Page from '../../../components/page';
import Preloader from '../../../components/preloader';
import {AUDIT_LOGS} from '../../../constants/routes';
import { SimpleRadio } from '../../../components/md-radio';
import ErrorMessage from '../../../components/alert-error';
import { instanceAPI } from '../../../services/api.service';
import { OrganizationModel, UserModel } from '../../../models';
import MdTablePagination from '../../../components/pagination';
import { SimpleAsyncSelect } from '../../../components/md-select';
import { SimpleDataPicker } from '../../../components/md-date-picker';
import {PrimaryBtn, InfoBtn, Link, CancelBtn, DetailsBtn} from '../../../components/md-button';
import {AUDIT_LOGS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

// config
export const DATE_RANGE = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This week',
    LAST_WEEK: 'Last week',
    THIS_MONTH: 'This month',
    LAST_MONTH: 'Last month',
    CHOOSE_DATE: 'Choose date',
};

export default connect(
    state => ({
        data: state.auditLogs.list.data,
        message: state.auditLogs.list.errorMessage,
        disabled: state.auditLogs.list.expectAnswer,
        initialized: state.auditLogs.list.initialized,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        init: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(({ disabled, data, message, clearError, init, initialized, clear }) => (
    <Page init={init} initialized={initialized} clear={clear}> <Grid fluid>
        <Row className="offset-bottom-4"> <Col xs={12}>
            <h2 className="text-uppercase">
                Audit Logs <Preloader expectAnswer={disabled} type="ICON" />
            </h2>
            <Breadcrumbs breadCrumbsMap={ AUDIT_LOGS_MAP }  />
        </Col> </Row>
        <Row className="offset-bottom-4"> <Col xs={12}> <FiltersPanelConnected /> </Col> </Row>
        <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError} /> </Col> </Row>
        { data.length ? (
            <Paper> <ConnectedTable /> </Paper>
        ) : (
            <h3 className="text-uppercase text-center text-highlighted"> There is no audit logs </h3>
        )}
    </Grid> </Page>
));

/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = connect(
    state => ({...state.auditLogs.list}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        openAdvanced: () => dispatch({type: LIST.META, showAdvanced: true}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        changeFilterValue: filter => dispatch({type: LIST.META, ...filter}),
        changeDate: dateRange => dispatch({type: LIST.CHANGE_DATE, dateRange}),
    })
)(({update, changeFilterValue, user, itemType, organization, expectAnswer, dateRange, dateFrom, dateTo, changeDate, showAdvanced, closeAdvanced, openAdvanced}) => (<div>
    <Row className="offset-bottom-4">
        <Col xs={12}>
            <Card style={{overflow: 'visible'}}>
                <CardHeader title="Search" />
                <CardContent style={{padding: '0 15px'}}>
                    <Row> <Col xs={12}> <SearchDescription /> </Col> </Row>
                    <Row>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <SimpleAsyncSelect
                                value={user}
                                isClearable={true}
                                placeholder="User"
                                labelKey="fullName"
                                disabled={expectAnswer}
                                label={(<strong> User </strong>)}
                                onChange={user => changeFilterValue({user})}
                                loadOptions={(name, done) => {
                                    UserModel.getListByName(name, 6).then(done).catch(()=> done([]));
                                }}/>
                        </Col>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <SimpleAsyncSelect
                                value={itemType}
                                isClearable={true}
                                disabled={expectAnswer}
                                placeholder="Item type"
                                label={(<strong> Item type </strong>)}
                                onChange={itemType => changeFilterValue({itemType})}
                                loadOptions={(name, done) => {
                                    instanceAPI({method: 'get', url: 'audit-logs/item-types'}).then(done).catch(done.bind(null, []));
                                }}/>
                        </Col>
                    </Row>
                </CardContent>
                <Collapse in={showAdvanced} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Row className="offset-bottom-4"> <Col xs={12}>
                            <SimpleAsyncSelect
                                isClearable={true}
                                value={organization}
                                disabled={expectAnswer}
                                placeholder="Organization"
                                label={(<strong> Organization </strong>)}
                                onChange={organization => changeFilterValue({organization})}
                                loadOptions={(name, done) => {
                                    OrganizationModel.getListByName(name, 6).then(done).catch(()=> done([]));
                                }}/>
                        </Col> </Row>
                        <Row><Col xs={12}>
                            <SimpleRadio
                                row
                                name="dateRange"
                                label="Date range"
                                value={dateRange}
                                disabled={expectAnswer}
                                options={Object.keys(DATE_RANGE).map(v=>({value: DATE_RANGE[v], label: DATE_RANGE[v]}))}
                                onChange={dateRange => changeDate(dateRange)}
                                    />
                        </Col></Row>
                        { dateRange !== DATE_RANGE.CHOOSE_DATE ? null : (<Row>
                            <Col xs={12} md={6} className="offset-bottom-2">
                                <SimpleDataPicker
                                    name="dateFrom"
                                    value={dateFrom}
                                    disabled={expectAnswer}
                                    label={(<strong> Date from </strong>)}
                                    onChange={dateFrom => changeFilterValue({dateFrom})}
                                        />
                            </Col>
                            <Col xs={12} md={6} className="offset-bottom-2">
                                <SimpleDataPicker
                                    name="dateTo"
                                    value={dateTo}
                                    disabled={expectAnswer}
                                    label={(<strong> Date to </strong>)}
                                    onChange={dateTo => changeFilterValue({dateTo})}
                                        />
                            </Col>
                        </Row>)}
                    </CardContent>
                </Collapse>
                <CardActions style={{justifyContent: 'flex-end'}}>
                    <InfoBtn tooltip="Apply" onClick={update} disabled={expectAnswer}> Apply </InfoBtn>
                    {showAdvanced ? (
                        <CancelBtn tooltip="Cancel advanced" onClick={closeAdvanced} disabled={expectAnswer}> Cancel </CancelBtn>
                    ) : (
                        <PrimaryBtn tooltip="Advanced search" onClick={openAdvanced} disabled={expectAnswer}> Advanced search </PrimaryBtn>
                    )}
                </CardActions>
            </Card>
        </Col>
    </Row>
</div>));

const SearchDescription = connect(state => ({...state.auditLogs.list}), null)(
    ({user, itemType, organization, dateFrom, dateTo, dateRange}) => (
        <h4 className="text-center capital-letter">
            {user && (<span>user <strong>{user.fullName}</strong> </span>)}
            {itemType ? (<span> changed item <strong>{itemType.name}</strong> </span>) : 'changed anything'}
            {organization && (<span> in organization <strong>{organization.name}</strong> </span>)}
            {dateRange !== DATE_RANGE.CHOOSE_DATE ? (<strong> {dateRange.toLowerCase()} </strong> ) : (<span>
                {dateFrom && (<span> from <strong>{moment(dateFrom).format(config.clientTimeFormat)}</strong> </span>)}
                {dateTo && (<span> till <strong>{moment(dateTo).format(config.clientTimeFormat)}</strong> </span>)}
            </span>)}
        </h4>
    )
);

const ConnectedTable = connect(
    state => ({...state.auditLogs.list}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead>
                <TableRow>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'auditUserName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('auditUserName')}
                                >
                            User Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> Operation Type </TableCell>
                    <TableCell className="th"> Item type </TableCell>
                    <TableCell className="th"> Updated </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'logDate'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('logDate')}
                                >
                            Updated Date
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow key={i}>
                    <TableCell>{item.auditUserName}</TableCell>
                    <TableCell>{item.operationTypeInfo}</TableCell>
                    <TableCell>{get(item, 'itemTypeInfo.name')}</TableCell>
                    <TableCell>{formatPreview(item)}</TableCell>
                    <TableCell className="text-nowrap">
                        {item.logDate && moment(item.logDate).format(config.clientTimeFormat+', h:mm:ss a')}
                    </TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={DetailsBtn}
                            tooltip="Details"
                            to={AUDIT_LOGS.LINK_EDIT({
                                id: item.id,
                                query: {back: history.location.pathname + history.location.search}
                            })}
                        />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>));

/**
 * format preview for updated item
 *
 * @public
 */
const formatPreview = (item={}) =>{
    let preview;
    let value = item.newValue ? item.newValue : item.oldValue;
    value=JSON.parse(value);
    let type = get(item, 'itemTypeInfo.itemType');
    switch (type) {
        default:
            preview = get(value, 'name');
            break;
        case 'USER':
        case 'USER_PASSWORD_CHANGED':
            preview = get(value, 'fullName', '');
            break;
        case 'SYSTEM_OWNER':
        case 'VENDOR_OWNER':
            preview = get(value, 'fullName') || get(value, 'owner.fullName', '');
            break;
        case 'QUALITATIVE_QUESTION':
            preview = get(value, 'question', '');
            break;
        case 'RISK_MODEL_DOMAIN':
            preview = get(value, 'riskDomainView.name', '');
            break;
        case 'VENDOR_ASSOCIATED_SYSTEMS':
        case 'QUALITATIVE_QUESTION_ANSWER_FOR_VENDOR':
            preview = get(value, 'vendor.name', '');
            break;
        case 'QUALITATIVE_QUESTION_ANSWER_FOR_SYSTEM':
            preview = get(value, 'system.name', '');
            break;
    }
    return preview;
};
