
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';

// local dependencies
import { EDIT } from '../types';
import Page from '../../../components/page';
import {DOWNLOAD_TYPES, NEW_ID} from '../../../constants/spec';
import Alert from '../../../components/alert-error';
import MdSwitch from '../../../components/md-switch';
import Preloader, { TYPE } from '../../../components/preloader';
import withDownloadLink from '../../../components/download-link';
import {BackBtn, SubmitBtn, ResetBtn, PrimaryBtn, WarningBtn} from '../../../components/md-button';
import {LANGUAGES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

// config
const FORM_NAME = 'languageEdit';

/**
 * Form wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.languages.edit }),
    dispatch => ({
        init: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null}),
    })
)(({ init, initialized, expectAnswer, errorMessage, clearError, match }) => {
    let isNew = match.params.id === NEW_ID;
    return (<Page init={()=>init(match.params.id)} initialized={initialized}>
        <Row className="top-indent-4 offset-bottom-2">
            <Col xs={12} md={8} mdOffset={2}>
                <Alert active message={errorMessage} onChange={clearError} />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12} md={10} mdOffset={1} lg={8} lgOffset={2}>
                <Paper className="indent-5">
                    <h2 className="offset-bottom-4">
                        {isNew ? 'Create' : 'Edit'}&nbsp;language&nbsp;
                        <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                    </h2>
                    <Breadcrumbs breadCrumbsMap={ LANGUAGES_MAP }  />

                    <ConnectedForm isNew={isNew} />
                </Paper>
            </Col>
        </Row>
    </Page>);
});

const ConnectedForm = connect(
    state => ({
        back: state.languages.edit.back,
        initialValues: state.languages.edit.data,
        disabled: state.languages.edit.expectAnswer,
        uploadedFile: state.languages.edit.uploadedFile,
        formValues: getFormValues(FORM_NAME)(state),
    }),
    dispatch => ({
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        uploadFile: (file, format) => dispatch({ type: EDIT.UPLOAD_FILE, file, format}),
    })
)(reduxForm({form: FORM_NAME, enableReinitialize: true})(
    ({ pristine, invalid, disabled, reset, handleSubmit, update, isNew, back, formValues={}, uploadedFile, uploadFile }) => (
        <form autoComplete="off" noValidate name="FORM_NAME" onSubmit={handleSubmit(update)}>
            <Row><Col xs={12}> <h4> <strong>Name:</strong> {formValues.name} </h4> </Col></Row>
            <Row><Col xs={12}> <h4> <strong>Code:</strong> {formValues.code} </h4> </Col></Row>
            <Row><Col xs={12}> <h4> <strong>Locale:</strong> {formValues.locale} </h4> </Col></Row>
            <Row className="offset-bottom-2">
                <Col xs={12} >
                    <Field
                        name="isPublic"
                        color="primary"
                        fullWidth={false}
                        component={MdSwitch}
                        disabled={disabled}
                        label={(<strong> Is public? </strong>)}
                            />
                </Col>
            </Row>
            <Row className="offset-bottom-8"><Col xs={12}>
                <h4> <strong>Dictionary:</strong> </h4>
                <div>
                    <DownloadBtn code={formValues.code} className="text-nowrap offset-right-2 offset-bottom-1" />
                    {/*<DownloadBtn*/}
                        {/*tooltip="Download CSV"*/}
                        {/*className="offset-right-2 offset-bottom-1"*/}
                        {/*link={() => DOWNLOAD.DICTIONARY.CSV_LINK({code: formValues.code})}*/}
                            {/*>*/}
                        {/*<i className="fa fa-file-text-o" aria-hidden="true" />&nbsp;Download CSV*/}
                    {/*</DownloadBtn>*/}
                    {/*<DownloadBtn*/}
                        {/*tooltip="Download JSON"*/}
                        {/*className="offset-right-2 offset-bottom-1"*/}
                        {/*link={() => DOWNLOAD.DICTIONARY.JSON_LINK({code: formValues.code})}*/}
                            {/*>*/}
                        {/*<i className="fa fa-file-text-o" aria-hidden="true" />&nbsp;Download JSON*/}
                    {/*</DownloadBtn>*/}
                    <WarningBtn
                        component="label"
                        htmlFor="fileUploadCSV"
                        tooltip="Upload CSV"
                        className="offset-right-2 offset-bottom-1"
                            >
                        <i className="fa fa-upload" aria-hidden="true" />&nbsp;Upload CSV
                        <input
                            type="file"
                            id="fileUploadCSV"
                            value={uploadedFile}
                            style={{display: 'none'}}
                            onChange={(e)=>uploadFile(e.target.files[0], 'csv')}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                    </WarningBtn>
                    {/*<WarningBtn*/}
                        {/*component="label"*/}
                        {/*htmlFor="fileUploadJSON"*/}
                        {/*tooltip="Upload JSON"*/}
                        {/*className="offset-right-2 offset-bottom-1"*/}
                            {/*>*/}
                        {/*<i className="fa fa-upload" aria-hidden="true" />&nbsp;Upload JSON*/}
                        {/*<input*/}
                            {/*type="file"*/}
                            {/*id="fileUploadJSON"*/}
                            {/*value={uploadedFile}*/}
                            {/*style={{display: 'none'}}*/}
                            {/*accept="application/JSON"*/}
                            {/*onChange={(e)=>uploadFile(e.target.files[0], 'json')}*/}
                                {/*/>*/}
                    {/*</WarningBtn>*/}
                </div>
            </Col></Row>
            <Row className="offset-bottom-4">
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={disabled||pristine||invalid} />
                    &nbsp;&nbsp;&nbsp;
                    <ResetBtn disabled={disabled||pristine} onClick={reset} />
                    &nbsp;&nbsp;&nbsp;
                    <BackBtn disabled={disabled} back={back} />
                </Col>
            </Row>
        </form>
    )
));

// const DownloadBtn = withDownloadLink()(props => (<PrimaryBtn {...props} />));

const DownloadBtn = withDownloadLink({downloadType: DOWNLOAD_TYPES.ADMIN_LANGUAGE_CONSTANTS.CSV_LIST})(props => (
    <PrimaryBtn {...props} tooltip="Download data">
        <i className="fa fa-file-text-o" aria-hidden="true" /> &nbsp; Download data
    </PrimaryBtn>
));