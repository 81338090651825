
// outsource dependencies
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as modal } from 'redux-modal';
import { reducer as toastr } from 'react-redux-toastr';
// local dependencies
import app from './app';
import publicLayout from '../public-layout/reducers';
import privateLayout from '../private-layout/reducers';
// configuration

// connect
let rootReducer = combineReducers({
    ...publicLayout,
    ...privateLayout,
    toastr,
    modal,
    form,
    app,
});
// export
export default rootReducer;
