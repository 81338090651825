
// outsource dependencies
import { put, call, takeEvery } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import is from "../../../services/is.service";
import {initial} from "./reducer";

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        let data = yield call(getData, id);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* cancelSaga () {
    // let { back } = yield select(state => state.cacheMetricsData.edit);
    // NOTE go back
    yield call(history.goBack);
}

/**
 * get data by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return instanceAPI({method: 'get', url: `/admin/cache-metrics-data/${id}`})
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back, page, size}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
        params.page = is.countable(page) ? Number(page) : initial.page;
        params.size = is.countable(size) ? Number(size) : initial.size;
    }
    return params;
}
