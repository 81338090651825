
// outsource dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

// local dependencies
import { PublicLogo } from '../../images';
import Input from '../../components/input';
import { PUBLIC } from '../../actions/types';
import CenteredBox from '../../components/centered-box';
import ErrorMessage from '../../components/alert-error';
import Preloader, { TYPE } from '../../components/preloader';
import { SIGN_IN, FORGOT_PASSWORD, PUBLIC_WELCOME } from '../../constants/routes';

// configuration

/**
 * Change Password using token from email notification
 *
 * @constructor ChangePassword
 * @public
 */
class ChangePassword extends Component {
    render () {
        let { invalid, handleSubmit, expectAnswer, changePassword, errorMessage, invalidToken, clearError } = this.props;
        return (<div id="publicPage">
            <CenteredBox>
                <div className="row">
                    <div className="col-xs-8 col-xs-offset-2 text-center">
                        <Link to={PUBLIC_WELCOME.LINK()}>
                            <PublicLogo />
                        </Link>
                    </div>
                </div>
                <div className="row offset-bottom-4">
                    <h1 className="col-xs-12 text-center">
                        <strong> Change password </strong>
                    </h1>
                </div>
                <div className="row">
                    <Preloader type="BOX" expectAnswer={expectAnswer}>
                        { invalidToken ? (
                            <h4 className="col-xs-12 text-justify offset-bottom-6">
                                Whoa there! The request token for this page is invalid.
                                It may have already been used, or expired because it is too old.
                                Please go back to the {' '}
                                <Link to={FORGOT_PASSWORD.LINK()} className="link">forgot password page</Link> {' '}
                                and try again.
                                <br/>
                            </h4>
                        ) : (
                            <div className="col-xs-12">
                                <form name="changePassword" onSubmit={ handleSubmit( changePassword ) }>
                                    <div className="offset-bottom-6">
                                        <Field
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            component={ Input }
                                            disabled={ expectAnswer }
                                            className="form-control input-lg"
                                            label={ <span className="h4"> Password </span> }
                                                />
                                    </div>
                                    <div className="offset-bottom-8">
                                        <Field
                                            type="password"
                                            component={ Input }
                                            name="confirmPassword"
                                            placeholder="Confirm"
                                            disabled={ expectAnswer }
                                            className="form-control input-lg"
                                            label={ <span className="h4"> Confirm password </span> }
                                                />
                                    </div>
                                    <button type="submit" disabled={invalid||expectAnswer} className="btn btn-lg btn-block btn-primary offset-bottom-8">
                                        Change&nbsp;password&nbsp;
                                        <Preloader type={TYPE.SPINNER} white expectAnswer={expectAnswer} />
                                    </button>
                                    <ErrorMessage active title={'Error:'} message={errorMessage} onChange={clearError}/>
                                </form>
                            </div>
                        )}
                    </Preloader>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-xs-offset-6 text-right text-uppercase">
                        <Link to={SIGN_IN.LINK()} className="link"> Sign In </Link>
                    </div>
                </div>
            </CenteredBox>
        </div>);
    }

    componentWillMount () { this.props.validateToken(this.props.match.params); }
}

export default reduxForm({
    form: 'changePassword',
    /**
     * @param { Object } values - named properties of input data
     * @param { Object } meta - information about form status
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: ( values, meta ) => {
        let errors = {};
        // PASSWORD
        if ( !values.password ) {
            errors.password = 'Password is required';
        } else if ( values.password.length < 8 ) {
            errors.password = 'Password must contain at least 8 symbol character';
        }
        // CONFIRM PASSWORD
        if ( !values.confirmPassword ) {
            errors.confirmPassword = 'Password confirmation is required';
        } else if ( values.confirmPassword !== values.password ) {
            errors.confirmPassword = 'Passwords confirmation do not match with password';
        }
        return errors;
    },
})( connect(
    state => ({ ...state.changePasswordPublic }),
    dispatch => ({
        changePassword: ({password}) => dispatch({type: PUBLIC.CHANGE_PASSWORD.REQUEST, password }),
        validateToken: ({token}) => dispatch({type: PUBLIC.VALIDATE_PASSWORD_TOKEN.REQUEST, token }),
        clearError: () => dispatch({type: PUBLIC.CHANGE_PASSWORD.CLEAR}),
    })
)(ChangePassword) );
