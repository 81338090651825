
// outsource dependencies
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// local dependencies
import is from '../services/is.service';
import Preloader, { TYPE } from './preloader';

/**
 * Component wrapper to make common preparation for pages and provide url params if it present
 *
 * @constructor
 * @public
 */
class Page extends Component {
    componentWillUnmount () { is.function(this.props.clear)&&this.props.clear(); }
    componentDidMount () { this.props.init(this.props.match ? this.props.match.params : {}); }
    render () {
        let { initialized, preloader = TYPE.MIN_HEIGHT, children } = this.props;
        return (<Preloader type={preloader} height={400} expectAnswer={!initialized} children={children} />);
    }
}

Page.propTypes = {
    clear: PropTypes.func,
    init: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired,
    preloader: PropTypes.oneOf( Object.keys(TYPE).map(key=>TYPE[key]) ),
};

export default Page;
