
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';

// local dependencies
import { EDIT } from '../types';
import Page from '../../../components/page';
import MdInput from '../../../components/md-input';
import Alert from '../../../components/alert-error';
import { NEW_ID, ENTITY_TYPE } from '../../../constants/spec';
import Preloader, { TYPE } from '../../../components/preloader';
import SelectEntities from '../../../components/select-entities';
import { BackBtn, SubmitBtn, ResetBtn } from '../../../components/md-button';
import {AGREEMENTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

/**
 * Form wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.agreements.edit }),
    dispatch => ({
        init: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null}),
    })
)(({ init, initialized, expectAnswer, errorMessage, clearError, match }) => {
    let isNew = match.params.id === NEW_ID;
    return (<Page init={()=>init(match.params.id)} initialized={initialized}>
        <Row className="top-indent-4 offset-bottom-2">
            <Col xs={12} md={8} mdOffset={2}>
                <Alert active message={errorMessage} onChange={clearError} />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12} md={10} mdOffset={1} lg={8} lgOffset={2}>
                <Paper className="indent-5">
                    <h2 className="offset-bottom-4">
                        {isNew ? 'Create' : 'Edit'}&nbsp;agreement&nbsp;
                        <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                    </h2>
                    <Breadcrumbs breadCrumbsMap={ AGREEMENTS_MAP }  />
                    <ConnectedForm isNew={isNew} />
                </Paper>
            </Col>
        </Row>
    </Page>);
});

const ConnectedForm = connect(
    state => ({
        back: state.agreements.edit.back,
        initialValues: state.agreements.edit.data,
        disabled: state.agreements.edit.expectAnswer,
    }),
    dispatch => ({
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'agreementEditForm',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @public
     */
    validate: values => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }
        return errors;
    }
})( ({ pristine, invalid, disabled, reset, handleSubmit, update, isNew, back }) => (
    <form autoComplete="off" noValidate name="userEditForm" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12} >
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    label={(<strong className="required-asterisk"> Name </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} >
                <Field
                    multiline
                    name="content"
                    component={MdInput}
                    disabled={disabled}
                    label={(<strong> Content </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    name="organizations"
                    disabled={disabled}
                    type={ENTITY_TYPE.ORGANIZATIONS}
                    label={(<strong className="required-asterisk"> Organizations </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={disabled||pristine||invalid} />
                &nbsp;&nbsp;&nbsp;
                <ResetBtn disabled={disabled||pristine} onClick={reset} />
                &nbsp;&nbsp;&nbsp;
                <BackBtn disabled={disabled} back={back} />
            </Col>
        </Row>
    </form>
)));
