// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {get, isEmpty} from 'lodash';

// local dependencies
import Preloader from './preloader';

/**
 * dropzone component
 *
 * @param {Object} props
 * @public
 */
const UploadFile = ({ data={}, disabled, loading, uploadFile }) => (

    <Dropzone
        multiple={false}
        className="dropzone"
        accept={{
            'text/csv': ['.csv'],
        }}
        disabled={disabled || loading}
        activeClassName="dropzone-active"
        disabledClassName="dropzone-disabled"
        useFsAccessApi={false}
        // ref={r => ref = r}
        onDrop={(acceptedFiles, rejectedFiles) => {
            if (!rejectedFiles.length) {
                return uploadFile(acceptedFiles[0]);
            }
        }}
    >
        {({rejectedFiles, getRootProps, getInputProps}) => {
            // NOTE expect answer
            if ( loading ) {
                return ( <Preloader expectAnswer={loading} type="MIN_HEIGHT" height={150} /> )
            }
            // NOTE invalid files
            if (rejectedFiles && rejectedFiles.length) {
                return (
                    <h3 className="text-center text-danger">
                        Allowed the upload of <strong>single</strong> file with <strong>.csv, .xlsx or .xlsm</strong> extension
                    </h3>
                )
            }
            // NOTE default message / upload answer
            return (
                <div>
                    { isEmpty(data) ? (
                        <div {...getRootProps()} className="text-center text-muted">
                            <input {...getInputProps()} />
                            <p style={{fontSize: '50px'}}><i className="fa fa-upload"/></p>
                            <h3 className="offset-top-0">Click here or drop files to upload!</h3>
                            <p> Allowed the upload of <strong>single</strong> file with <strong>.csv, .xlsx or .xlsm</strong> extension </p>
                        </div>
                    ) : (<Info data={data} />)}
                </div>
            )
        }}
    </Dropzone>
    //
    // <Dropzone
    //     multiple={false}
    //     className="dropzone"
    //     accept={{
    //         'text/csv': ['.csv'],
    //         'application/vnd.ms-excel': ['.xlsx', '.xls'],
    //     }}
    //     useFsAccessApi={false}
    //     disabled={disabled || loading}
    //     activeClassName="dropzone-active"
    //     disabledClassName="dropzone-disabled"
    //     onDrop={(acceptedFiles, rejectedFiles)=> {!rejectedFiles.length && uploadFile(acceptedFiles[0])}}
    //         >
    //     {({rejectedFiles, getRootProps, getInputProps})=>{
    //         // NOTE expect answer
    //         if ( loading ) {
    //             return ( <Preloader expectAnswer={loading} type="MIN_HEIGHT" height={150} /> )
    //         }
    //         // NOTE invalid files
    //         if ( rejectedFiles && rejectedFiles.length ) {
    //             return (
    //                 <h3 className="text-center text-danger">
    //                     Allowed the upload of <strong>single</strong> file with <strong>.csv, .xlsx or .xlsm</strong> extension
    //                 </h3>
    //             )
    //         }
    //         // NOTE default message / upload answer
    //         return (<div>
    //             { isEmpty(data) ? (
    //                 <div {...getRootProps()} className="text-center text-muted">
    //                     <input {...getInputProps()} />
    //                     <p style={{fontSize: '50px'}}> <i className="fa fa-upload" /> </p>
    //                     <h3 className="offset-top-0">Click here or drop files to upload!</h3>
    //                     <p> Allowed the upload of <strong>single</strong> file with <strong>.csv, .xlsx or .xlsm</strong> extension </p>
    //                 </div>
    //             ) : ( <Info data={data} />)}
    //         </div>)
    //     }}
    // </Dropzone>
);

UploadFile.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    data: PropTypes.object.isRequired,
    uploadFile: PropTypes.func.isRequired,
};

export default UploadFile;

/**
 * upload answer component
 *
 * @param {Object} props
 * @public
 */
const Info = ({ data }) => {
    let { status, ...arrays } = data;
    return (<div>
        <h3 className="text-center text-uppercase"> Upload file </h3>
        <ul>
            <li> <strong>Status</strong> { status ? status : 'UNKNOWN'} </li>
            {/*NOTE display data properties which are array*/}
            { Object.keys(arrays).filter(property => {
                return arrays[property] && Array.isArray(arrays[property]) && arrays[property].length > 0;
            }).map((property, index) => (
                <li key={index}>
                    <strong>{ property } </strong>
                    { !arrays[property] || !arrays[property].length ? "No changes" : (
                        <ul>{ arrays[property].map((item, key) => (<li key={key}>{get(item, 'name', item)}</li>)) }</ul>
                    )}
                </li>
            ))}
        </ul>
    </div>)
};
