
// outsource dependencies
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// local dependencies
import { DATA_CLASSES } from '../../constants/routes';

// nested pages
import List from './list';
import Edit from './edit';

export default function ( props ) {
    let { location } = props;
    return (
        <Switch>
            <Route path={DATA_CLASSES.LIST} component={ List } />
            <Route path={DATA_CLASSES.EDIT} component={ Edit } />
            {/* OTHERWISE */}
            <Redirect to={{ pathname: DATA_CLASSES.LINK(), state: { from: location } }}/>
        </Switch>
    );
}
