// outsource dependencies

// local dependencies
import {EDIT} from '../actions';
import {QLIK_ANALYTICS} from '../../../constants/routes';

let initial = {
    data: {},
    analyticsParameters: [],
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
    // previous path
    back: QLIK_ANALYTICS.LINK(),
};

export default function (state = initial, action) {
    //eslint-disable-next-line
    let {type, ...options} = action;
    switch (type) {
        default:
            break;
        case EDIT.CLEAR:
            state = initial;
            break;
        case EDIT.DATA:
            state = {...state, data: options.data};
            break;
        case EDIT.META:
            state = {...state, ...options, data: state.data};
            break;
    }

    return state;
}
