
// outsource dependencies
import { get } from 'lodash';

// local dependencies
import is from '../services/is.service';
import { NEW_ID } from '../constants/spec';
import storage from '../services/storage.service';
import queryService from '../services/query.service';
import { API_PATH, AUTH_STORE, ACCESS_TOKEN } from '../services/api.service';



export const PUBLIC = '/public';
export const PRIVATE = '/private';

/*-------------------------------------------------
        PUBLIC nested routes
---------------------------------------------------*/
export const PUBLIC_WELCOME = {
    LINK: linkTo.bind({url: ()=> (`/`)}),
    ROUTE: `/`,
    REGEXP: new RegExp('fake regex which never match'),
};

export const SIGN_IN = {
    LINK: linkTo.bind({url: ()=> (`${PUBLIC}/sign-in`)}),
    ROUTE: `${PUBLIC}/sign-in`,
    REGEXP: new RegExp(`${PUBLIC}/sign-in`, 'i'),
};

export const FORGOT_PASSWORD = {
    LINK: linkTo.bind({url: ()=> (`${PUBLIC}/forgot-password`)}),
    ROUTE: PUBLIC+'/forgot-password',
};

export const CHANGE_PASSWORD = {
    LINK: linkTo.bind({url: ({token}) => (`${PUBLIC}/change-password/${token}`)}),
    ROUTE: PUBLIC+'/change-password/:token',
    REGEXP: new RegExp(`${PUBLIC}/change-password/.*`, 'i'),
};

export const EMAIL_CONFIRMATION = {
    LINK: linkTo.bind({url: ({token}) => (`${PUBLIC}/email-confirmation/${token}`)}),
    ROUTE: PUBLIC+'/email-confirmation/:token',
    REGEXP: new RegExp(`${PUBLIC}/email-confirmation/.*`, 'i'),
};

/*-------------------------------------------------
        PRIVATE nested routes
---------------------------------------------------*/
export const PRIVATE_WELCOME_SCREEN = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/welcome`)}),
    ROUTE: PRIVATE+'/welcome',
    REGEXP: new RegExp(`${PRIVATE}/welcome`, 'i'),
};

export const PROFILE = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/profile`)}),
    ROUTE: PRIVATE+'/profile',
    REGEXP: new RegExp(`${PRIVATE}/profile.*`, 'i'),
};

export const PRIVATE_CHANGE_PASSWORD = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/change-password`)}),
    ROUTE: PRIVATE+'/change-password',
    REGEXP: new RegExp(`${PRIVATE}/change-password.*`, 'i'),
};

export const USERS = (function () {
    let ROUTE = `${PRIVATE}/users`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const ORGANIZATIONS = (function () {
    let ROUTE = `${PRIVATE}/organization`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const DATA_CLASSES = (function () {
    let ROUTE = `${PRIVATE}/data-classes`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const TECHNOLOGY_CATEGORIES = (function () {
    let ROUTE = `${PRIVATE}/technology-categories`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const HINTS = (function () {
    let ROUTE = `${PRIVATE}/hints`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const CACHE_METRICS_DATA = (function () {
    let ROUTE = `${PRIVATE}/cache-metrics-data`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/details/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/details/${id}`)}),
    };
})();

export const REGULATIONS = (function () {
    let ROUTE = `${PRIVATE}/regulations`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const QUANTIFICATION_MODELS = (function () {
    let ROUTE = `${PRIVATE}/quantification-models`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const ASSET_CLASSES = (function () {
    let ROUTE = `${PRIVATE}/asset-classes`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const IMPORT_NEWS = {
    REGEXP: new RegExp(`${PRIVATE}/import-news.*`, 'i'),
    ROUTE: `${PRIVATE}/import-news`,
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-news`)}),
};

export const IMPORT_PACE_COURSES = {
    REGEXP: new RegExp(`${PRIVATE}/import-pace-courses.*`, 'i'),
    ROUTE: `${PRIVATE}/import-pace-courses`,
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-pace-courses`)}),
};
export const IMPORT_WEBINARS = {
    REGEXP: new RegExp(`${PRIVATE}/import-webinars.*`, 'i'),
    ROUTE: `${PRIVATE}/import-webinars`,
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-webinars`)}),
};
export const IMPORT_WHATS_NEW = {
    REGEXP: new RegExp(`${PRIVATE}/import-whats-new.*`, 'i'),
    ROUTE: `${PRIVATE}/import-whats-new`,
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-whats-new`)}),
};
export const INDUSTRIES = (function () {
    let ROUTE = `${PRIVATE}/industries`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const QLIK_ANALYTICS = (function () {
    let ROUTE = `${PRIVATE}/qlik-analytics`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id/`,
        CLONE: `${ROUTE}/clone/:id/:cloneId`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
        LINK_CLONE: linkTo.bind({url: ({id = NEW_ID, cloneId}) => (`${ROUTE}/clone/${id}${cloneId ? `/${cloneId}` : ''}`)}),
    };
})();

export const ROLES = (function () {
    let ROUTE = `${PRIVATE}/roles`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const AUDIT_LOGS = (function () {
    const ROUTE = `${PRIVATE}/audit-logs`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/details/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${ROUTE}/details/${id}`)})
    };
})();

export const SETTINGS = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/settings`)}),
    ROUTE: PRIVATE+'/settings',
    REGEXP: new RegExp(`${PRIVATE}/settings.*`, 'i'),
};

export const PERMISSIONS = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/permissions`)}),
    ROUTE: PRIVATE+'/permissions',
    REGEXP: new RegExp(`${PRIVATE}/permissions.*`, 'i'),
};

export const AGREEMENTS = (function () {
    let ROUTE = `${PRIVATE}/agreements`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
    };
})();

export const LANGUAGES = (function () {
    let ROUTE = `${PRIVATE}/languages`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        DICTIONARY: `${ROUTE}/dictionary/:langId`,
        LINK: linkTo.bind({url: () => (`${ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID}) => (`${ROUTE}/edit/${id}`)}),
        LINK_DICTIONARY: linkTo.bind({url: ({langId}) => (`${ROUTE}/dictionary/${langId}`)}),
    };
})();

export const DOWNLOAD = {
    PERMISSIONS_CSV_LINK: linkTo.bind({url: () => (`${API_PATH}/admin/permissions/csv/export?token=${get(storage.get(AUTH_STORE), ACCESS_TOKEN)}`)}),
    HINTS_CSV_LINK: linkTo.bind({url: () => (`${API_PATH}/hints/csv/export?token=${get(storage.get(AUTH_STORE), ACCESS_TOKEN)}`)}),
    DICTIONARY: {
        CSV_LINK: linkTo.bind({url: ({code}) => (`${API_PATH}/admin/language-constants/csv/export/${code}?token=${get(storage.get(AUTH_STORE), ACCESS_TOKEN)}`)}),
        JSON_LINK: linkTo.bind({url: ({code}) => (`${API_PATH}/admin/language-constants/json/export/${code}?token=${get(storage.get(AUTH_STORE), ACCESS_TOKEN)}`)}),
    },
};

/**
 * @param { Object } options
 * @returns { String }
 * @function linkTo
 * @private
 */
function linkTo ( options={} ) {
    let opt = {}; // prepare options to setting in url
    let query = options.query || {};
    let search = queryService.format(query);
    for ( let key in options ) if ( is.string(options[key]) || is._number(options[key]) ) {
        opt[key] = encodeURIComponent(options[key]);
    }

    // config.DEBUG&& // log debug
    // console.log('%c linkTo => ( options ) ', 'color: #0747a6; font-weigth: bolder; font-size: 12px;'
    //     ,'\n routeData:', this
    //     ,'\n options:', options
    //     ,'\n formatted:', this.url(opt)
    // );

    return this.url(opt)+search;
}

