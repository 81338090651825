
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Paper} from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { Person } from '@material-ui/icons';
import { reduxForm, Field } from 'redux-form';

// local dependencies
import { EDIT } from './actions';
import { history } from '../../store';
import Page from '../../components/page';
import Input from '../../components/md-input';
import Alert from '../../components/alert-error';
import Preloader, {TYPE} from '../../components/preloader';
import { CancelBtn, SubmitBtn, ResetBtn } from '../../components/md-button';

export default connect(
    state => ({ ...state.profile }),
    dispatch => ({
        init: () => dispatch({type: EDIT.INITIALIZE}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null}),
    })
)(({ init, initialized, expectAnswer, errorMessage, clearError }) => {
    return (<Page init={()=>init()} initialized={initialized}>
        <Row className="top-indent-4 offset-bottom-2">
            <Col xs={12} md={6} mdOffset={3}>
                <Alert active message={errorMessage} onChange={clearError} />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12} md={6} mdOffset={3}>
                <Paper className="indent-5">
                    <h2 className="text-center offset-bottom-4">
                        <Person fontSize="large" className="align-middle"/>&nbsp;
                        <span className="align-middle">Edit profile</span>
                        <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                    </h2>
                    <ConnectedForm />
                </Paper>
            </Col>
        </Row>
    </Page>);
});

const ConnectedForm = connect(
    state => ({
        initialValues: state.profile.data,
        disabled: state.profile.expectAnswer
    }),
    dispatch => ({ update: formData => dispatch({type: EDIT.UPDATE, ...formData}) })
)(reduxForm({
    form: 'editProfile',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) { errors.name = 'Name is required.' }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset})=>(
    <form autoComplete="off" name="editProfile" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="firstName"
                    component={Input}
                    disabled={disabled}
                    placeholder="First Name"
                    label={(<strong className="required-asterisk"> First Name </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="lastName"
                    component={Input}
                    disabled={disabled}
                    placeholder="Last Name"
                    label={(<strong> Last Name </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    type="tel"
                    component={Input}
                    name="mobilePhone"
                    disabled={disabled}
                    placeholder="Mobile Phone"
                    label={(<strong> Mobile Phone </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn disabled={disabled||pristine||invalid} />
                &nbsp;&nbsp;&nbsp;
                <ResetBtn disabled={disabled||pristine} onClick={reset} />
                &nbsp;&nbsp;&nbsp;
                <CancelBtn disabled={disabled} onClick={() => history.goBack()} />
            </Col>
        </Row>
    </form>
)));
