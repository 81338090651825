
// outsource dependencies
import React from 'react';
import { Link } from 'react-router-dom';

// local dependencies
import { PublicLogo } from '../../images';
import { SIGN_IN } from '../../constants/routes';
import CenteredBox from '../../components/centered-box';

/**
 * Welcome page available for unauthorized users
 * @constructor PublicWelcomePage
 * @public
 */
export default function () {
    return (<div id="publicPage">
        <CenteredBox className="content-wrap">
            <div className="row offset-bottom-2">
                <div className="col-xs-10 col-xs-offset-1 text-center">
                    <PublicLogo />
                </div>
            </div>
            <div className="row">
                <h2 className="col-xs-12 text-center">
                    It's nice to see you here!
                </h2>
            </div>
            <div className="row">
                <h3 className="col-xs-12 text-center">
                    <Link to={SIGN_IN.LINK()} className="link text-uppercase"> Login </Link>
                </h3>
            </div>
        </CenteredBox>
    </div>);
}
