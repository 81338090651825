// outsource dependencies
import {put, call, takeEvery, select} from 'redux-saga/effects';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import {instanceAPI} from '../../../services/api.service';
import queryService from '../../../services/query.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
}

function* initializeSaga({id, cloneId}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id, cloneId);
        let analyticsParameters = yield call(getAnalyticsParameters);
        yield put({type: EDIT.META, analyticsParameters: analyticsParameters});
        // yield put({type: EDIT.META, dynamicParameters: Object.keys(QLIK)});

        // NOTE default value "externalAnalyticsType" without changing
        // data.externalAnalyticsType = "QLIK";

        // NOTE
        if (data.externalAnalyticsParameters) data.externalAnalyticsParameters.forEach(param => data[param.name] = param.value);

        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    try {
        options.externalAnalyticsParameters = [];
        // let stateData = yield select(state => state.qlikAnalytics.edit);
        const { analyticsParameters } = yield select(state => state.qlikAnalytics.edit);

        if (analyticsParameters[options.externalAnalyticsType]) {
            Object.keys(analyticsParameters[options.externalAnalyticsType]).forEach(name => {
                options[name] && options.externalAnalyticsParameters.push({name: name, value: options[name]});
                delete options[name];
            });
        }

        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}


function* cancelSaga() {
    let {back} = yield select(state => state.qlikAnalytics.edit);
    // NOTE go back
    yield call(history.push, back);
}


/**
 * get qlik analytic by id
 * @param {Number|String} id
 * @param {Number|String} cloneId
 * @private
 */
function getData(id, cloneId) {
    return new Promise((resolve, reject) => {
        // NOTE adding new entity
        if (!(id !== NEW_ID || cloneId) || cloneId === 0) return resolve({});
        // NOTE get entity data for cloning existing analytic
        if(cloneId || cloneId === 0) return instanceAPI({method: 'get', url: `/external-analytics/${cloneId}`}).then( (data) => { delete data.id; resolve(data)}).catch(reject);
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/external-analytics/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * get qlik analytics parameters
 *
 * @private
 */
function getAnalyticsParameters() {
    return new Promise((resolve, reject) => {
        // NOTE get qlik analytics parameters
        instanceAPI({method: 'get', url: `/external-analytics-parameters/defaults`}).then(resolve).catch(reject);
    });
}

/**
 * update qlik analytic
 * @param {Object} data
 * @private
 */
function updateData(data) {
    return new Promise((resolve, reject) => {
        let promise;
        if (!data.id || data.id === NEW_ID) { // CREATE
            promise = instanceAPI({method: 'post', url: `/external-analytics`, data});
        } else { // UPDATE
            promise = instanceAPI({method: 'put', url: `/external-analytics`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}

