
import React from 'react';
import {history} from "../../store";
import { PRIVATE_WELCOME_SCREEN } from '../../constants/routes';


const breadcrumb = {
    padding: 6,
    marginBottom: 0,
    backgroundColor: 'transparent',
};

function buildMap(url, breadCrumbsMap, result=[]) {

    if(!breadCrumbsMap[url]) return result;
    let item = breadCrumbsMap[url];
    item.url = url;

    if(item) {
        result[item.id] = item;
        if(item.parent) {
            for(let url in breadCrumbsMap) {
                if(breadCrumbsMap[url].id === item.parent) buildMap(url, breadCrumbsMap, result);
            }
        }
    }
    return result;
}

function Breadcrumbs(props) {

    const { breadCrumbsMap } = props;
    const url = history.location.pathname.replace(/\d.*/g, '').replace(/[/]$/, '');
    const list = buildMap(url, breadCrumbsMap);

    return <BreadCrumbsLayout breadcrumbsList={list}/>
}

function BreadCrumbsLayout({breadcrumbsList}) {

    function isLast(index) {
        return index === breadcrumbsList.length - 1;
    }

    return (
        <nav className="row justify-content-center">
            <ol style={breadcrumb} className="breadcrumb" >
                <li className="breadcrumb-item align-items-center">
                    <button className={ `btn btn-link` } onClick={ () => history.push(PRIVATE_WELCOME_SCREEN.ROUTE) }>
                        { 'Home page' }
                    </button>
                </li>
                {
                    breadcrumbsList.map((crumb, ci) => {
                        const disabled = isLast(ci);

                        return (
                            <li
                                key={ ci }
                                className="breadcrumb-item align-items-center"
                            >
                                <button disabled={disabled} className={ `btn btn-link` } onClick={ () => history.push(crumb.url) }>
                                    { crumb.title }
                                </button>
                            </li>
                        );
                    })
                }
            </ol>
        </nav>
    );
}

export default Breadcrumbs;
