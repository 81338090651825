
// outsource dependencies
import React from 'react';
import { FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core';

// local dependencies
import is from '../services/is.service';

// configuration

/**
 * Input component renderer with helpers to display validation results
 * @example
 <Field
    type="mail"
    name="email"
    placeholder="Email"
    component={ Input }
    className="form-control"
    label={ <span> @ </span> }
        />
 * @public
 */
export default function ({ input, meta, label, filter, helpText = '', skipTouch = false, ...attr }) {
    filter = is.function(filter) ? filter : e => e;
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = meta.error;
    }
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '' : (<InputLabel htmlFor={input.name}>{label}</InputLabel>)}
        <Input
            autoComplete="off"
            id={input.name}
            {...input}
            {...attr}
            onChange={e => input.onChange(filter(e))}
                />
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
};
