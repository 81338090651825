
// outsource dependencies
import React from 'react';
import { NavLink } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

// local dependencies
import * as ROUTES from './constants/routes';

// configuration
export default NoMatch;

// TODO developer component to test router features
function NoMatch ( props )  {

        return (
            <div className="container-fluid">
                <div className="row">
                    <h3 className="col-xs-10 col-xs-offset-1 text-center top-indent-4">
                        No Match page for&nbsp;
                        <code> { props.location.pathname } </code>
                    </h3>
                </div>
                <div className="row">
                    <h3 className="col-xs-10 col-xs-offset-1 text-center top-indent-4">
                        Props
                        <hr/>
                        <code> { JSON.stringify(props) } </code>
                        <hr/>
                    </h3>
                </div>
                <div className="row">
                    <div className="col-xs-2">
                        <NavLink to={'/qwe'} activeClassName={'active'} className="btn btn-block btn-default">
                            1
                        </NavLink>
                    </div>
                    <div className="col-xs-2">
                        <button className="btn btn-block btn-primary">
                            2
                        </button>
                    </div>
                    <div className="col-xs-2">
                        <button className="btn btn-block btn-warning">
                            3
                        </button>
                    </div>
                    <div className="col-xs-2">
                        <button className="btn btn-block btn-danger">
                           4
                        </button>
                    </div>
                    <div className="col-xs-2">
                        <button className="btn btn-block btn-info">
                            5
                        </button>
                    </div>
                    <div className="col-xs-2">
                        <button className="btn btn-block btn-success">
                            6
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="h3 col-xs-12">
                        Private
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-2">
                        <LinkContainer exact={true} to={ROUTES.PRIVATE_WELCOME_SCREEN.LINK()}>
                            <button className="btn btn-block btn-default">
                                WELCOME_SCREEN
                            </button>
                        </LinkContainer>
                    </div>
                    <div className="col-xs-2">
                        <LinkContainer to={ROUTES.SIGN_IN.LINK()}>
                            <button className="btn btn-block btn-primary">
                                <i className="fa fa-sign-in" aria-hidden="true"> </i>
                                &nbsp;SIGN IN
                            </button>
                        </LinkContainer>
                    </div>
                    <div className="col-xs-2">
                        <LinkContainer to={ROUTES.FORGOT_PASSWORD.LINK()}>
                            <button className="btn btn-block btn-warning">
                                <i className="fa fa-ban" aria-hidden="true"> </i>
                                &nbsp;FORGOT PASSWORD
                            </button>
                        </LinkContainer>
                    </div>
                    <div className="col-xs-2">
                        <LinkContainer to={ROUTES.CHANGE_PASSWORD.LINK({token: 'test-token'})}>
                            <button className="btn btn-block btn-danger">
                                CHANGE PASSWORD
                            </button>
                        </LinkContainer>
                    </div>
                    <div className="col-xs-2">
                        <LinkContainer to={ROUTES.EMAIL_CONFIRMATION.LINK({token: 'test-token'})}>
                            <button className="btn btn-block btn-info">
                                EMAIL CONFIRMATION
                            </button>
                        </LinkContainer>
                    </div>
                    <div className="col-xs-2">

                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3">
                        <h1>1</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3">
                        <h1>2</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3">
                        <h1>3</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3">
                        <h1>4</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3">
                        <h1>5</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3">
                        <h1>6</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3">
                        <h1>7</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3">
                        <h1>8</h1>
                    </div>
                </div>
            </div>
        );
}

