
// outsource dependencies
import React from 'react';

// local dependencies
import logo from './logo.png';
import defImg from './def-image.svg';
import HebFlag from '../images/heb.png';
import RusFlag from '../images/rus.png';
import LcdFlag from '../images/lcd.png';
import EngFlag from '../images/eng.png';
import fullLogo from './full_logo.svg';
import settingsGif from './settings.gif';
import spinnerRed from './spinner-red.svg';
import bookLogoImage from './book_logo.jpg';
import cloudNormal from './cloud_normal.svg';
import spinnerBlack from './spinner-black.svg';
import spinnerWhite from './spinner-white.svg';
import publicLogoImage from './public_logo.svg';
import defaultAvatar from './default_avatar.svg';
import additionalPublicLogoImage from './valurisq-logo.svg';
import maintenance from './down_for_maintenance.png';


export function selectImageSrc(language) {
    let languageSrc;
    switch (language) {
        default:
            languageSrc = LcdFlag;
            break;
        case 'eng':
            languageSrc = EngFlag;
            break;
        case 'heb':
            languageSrc = HebFlag;
            break;
        case 'rus':
            languageSrc = RusFlag;
            break;
    }
    return languageSrc;
}

/**
 * Wrapped logo image from book cover
 * @public
 */
export function BookLogo ( props ) {
    return ( <img
            src={bookLogoImage}
            alt="RISK-Q ADMINISTRATION Logo"
            style={{
                opacity: .85,
                maxWidth: '100%',
                borderRadius: '0',
                boxShadow: 'rgb(8, 28, 61) 0px 0px 30px 20px',
            }}
            {...props}
        />
    );
}

/**
 * Wrapped logo image
 * @public
 */
export function Logo ({ full, ...attr }) {
    return (<img src={full ? fullLogo : logo} {...attr} alt={attr.alt||'RISK-Q Administration'} />);
}

/**
 * Wrapped additional public logo image
 * @public
 */
export function AdditionalPublicLogo ( props ) {
    return ( <img src={additionalPublicLogoImage} {...props} alt={props.alt||'Risk-Q Administration'} /> );
}

/**
 * Wrapped public logo image
 * @public
 */
export function PublicLogo ( props ) {
    return ( <img src={publicLogoImage} {...props} alt={props.alt||'Risk-Q Administration'} /> );
}


/**
 * Wrapped default avatar
 * @public
 */
export function DefAvatar ( props ) {
    return (<img style={{'borderRadius': '50%'}} {...props} src={defaultAvatar} alt={props.alt||'User'} />);
}

/**
 * Wrapped Avatar with default image
 * @public
 */
export function Avatar ( props ) {
    return (props.src ? <img {...props} style={{'borderRadius': '50%'}} alt={props.alt} /> : <DefAvatar {...props} />);
}

/**
 * Wrapped spinners
 * @public
 */
export function Spinner ({ alt = 'Loading', black, white, ...attr }) {
    if (white) return (<img title={alt} alt={alt} {...attr} src={spinnerWhite} />);
    if (black) return (<img title={alt} alt={alt} {...attr} src={spinnerBlack} />);
    return (<img title={alt} alt={alt} {...attr} src={spinnerRed} />);
}

/**
 *
 * @public
 */
export function DefImg ( { src = defImg, alt = 'Absent image', style, ...attr} ) {
    return (<img title={alt} {...attr} style={{width: '100%', ...style}} src={src} alt={alt} />);
}

/**
 *
 * @public
 */
export function CloudImage ( props ) {
    return (<img src={cloudNormal} {...props} alt={props.alt||'Upload to cloud'} />);
}

/**
 *
 * @public
 */
export function SettingGif ( props ) {
    return (<img src={settingsGif} {...props} alt={props.alt||'Settings'} />);
}

/**
 *
 * @public
 */
export function Maintenance ( props ) {
    return (<img src={maintenance} {...props} alt={props.alt||'Down for maintenance'} />);
}
