// outsource dependencies
import {toastr} from 'react-redux-toastr';
import {call, put, takeEvery} from 'redux-saga/effects';

// local dependencies
import {ACTIONS, LIST} from '../actions';
import {instanceAPI} from '../../../services/api.service';


/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(ACTIONS.UPLOAD_FILE, uploadFileSaga);
}
function* uploadFileSaga ({file}) {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null });
    // NOTE clean previous data
    yield put({type: ACTIONS.DATA, data: {} });
    // NOTE get current risk model
    try {
        let data = yield call(uploadFile, file);
        yield put({type: ACTIONS.DATA, data});
        yield call(toastr.success, 'Data import', 'File was successfully imported');
        yield put({type: ACTIONS.META, expectAnswer: false});
        yield put({type: LIST.INITIALIZE, expectAnswer: false});
    }
    catch ({message}) {
        yield call(toastr.error, 'Error', message);
        yield put({type: ACTIONS.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * upload file
 *
 * @param {File} file
 * @private
 */
function uploadFile ( file ) {
    const data = new FormData();
    data.append('file', file);
    return instanceAPI({method: 'post', url: '/admin/feeds/news/import', data: data});
}