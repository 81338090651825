
// outsource dependencies

// local dependencies
import BaseModel from './base.model';

/**
 *
 *
 * @constructor AgreementModel
 * @type {AgreementModel}
 * @public
 */
class AgreementModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {

    }

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @override BaseModel
     * @private
     */
    static get baseRoute () {
        return '/organization-agreement';
    }

}

export default AgreementModel;
