
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

// local dependencies
import Layout from './layout';
import { config } from '../constants';
import * as ROUTES from '../constants/routes';
import { MENU_ITEM_TYPE } from '../constants/spec';

// nested pages
import UsersPage from './users';
import Profile from './profile';
import RolesPage from './roles';
import NoMatch from '../no-match';
import Languages from './languages';
import WelcomePage from './welcome';
import AuditLogs from './audit-logs';
import DataClasses from './data-classes';
import Hints from './hints';
import ImportNews from './import-news';
import ImportPaceCourses from './import-pace-courses';
import ImportWebinars from './import-webinars';
import ImportWhatsNew from './import-whats-new';
import Industries from './industries';
import CacheMetricsData from './cache-metrics-data';
import Regulations from './regulations';
import AssetClasses from './asset-classes';
import QlikAnalytics from './qlik-analytics';
import SettingsPage from './settings';
import Agreements from './agreements';
import Permissions from './permissions';
import ChangePassword from './change-password';
import OrganizationsPage from './organizations';
import QuantificationModels from './quantification-models';
import TechnologyCategories from './technology-categories';

// Export
export default connect(state => ({ auth: state.app.auth }), null)(PrivateLayout);

/**
 * list of nested pages for private layout
 *
 * @constructor PrivateLayout
 * @public
 */
function PrivateLayout ({ location, auth }) {
    // NOTE redirect for unauthorized users
    if ( !auth ) return (<Redirect to={{ pathname: ROUTES.SIGN_IN.ROUTE, state: { from: location } }}/>);
    // NOTE Authorized users layout
    return (<Layout menu={MENU}>
        <Switch>
            <Route path={ROUTES.AGREEMENTS.ROUTE} component={ Agreements } />
            <Route path={ROUTES.ASSET_CLASSES.ROUTE} component={ AssetClasses } />
            <Route path={ROUTES.AUDIT_LOGS.ROUTE} component={ AuditLogs } />
            <Route path={ROUTES.CACHE_METRICS_DATA.ROUTE} component={ CacheMetricsData } />
            <Route path={ROUTES.DATA_CLASSES.ROUTE} component={ DataClasses } />
            <Route path={ROUTES.HINTS.ROUTE} component={ Hints } />
            <Route path={ROUTES.IMPORT_NEWS.ROUTE} component={ ImportNews } />
            <Route path={ROUTES.IMPORT_PACE_COURSES.ROUTE} component={ ImportPaceCourses } />
            <Route path={ROUTES.IMPORT_WEBINARS.ROUTE} component={ ImportWebinars } />
            <Route path={ROUTES.IMPORT_WHATS_NEW.ROUTE} component={ ImportWhatsNew } />
            <Route path={ROUTES.INDUSTRIES.ROUTE} component={ Industries } />
            <Route path={ROUTES.LANGUAGES.ROUTE} component={ Languages } />
            <Route path={ROUTES.ORGANIZATIONS.ROUTE} component={ OrganizationsPage } />
            <Route path={ROUTES.PERMISSIONS.ROUTE} component={ Permissions } />
            <Route path={ROUTES.PRIVATE_CHANGE_PASSWORD.ROUTE} component={ ChangePassword } />
            <Route path={ROUTES.PRIVATE_WELCOME_SCREEN.ROUTE} component={ WelcomePage } />
            <Route path={ROUTES.PROFILE.ROUTE} component={ Profile } />
            <Route path={ROUTES.QLIK_ANALYTICS.ROUTE} component={ QlikAnalytics } />
            <Route path={ROUTES.QUANTIFICATION_MODELS.ROUTE} component={ QuantificationModels } />
            <Route path={ROUTES.REGULATIONS.ROUTE} component={ Regulations } />
            <Route path={ROUTES.ROLES.ROUTE} component={ RolesPage } />
            <Route path={ROUTES.SETTINGS.ROUTE} component={ SettingsPage } />
            <Route path={ROUTES.TECHNOLOGY_CATEGORIES.ROUTE} component={ TechnologyCategories } />
            <Route path={ROUTES.USERS.ROUTE} component={ UsersPage } />
            {/* OTHERWISE */}
            { !config.production ? (
                <Route component={ NoMatch } />
            ) : (// TODO on otherwise should redirect
                <Redirect to={{ pathname: ROUTES.PRIVATE_WELCOME_SCREEN.LINK(), state: { from: location } }}/>
            )}
        </Switch>
    </Layout>);
}
// Side Menu
let MENU = [
    {
        type: MENU_ITEM_TYPE.MENU,
        name: 'App Administration',
        list: [{
            type: MENU_ITEM_TYPE.LINK,
            name: 'Organizations',
            icon: 'fa fa-sitemap',
            link: ROUTES.ORGANIZATIONS.LINK(),
            isActive: (s, location) => ROUTES.ORGANIZATIONS.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Users',
            icon: 'fa fa-users',
            link: ROUTES.USERS.LINK(),
            isActive: (s, location) => ROUTES.USERS.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Roles',
            icon: 'fa fa-id-badge',
            link: ROUTES.ROLES.LINK(),
            isActive: (s, location) => ROUTES.ROLES.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Hints',
            icon: 'fa fa-commenting-o',
            link: ROUTES.HINTS.LINK(),
            isActive: (s, location) => ROUTES.HINTS.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Permissions',
            icon: 'fa fa-calendar-check-o',
            link: ROUTES.PERMISSIONS.LINK(),
            isActive: (s, location) => ROUTES.PERMISSIONS.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Languages',
            icon: 'fa fa-language',
            link: ROUTES.LANGUAGES.LINK(),
            isActive: (s, location) => ROUTES.LANGUAGES.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Audit logs',
            icon: 'fa fa-book',
            link: ROUTES.AUDIT_LOGS.LINK(),
            isActive: (s, location) => ROUTES.AUDIT_LOGS.REGEXP.test(location.pathname)
        }, ]
    }, {
        type: MENU_ITEM_TYPE.MENU,
        name: 'Management',
        list: [{
            type: MENU_ITEM_TYPE.LINK,
            name: 'Industries',
            icon: 'fa fa-building',
            link: ROUTES.INDUSTRIES.LINK(),
            isActive: (s, location) => ROUTES.INDUSTRIES.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Agreements',
            icon: 'fa fa-clipboard',
            link: ROUTES.AGREEMENTS.LINK(),
            isActive: (s, location) => ROUTES.AGREEMENTS.REGEXP.test(location.pathname)
        }]
    }, {
        type: MENU_ITEM_TYPE.MENU,
        name: 'Risk Model',
        list: [{
            type: MENU_ITEM_TYPE.LINK,
            name: 'Regulations',
            icon: 'fa fa-file-text-o',
            link: ROUTES.REGULATIONS.LINK(),
            isActive: (s, location) => ROUTES.REGULATIONS.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Asset classes',
            icon: 'fa fa-files-o',
            link: ROUTES.ASSET_CLASSES.LINK(),
            isActive: (s, location) => ROUTES.ASSET_CLASSES.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Data classes',
            icon: 'fa fa-database',
            link: ROUTES.DATA_CLASSES.LINK(),
            isActive: (s, location) => ROUTES.DATA_CLASSES.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Exposure models',
            icon: 'fa fa-cubes',
            link: ROUTES.QUANTIFICATION_MODELS.LINK(),
            isActive: (s, location) => ROUTES.QUANTIFICATION_MODELS.REGEXP.test(location.pathname)
        }, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Technology Types',
            icon: 'fa fa-th-large',
            link: ROUTES.TECHNOLOGY_CATEGORIES.LINK(),
            isActive: (s, location) => ROUTES.TECHNOLOGY_CATEGORIES.REGEXP.test(location.pathname)
        }]
    }, {
        type: MENU_ITEM_TYPE.MENU,
        name: 'Analytics',
        list: [{
            type: MENU_ITEM_TYPE.LINK,
            name: 'Analytic Reports',
            icon: 'fa fa-area-chart',
            link: ROUTES.QLIK_ANALYTICS.LINK(),
            isActive: (s, location) => ROUTES.QLIK_ANALYTICS.REGEXP.test(location.pathname)
        },  {
            type: MENU_ITEM_TYPE.LINK,
            name: 'Cache Metrics Data',
            icon: 'fa fa-cloud',
            link: ROUTES.CACHE_METRICS_DATA.LINK(),
            isActive: (s, location) => ROUTES.CACHE_METRICS_DATA.REGEXP.test(location.pathname)
        }]
    }, {
        type: MENU_ITEM_TYPE.MENU,
        name: 'Import & Export',
        list: [
            {
                type: MENU_ITEM_TYPE.LINK,
                name: 'Import News',
                icon: 'fa fa-newspaper-o',
                link: ROUTES.IMPORT_NEWS.LINK(),
                isActive: (s, location) => ROUTES.IMPORT_NEWS.REGEXP.test(location.pathname)
            },
            {
                type: MENU_ITEM_TYPE.LINK,
                name: 'Import Pace Courses',
                icon: 'fa fa-book',
                link: ROUTES.IMPORT_PACE_COURSES.LINK(),
                isActive: (s, location) => ROUTES.IMPORT_PACE_COURSES.REGEXP.test(location.pathname)
            },
            {
                type: MENU_ITEM_TYPE.LINK,
                name: 'Import Webinars',
                icon: 'fa fa-desktop',
                link: ROUTES.IMPORT_WEBINARS.LINK(),
                isActive: (s, location) => ROUTES.IMPORT_WEBINARS.REGEXP.test(location.pathname)
            },
            {
                type: MENU_ITEM_TYPE.LINK,
                name: 'Import What\'s New',
                icon: 'fa fa-volume-up',
                link: ROUTES.IMPORT_WHATS_NEW.LINK(),
                isActive: (s, location) => ROUTES.IMPORT_WHATS_NEW.REGEXP.test(location.pathname)
            }
        ]
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'Settings',
        icon: 'fa fa-cogs',
        link: ROUTES.SETTINGS.LINK(),
        isActive: (s, location) => ROUTES.SETTINGS.REGEXP.test(location.pathname)
    },
];
