
// outsource dependencies
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

// local dependencies
import { LIST } from '../actions';
import { history } from '../../../store';
import { PERMISSION } from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import { QUANTIFICATION_MODELS } from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import { Truncate } from '../../../components/filter';
import { AddBtn, EditBtn, DelBtn, Link } from '../../../components/md-button';
import {QUANTIFICATION_MODELS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list } = this.props;
        return (
            <Grid fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <h2 className="text-uppercase">
                                Exposure models
                                <Preloader expectAnswer={expectAnswer} type="ICON" />
                            </h2>
                            <Breadcrumbs breadCrumbsMap={ QUANTIFICATION_MODELS_MAP }  />
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} to={QUANTIFICATION_MODELS.LINK_EDIT()} permission={PERMISSION.QUANTIFICATION_MODELS.CREATE} />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected />
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>
                    )}
                </ConnectedInitializer>
            </Grid>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.quantificationModels.list.expectAnswer, list: state.quantificationModels.list.data}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.quantificationModels.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.quantificationModels.list.filter,
        disabled: state.quantificationModels.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

const ConnectedTable = connect(
    state => ({...state.quantificationModels.list}),
    dispatch => ({
        deleteItem: (id, name) => dispatch({type: LIST.DELETE_ITEM, id, name}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead>
                <TableRow>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                        >
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                        >
                            Description
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> Regulations </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow key={i}>
                    <TableCell className="text-nowrap">{item.name}</TableCell>
                    <TableCell>
                        <Truncate tag="h5" text={item.description||''} options={{length: 40, end: '...', breakOnWord: true}} />
                    </TableCell>
                    <TableCell className="text-nowrap">{item.regulations ? item.regulations.map( item => item.name).join(', ') : null}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <div>
                            <Link
                                Btn={EditBtn}
                                permission={PERMISSION.QUANTIFICATION_MODELS.UPDATE}
                                to={QUANTIFICATION_MODELS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            />
                            <DelBtn permission={PERMISSION.QUANTIFICATION_MODELS.DELETE} disabled={item.isReadOnly} onClick={() => deleteItem(item.id, item.name)} className="offset-left-2" />
                        </div>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>));

const ConnectedError = connect(
    state => ({message: state.quantificationModels.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
