
// local dependencies

export const LIST = (prefix => {
    return {
        PREFIX: new RegExp(prefix, 'i'),
        // simple action
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        GET_DATA: `${prefix}GET_DATA`,
        // complex actions
        INITIALIZE: `${prefix}INITIALIZE`,
        UPDATE_LIST: `${prefix}UPDATE_LIST`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        DELETE_ALL_TRANSLATION: `${prefix}DELETE_ALL_TRANSLATION`,
    };
})('@languages/list/');

export const EDIT = (prefix => {
    return {
        PREFIX: new RegExp(prefix, 'i'),
        // simple action
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex actions
        UPDATE: `${prefix}UPDATE`,
        INITIALIZE: `${prefix}INITIALIZE`,
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
    };
})('@languages/edit/');

export const DICTIONARY = (prefix => {
    return {
        PREFIX: new RegExp(prefix, 'i'),
        // simple action
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        GET_DATA: `${prefix}GET_DATA`,
        // complex actions
        INITIALIZE: `${prefix}INITIALIZE`,
        UPDATE_LIST: `${prefix}UPDATE_LIST`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        DELETE_ITEM: `${prefix}DELETE_ITEM`,
        UPDATE_TRANSLATION: `${prefix}UPDATE_TRANSLATION`,
        DELETE_TRANSLATION: `${prefix}DELETE_TRANSLATION`,
    };
})('@languages/dictionary/');
