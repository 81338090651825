
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle } from '@material-ui/core';

// local dependencies
import { InfoBtn } from '../md-button';
import CONFIRM_DIALOG from './actions';
// import { translate } from '../../services/translate.service';
import {withStyles} from '@material-ui/core/index';

/**
 * confirmation component with message which open by 'CONFIRM_DIALOG.REQUEST' action
 *
 * @example
 dispatch({type: CONFIRM_DIALOG.REQUEST, message: translate('GLOBALS$DELETION_CONFIRM') });

 *
 * @param {Object} props
 * @public
 */
export default withStyles({ paper: { minWidth: '400px' } })(connect(
    state => ({...state.confirmDialog}),
    dispatch => ({ confirm: () => dispatch({type: CONFIRM_DIALOG.SUCCESS}), dismiss: () => dispatch({type: CONFIRM_DIALOG.ERROR})}),
)(({show, title, message, confirm, dismiss, classes}) => {
    return (
        <Dialog
            open={show}
            onClose={dismiss}
            disableBackdropClick
            transitionDuration={{exit: 0}}
            classes={{paper: classes.paper}}
            aria-labelledby="confirmation-dialog"
            aria-describedby="confirmation-dialog"
                >
            { title ? (<DialogTitle id="confirmation-dialog"> {title} </DialogTitle>) : '' }
            <DialogContent>
                <DialogContentText> {message || 'Are you sure?'} </DialogContentText>
            </DialogContent>
            <DialogActions>
                <InfoBtn variant="text" onClick={confirm}> Agree </InfoBtn>
                <InfoBtn variant="text" onClick={dismiss}> Disagree </InfoBtn>
            </DialogActions>
        </Dialog>
    )
}))

