
// outsource dependencies
import moment from 'moment';
import assignIn from 'lodash/assignIn';

// local dependencies
import { config } from '../constants';
import is from '../services/is.service';
import { NEW_ID } from '../constants/spec';
import { instanceAPI } from '../services/api.service';

const timeFix = 1000;
/**
 * prevent "encodeURIComponent" to let setup custom parameter
 * sort=createdDate,desc&sort=lastModifiedDate,asc
 *
 * @param {Object} params
 * @returns {String}
 * @private
 */
function skipEncode ( params ) {
    let query = '';
    for ( let name in params ) {
        query += `${name}=${String(params[name])}&`;
    }
    // NOTE remove last "&"
    return query.substring(0, query.length-1);
}

/**
 *
 *
 * @constructor BaseModel
 * @type {BaseModel}
 * @abstract
 */
class BaseModel {
    constructor ( data = {} ) {
        // copy all
        assignIn(this, data);
    }

    /**
     * helper to prevent Type Error
     *
     * @public
     */
    get created () {
        // NOTE Java API returns long (1531236834.000000000 => 1531236834)
        let time = this.createdDate ? this.createdDate*timeFix : (new Date(2018, 0, 1, 0, 0, 0, 0));
        return moment(time).format(config.clientTimeFormat);
    }

    /**
     * helper to prevent Type Error
     *
     * @public
     */
    get creator () {
        let result = { fullName: 'Sofra CBC', avatar: null, email: 'CBC System' };
        // check existing of audit data
        if ( !is.object(this.auditable) ) return result;
        let { createdBy } = this.auditable;
        if ( !is.object(createdBy) ) return result;
        assignIn(result, this.auditable.createdBy);
        return result;
    }

    /**
     * helper to prevent Type Error
     *
     * @public
     */
    get updated () {
        // NOTE Java API returns long (1531236834.000000000 => 1531236834)
        let time = this.lastModifiedDate ? this.lastModifiedDate*timeFix : (new Date(2018, 0, 1, 0, 0, 0, 0));
        return moment(time).format(config.clientTimeFormat);
    }

    /**
     * helper to prevent Type Error
     *
     * @public
     */
    get editor () {
        let result = { fullName: 'Sofra CBC', avatar: null, email: 'CBC System' };
        // check existing of audit data
        if ( !is.object(this.auditable) ) return result;
        let { lastModifiedBy } = this.auditable;
        if ( !is.object(lastModifiedBy) ) return result;
        assignIn(result, this.auditable.lastModifiedBy);
        return result;
    }

    /**
     * logger for all models
     *
     * @public
     */
    get log () {
        return this.constructor.log.bind(this.constructor);
    }

    /**
     * debug state logger for all models
     *
     * @public
     */
    get debug () {
        return this.constructor.debug.bind(this.constructor);
    }

    /**
     * setup default data for model or use another preparation
     *
     * @public
     */
    init () {
        // method to override
    }

    /**
     * create/update method
     * NOTE solution on server side to use one method to create and update entities
     *
     * @param {Object} data
     * @public
     */
    update ( data ) {
        let Model = this.constructor;
        let baseRoute = Model.baseRoute;
        return new Promise((resolve,  reject) => {
            let updated = assignIn(assignIn({}, this), data);
            // id is important things to known is exist item on server side
            instanceAPI({ data: updated, method: 'put', url: `${baseRoute}/`})
                .then(success => {
                    assignIn(this, Model.create(success));
                    resolve(this);
                }).catch(reject);
        });
    }

    /**
     * change status method
     *
     * @param {String} status
     * @public
     */
    changeStatus ( status ) {
        let Model = this.constructor;
        return Model.changeStatuses(status, [{id: this.id}]);
    }

    /**
     * delete method
     *
     * @public
     */
    remove () {
        let Model = this.constructor;
        return Model.remove([{id: this.id}]);
    }

    /**
     * helper to detect changes in model after merge with other data
     *
     * @param {Object} changes
     * @private
     */
    isWillChange ( changes ) {
        let Model = this.constructor;
        let newData = Model.create( assignIn({}, this, changes) );
        let isEqual = is.equal(this, newData);
        return !isEqual;
    }

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @private
     */
    static get baseRoute () {
        throw new Error('static "baseRoute" required for models');
    }

    /**
     * get by Id
     *
     * @param {Number} id
     * @param {Object} [options=null]
     * @public
     */
    static getById ( id, options ) {
        let Model = this;
        let baseRoute = Model.baseRoute;
        return new Promise((resolve, reject) => {
            instanceAPI({ method: 'get', url: `${baseRoute}/${id}`})
                .then(success => {
                    let instance = Model.create(success);
                    resolve(instance);
                }).catch(reject);
        });
    }

    /**
     * change status method
     *
     * @param {String} status
     * @param {Array} list - ([{id: 1}, {id: 2}])
     * @public
     */
    static changeStatuses ( status, list ) {
        let Model = this;
        let baseRoute = Model.baseRoute;
        return new Promise((resolve,  reject) => {
            // id is important things to known is exist item on server side
            // NOTE request from server side from 06.08.2018 for url mapping to use ENUM of status in lower case
            instanceAPI({ data: list, method: 'put', url: `${baseRoute}/statuses/${status.toLowerCase()}`})
                .then(resolve).catch(reject);
        });
    }

    /**
     * remove method
     *
     * @param {Object} data - {id: 1}
     * @public
     */
    static remove ( data ) {
        let Model = this;
        let baseRoute = Model.baseRoute;
        return new Promise((resolve,  reject) => {
            // id is important things to known is exist item on server side
            instanceAPI({ data, method: 'delete', url: `${baseRoute}/`})
                .then(resolve).catch(reject);
        });
    }

    /**
     * get list with pagination
     *
     * @param {Object} data
     * @param {Object} [options=null]
     * @public
     */
    static getPage ( data, options ) {
        let Model = this;
        let baseRoute = Model.baseRoute;
        !options&&(options = {});
        if( !is.defined(options.paramsSerializer) ) {
            // NOTE prevent "encodeURIComponent"
            options.paramsSerializer = skipEncode;
        }

        return new Promise((resolve, reject) => {
            instanceAPI({
                data,
                method: 'post',
                url: `${baseRoute}/filter`,
                ...options
            }).then(success => resolve({...success, items: success.items.map(i=>Model.create(i))}) ).catch(reject);
        });
    }

    /**
     * get list with pagination
     * NOTE minimum fields within list and cut pagination information
     * the same request as pagination
     *
     * @param {String} name
     * @param {Number} [size=10]
     * @param {Array} [excludeIds]
     * @param {String} organizationType
     * @public
     */
    static getListByName ( name, size = 10, excludeIds, organizationType ) {
        let Model = this;
        let baseRoute = Model.baseRoute;
        if (!excludeIds || !is.array(excludeIds) || !excludeIds.length) {
            excludeIds = void(0);
        }
        return new Promise((resolve, reject) => {
            instanceAPI({
                method: 'post',
                data: { filter: {name, excludeIds, organizationType}, page: 0, size },
                url: `${baseRoute}/filter`,
            }).then(({items = []}) => resolve(items.map(i=>Model.create(i))) ).catch(reject);
        });
    }

    /**
     * create/update method
     * NOTE solution on server side to use one method to create and update entities
     *
     * @param {Number|String} [id=NEW_ID]
     * @param {Object} data
     * @public
     */
    static partiallyUpdate ( id = NEW_ID, data ) {
        let Model = this;
        let baseRoute = Model.baseRoute;
        return new Promise((resolve,  reject) => {
            // NOTE id is important things to known is exist item on server side
            let isNew = !is.countable(id);
            data.id = isNew ? void(0) : id;
            instanceAPI({
                data,
                url: `${baseRoute}/`,
                method: isNew ? 'post' : 'put'
            }).then(success => resolve(Model.create(success))).catch(reject);
        });
    }

    /**
     * create method
     *
     * @public
     */
    static create ( ...args ) {
        let Model = this;
        let instance = new Model(...args);
        // NOTE make some preparing for instances
        instance.init( ...args );
        return instance;
    }

    /**
     * debug state logger
     *
     * @public
     */
    static debug ( ...args ) {
        config.DEBUG&&console.log(`%c[${this.name}] DEBUG:`, 'background: #0747A6; color: #fff; font-weight: bolder; font-size: 14px; padding: 4px 2px;', '\n', ...args);
    }

    /**
     * logger
     *
     * @public
     */
    static log ( ...args ) {
        console.log(`%c ${this.name} `, 'background: #3e8f3e; color: #000; font-weight: bolder; font-size: 12px; padding: 2px 0;', '\n', ...args);
    }

}

export default BaseModel;
