
// outsource dependencies
import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Delete } from '@material-ui/icons';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, TableSortLabel, TextField } from '@material-ui/core';

// local dependencies
import { DICTIONARY } from '../types';
import { history } from '../../../store';
import Page from '../../../components/page';
import Alert from '../../../components/alert-error';
import { Humanize } from '../../../components/filter';
import SearchFilter  from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import Preloader, { TYPE } from '../../../components/preloader';
import { DangerBtn, WarningBtn } from '../../../components/md-button';
import {LANGUAGES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";


// configure
export const allowedSort = ['name', 'value', 'defaultValue'];

/**
 * Table
 *
 * @private
 */
const ConnectedTable = connect(
    state => ({
        list: state.languages.dictionary.list,
        page: state.languages.dictionary.page,
        size: state.languages.dictionary.size,
        totalPages: state.languages.dictionary.totalPages,
        disabled: state.languages.dictionary.expectAnswer,
    }),
    dispatch => ({
        init: () => dispatch({type: DICTIONARY.INITIALIZE}),
        changePage: page => dispatch({type: DICTIONARY.UPDATE_LIST, page}),
        clearError: () => dispatch({type: DICTIONARY.META, errorMessage: null}),
        changeSize: size => dispatch({type: DICTIONARY.UPDATE_LIST, size, page: 0}),
    })
)(({ list, disabled, page, size, totalPages, changePage, changeSize }) => (<Paper>
    <div style={{overflowX: 'auto'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <SortableCell name="name" label="Key" />
                    <SortableCell name="defaultValue" style={{minWidth: 300}} />
                    <SortableCell name="scope" style={{minWidth: 200}} />
                    <SortableCell name="value" style={{minWidth: 300}} />
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (<TableRow key={index}>
                    <TableCell component="th" scope="row"> {get(item, 'languageConstant.name')} </TableCell>
                    <TableCell component="th" scope="row"> {item.defaultValue} </TableCell>
                    <TableCell component="th" scope="row"> {item.languageConstant.scope} </TableCell>
                    <TableCell component="th" scope="row"> <ConnectedEditableCell item={item} /> </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={disabled}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</Paper>));

/**
 * Wrapper for sorting TH
 *
 * @private
 */
const SortableCell = connect(
    state => ({
        sortF: state.languages.dictionary.sortF,
        sortD: state.languages.dictionary.sortD,
        disabled: state.languages.dictionary.expectAnswer,
    }),
    dispatch => ({ changeSort: field => dispatch({type: DICTIONARY.CHANGE_SORT, field}) })
)(({label, disabled, sortF, sortD, name, changeSort, ...attr})=>(
    <TableCell className="th" {...attr}>
        <Tooltip
            enterDelay={300}
            placement="top-start"
            title={(<span>Sort by "<Humanize tag="strong" text={name} />"</span>)}
                >
            <span>
                <TableSortLabel
                    disabled={disabled}
                    active={sortF===name}
                    direction={sortD?'asc':'desc'}
                    onClick={()=>changeSort(name)}
                        >
                    {label ? <strong>{label}</strong> : <Humanize tag="strong" text={name} />}
                </TableSortLabel>
            </span>
        </Tooltip>
    </TableCell>
));

class EditableCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item || {}
        }
    }

    componentDidUpdate (prevProps) {
        if ( get(prevProps, 'item', null) !== get(this.props, 'item', null) ) {
            // NOTE update value when item  was updated
            this.setState({item: this.props.item || {}});
        }
    }

    handleChange (value) {
        this.setState((state) => ({item: {...state.item, value}}));
    }

    handleUpdate (e) {
        if (get(this.state, 'item.value') !== get(this.props, 'item.value')) {
            this.props.update(this.state.item)
        }
    }

    render() {
        let { item } = this.state;
        let { language } = this.props;
        let direction = language.direction === 'RTL' ? 'rtl' : 'ltr';
        return (
            <TextField
                fullWidth
                multiline
                value={item.value}
                placeholder="value"
                onBlur={e => this.handleUpdate(e)}
                InputProps={{style: {fontSize: 16, direction}}}
                onChange={e => e&&this.handleChange(e.target.value)}
                    />
        );
    }
}

const ConnectedEditableCell = connect(
    state => ({ language: state.languages.dictionary.language }),
    dispatch => ({ update: item => dispatch({type: DICTIONARY.UPDATE_TRANSLATION, item}) })
)(EditableCell);

/**
 *
 * @private
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.languages.dictionary.filter,
        disabled: state.languages.dictionary.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: DICTIONARY.META, filter}),
        applyFilter: filter => dispatch({type: DICTIONARY.UPDATE_LIST, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        placeholder="Search by value"
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

/**
 * Table wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.languages.dictionary }),
    dispatch => ({
        init: langId => dispatch({type: DICTIONARY.INITIALIZE, langId}),
        clearError: () => dispatch({type: DICTIONARY.META, errorMessage: null}),
        deleteTranslation: () => toastr.confirm(<ConfirmDeleting />, { onOk: () => dispatch({type: DICTIONARY.DELETE_TRANSLATION}) }),
    })
)(({ list, init, initialized, expectAnswer, errorMessage, clearError, back, match, language, deleteTranslation }) => (
    <Page init={() => init(match.params.langId)} initialized={initialized}>
        <Row className="offset-bottom-4">
            <Col xs={12} md={6}> <h2 className="text-uppercase offset-bottom-1">
                {language.name} Dictionary&nbsp;<Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
            </h2>
                <Breadcrumbs breadCrumbsMap={ LANGUAGES_MAP }  />
            </Col>
            <Col xs={12} md={6} className="text-right top-indent-3">
                <DangerBtn className="offset-bottom-1" disabled={expectAnswer} tooltip="Delete translation" onClick={deleteTranslation}>
                    <Delete />&nbsp;Delete translation
                </DangerBtn>
                <WarningBtn className="offset-left-2 offset-bottom-1" disabled={expectAnswer} tooltip="Go back" onClick={()=>history.push(back)}>
                    <i className="fa fa-reply" aria-hidden="true" />&nbsp;Back
                </WarningBtn>
            </Col>
        </Row>
        <Row> <Col xs={12} md={8} mdOffset={2}> <Alert active message={errorMessage} onChange={clearError} /> </Col> </Row>
        <Row> <Col xs={12}>
            <div style={{width: '300px', marginBottom: '10px'}}> <SearchFilterConnected /> </div>
        </Col> </Row>
        <Row> <Col xs={12}>
            {list.length ? (<ConnectedTable />) : (<h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>)}
        </Col> </Row>
    </Page>
));

/**
 * prepared confirmation for translation deleting
 *
 * @public
 */
const ConfirmDeleting = () => (<div>
    <h3> Confirm deleting </h3>
    <p> Are you sure you want to delete all translation for current language? </p>
</div>);

