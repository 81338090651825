
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit, Add, Save, Clear, LayersClear, ControlPointDuplicate, MoreHorizOutlined, DataUsageOutlined, BookOutlined } from '@material-ui/icons';

// local dependencies
import Theme from './theme';
import { historyPush } from '../store';

// configure

/**
 * Wrapper any item in link container if link path is present
 *
 * @param {Object} props
 * @public
 */
export const Link = ({ to, disabled, Btn, ...attr }) => (
    <LinkContainer to={to} disabled={disabled} className="link-unstyled">
        <Btn disabled={disabled} {...attr} />
    </LinkContainer>
);

/**
 * Prepared primary btn
 *
 * @param {Object} props
 * @public
 */
export const PrimaryBtn = ({tooltip = 'tooltip', theme = 'ROOT', ...attr}) => (<Theme name={theme}>
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <Button aria-label={tooltip} variant="contained" color="primary" {...attr} />
    </span></Tooltip>
</Theme>);

/**
 * Prepared warning btn
 *
 * @param {Object} props
 * @public
 */
export const WarningBtn = ({tooltip = 'tooltip', theme = 'ROOT', ...attr}) => (<Theme name={theme}>
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <Button aria-label={tooltip} variant="contained" color="secondary" {...attr} />
    </span></Tooltip>
</Theme>);

/**
 * Prepared info btn
 *
 * @param {Object} props
 * @public
 */
export const InfoBtn = ({tooltip = 'tooltip', theme = 'ALT', ...attr}) => (<Theme name={theme}>
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <Button aria-label={tooltip} variant="contained" color="primary" {...attr} />
    </span></Tooltip>
</Theme>);

/**
 * Prepared danger btn
 *
 * @param {Object} props
 * @public
 */
export const DangerBtn = ({tooltip = 'tooltip', theme = 'ALT', ...attr}) => (<Theme name={theme}>
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <Button aria-label={tooltip} variant="contained" color="secondary" {...attr} />
    </span></Tooltip>
</Theme>);

/**
 * Prepared delete btn
 *
 * @param {Object} props
 * @public
 */
// export const LangBtn = ( props ) => (<PrimaryBtn {...props} tooltip="Add"><FlagOutlined /></PrimaryBtn>);

export const AddBtn = ( props ) => (<InfoBtn {...props} tooltip="Add"> <Add /></InfoBtn>);
export const EditBtn = ( props ) => (<PrimaryBtn {...props} tooltip="Edit"> <Edit /></PrimaryBtn>);
export const DetailsBtn = ( props ) => (<PrimaryBtn {...props} tooltip="Details"> <MoreHorizOutlined /></PrimaryBtn>);
// export const ViewBtn = ( props ) => (<PrimaryBtn {...props} tooltip="View"> <Edit />&nbsp;View </PrimaryBtn>);
export const CloneBtn = ( props ) => (<InfoBtn {...props} tooltip="Clone"> <ControlPointDuplicate /></InfoBtn>);
export const DelBtn = ( props ) => (<DangerBtn {...props} tooltip="Delete"> <Delete /></DangerBtn>);
export const CancelBtn = ( props ) => (<DangerBtn {...props} tooltip="Cancel"> <Clear /></DangerBtn>);
export const ResetBtn = ( props ) => (<WarningBtn {...props} tooltip="Reset"> <LayersClear /></WarningBtn>);
export const LoadDemoModelBtn = ( props ) => (<WarningBtn {...props} tooltip="Load demo model"> <DataUsageOutlined /></WarningBtn>);
export const DictionaryBtn = ( props ) => (<WarningBtn {...props} tooltip="Load demo model"> <BookOutlined /></WarningBtn>);
export const SubmitBtn = ({ isNew = false, ...attr }) => (<PrimaryBtn {...attr} type="submit" tooltip={isNew ? 'Create' : 'Save'}>
    {isNew ? <Add/> : <Save/>}
</PrimaryBtn>);

/**
 * Prepared add icon btn
 *
 * @param {Object} props
 * @public
 */
export const AddIconBtn = ({tooltip = 'Add', ...attr}) => (<Tooltip title={tooltip} placement="top" enterDelay={300}><span>
    <IconButton aria-label={tooltip} {...attr}>
        <Add fontSize="small"/>
    </IconButton>
</span></Tooltip>);

/**
 * Prepared delete icon button
 *
 * @param {Object} props
 * @public
 */
export const DeleteIconBtn = ({tooltip = 'Delete', theme = 'ALT', ...attr}) => (<Theme name={theme}>
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <IconButton aria-label={tooltip} color="secondary" {...attr}>
            <Delete fontSize="small"/>
        </IconButton>
    </span></Tooltip>
</Theme>);


/**
 * Back button
 *
 * @param {Function} Btn
 * @param {String} back - previous path to go back
 * @param {object} attr
 * @public
 */
export const BackBtn = ({Btn = CancelBtn, back, ...attr}) => ( <Btn {...attr} onClick={()=>historyPush(back)} /> );

BackBtn.propTypes = {
    Btn: PropTypes.func,
    back: PropTypes.string.isRequired,
};