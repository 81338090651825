
// outsource dependencies

// local dependencies
import BaseModel from './base.model';

/**
 *
 *
 * @constructor LanguageModel
 * @type {LanguageModel}
 * @public
 */
class LanguageModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {}

    /*----------------------------------------
                    STATIC
    ------------------------------------------*/

    /**
     * setup path for base CRUD requests
     *
     * @override BaseModel
     * @private
     */
    static get baseRoute () {
        return '/admin/supported-languages';
    }

}

export default LanguageModel;
