
// outsource dependencies
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Route, Switch, Redirect } from 'react-router-dom';

// local dependencies
import Edit from './edit';
import List from './list';
import { QLIK_ANALYTICS } from '../../constants/routes';

/**
 *
 * @param {Object} props
 * @public
 */
export default function ( props ) {
    return (<Grid fluid>
        <Row>
            <Col xs={12}>
                <Switch>
                    <Route path={QLIK_ANALYTICS.LIST} component={List} />
                    <Route path={QLIK_ANALYTICS.EDIT} component={Edit} />
                    <Route path={QLIK_ANALYTICS.CLONE} component={Edit} />
                    {/* OTHERWISE */}
                    <Redirect to={{pathname: QLIK_ANALYTICS.LINK(), state: {from: props.location} }}/>
                </Switch>
            </Col>
        </Row>
    </Grid>);
};
